export const countries = [
    {
        id: 'UK',
        name: 'UK',
    },
];

export const currencies = [
    {
        id: 'GBP',
        name: 'GBP',
    },
];
