import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Drawer.WorkflowsMenu', {
    addButtonTitle: 'Create a new',
});

export const workflowsMessages = defineMessages('components.Drawer.WorkflowsMenu.workflows', {
    unnamedName: 'Unnamed workflow',
    xeroPoName: 'Purchase Orders',
    xeroBillName: 'Bills',
    xeroBillBatchPaymentName: 'Xero Batch Payments',
    xeroAirwallexBatchPaymentName: 'Airwallex Batch Payments',
    amaxPayBatchPaymentName: 'Batch Payments',
    xeroCreditNotesPayableName: 'AP Credit Notes',
    xeroInvoiceName: 'Sales Invoices',
    xeroCreditNotesReceivableName: 'AR Credit Notes',
    xeroContactName: 'Contacts',
    salesQuotesName: 'Quotes',
    qBooksPoName: 'Purchase Orders',
    qBooksBillName: 'Bills',
    qBooksExpenseName: 'Expenses',
    qBooksInvoiceName: 'Sales Invoices',
    qBooksVendorName: 'Vendors',
    qBooksJournalEntryName: 'Journal Entries',
    netSuiteBillName: 'Bills',
    netSuitePOName: 'Purchase Orders',
    netSuiteSalesOrderName: 'Sales Orders',
    netSuiteInvoiceName: 'Invoices',
    netSuiteExpenseReportName: 'Expense Reports',
    netSuiteVRAName: 'Vendor Return Authorization',
    netSuiteJournalEntryName: 'Journal Entries',
    netSuiteBillPaymentName: 'Bill Payments',
    netSuiteRAName: 'Return Authorization',
    xeroManualJournalName: 'Manual Journals',
    dearPoName: 'Purchase Orders',
});

export const filtersMessages = defineMessages('components.Drawer.WorkflowsMenu.filters', {
    allName: 'All',
    acceptedName: 'Accepted',
    declinedName: 'Declined',
    onApprovalName: 'On Approval',
    approvedName: 'Approved',
    cancelledName: 'Cancelled',
    rejectedName: 'Rejected',
    billedName: 'Billed',
    notBilledName: 'Not Billed',
    paidName: 'Paid',
    awaitingPaymentName: 'Awaiting payment',
    partiallyPaidName: 'Partially paid',
    failedName: 'Failed',
    processingName: 'Processing',
    grnNotReceivedName: 'Not Received',
    grnPartiallyReceivedName: 'Partially Received',
    grnFullyReceivedName: 'Fully Received',
    creditedName: 'Credited',
    partiallyReceivedTitle: 'Partially Received',
    pendingReceiptTitle: 'Pending Receipt',
    pendingBillTitle: 'Pending Bill',
    closedTitle: 'Closed',
    onReview: 'On review',
    onHold: 'On hold',
});
