import { Field, Popup } from '@approvalmax/ui';
import { font } from 'modules/styles';
import styled from 'styled-components';

export const Root = styled.div`
    padding: 0 60px 50px 80px;
    overflow: auto;
`;

export const LoadingBarContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 8px;
`;

export const Title = styled.div`
    ${font(16, '#000', 'semibold')}
`;

export const StyledField = styled(Field)`
    margin-top: 20px;
`;

export const StyledPopup = styled(Popup.DefaultContent)`
    position: relative;
    width: 800px;
`;
