import { Button, Flex, Text } from '@approvalmax/ui/src/components';
import { ChevronLeftIcon } from '@approvalmax/ui/src/icons';
import { memo, useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { contentSplitViewState } from 'shared/states';

import { goBackBarDefaultSettings } from './GoBackBar.constants';
import { messages } from './GoBackBar.messages';
import { goBackBarSettingsState } from './GoBackBar.states';

export const GoBackBar = memo(() => {
    const setContentSplitView = useSetRecoilState(contentSplitViewState);
    const [goBackBarSettings, setGoBackBarSettings] = useRecoilState(goBackBarSettingsState);

    const onGoBack = useCallback(() => {
        goBackBarSettings.previousContent && setContentSplitView(goBackBarSettings.previousContent);
        setGoBackBarSettings(goBackBarDefaultSettings);
    }, [goBackBarSettings.previousContent, setContentSplitView, setGoBackBarSettings]);

    return (
        <Flex inline alignItems='center' spacing='8' width='100%' wrap={false}>
            <Button icon color='transparent' noPadding onClick={onGoBack}>
                <ChevronLeftIcon size={28} />
            </Button>

            <Text font='body' ellipsis={1}>
                {goBackBarSettings.previousContentName
                    ? messages.goTo({ contentName: goBackBarSettings.previousContentName })
                    : messages.goBack}
            </Text>
        </Flex>
    );
});

GoBackBar.displayName = 'GoBackBar';
