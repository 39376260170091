import { MentionedUser, PascalCaseToCamelCase } from '@approvalmax/types';
import { arrayHelpers, compareHelpers, errorHelpers, miscHelpers } from '@approvalmax/utils';
import { selectors, statics } from 'modules/common';
import { domain, schemas } from 'modules/data';
import { schema } from 'normalizr';

import * as backend from '../../backend';
import { getForwardEnumMapper } from '../../utils';
import { EmailToContact } from '../EmailToContact';
import { FieldSystemPurpose } from '../Field';
import { getIntegrationTypeByCode, IntegrationType } from '../Integration';
import {
    PossibleDuplicateItem,
    RequestAttachment,
    RequestAttachmentType,
    RequestFieldEntry,
    RequestFraudulentActivity,
    RequestHistoryEvent,
    RequestHistoryEventType,
    RequestHistoryFieldChangeData,
    RequestHistoryReviewStepChangeData,
    RequestHistoryStepChangeData,
    RequestMatchingAgreement,
    RequestMatchingAgreementDocument,
    RequestOrigin,
    RequestResolutionOrigin,
    RequestSpecifics,
    RequestStep,
    RequestStepParticipantDecision,
    RequestStepParticipantOrigin,
    RequestStepResolution,
    RequestStepState,
} from '../Request';
import { TemplateStepType } from '../Template';
import { mapField } from './fieldSchema';
import { getDearRequestSpecifics } from './requestSchema.Dear';
import { mapNetSuiteRequestSpecifics } from './requestSchema.NetSuite';
import { getQbooksBase } from './requestSchema.QBooks';
import { getStandaloneRequestSpecifics } from './requestSchema.Standalone';
import { mapXeroRequestSpecifics } from './requestSchema.Xero';
import { mapMatrixCondition } from './templateSchema';
import userSchema from './userSchema';

const mapRequestAttachmentType = getForwardEnumMapper(backend.AttachmentType, RequestAttachmentType);

export function mapAttachment(
    value: backend.AttachmentAnswer | PascalCaseToCamelCase<backend.AttachmentAnswer>
): RequestAttachment {
    if ('AttachmentId' in value) {
        return {
            id: value.AttachmentId,
            name: value.Name,
            size: value.Size,
            createdDate: value.CreatedDate,
            attachmentType: mapRequestAttachmentType(value.AttachmentType),
            externalId: value.ExternalId,
            authorId: value.Author?.UserEmail,
            ocrStatus: value.OcrStatus,
            isOcrOngoing: value.IsOcrOngoing,
        };
    } else {
        return {
            id: value.attachmentId,
            name: value.name,
            size: value.size,
            createdDate: value.createdDate,
            attachmentType: mapRequestAttachmentType(value.attachmentType),
            externalId: value.externalId,
            authorId: value.author?.userEmail,
            ocrStatus: value.ocrStatus,
            isOcrOngoing: value.isOcrOngoing,
        };
    }
}

export function mapMentionedUser(value: backend.MentionedUserAnswer): MentionedUser {
    return {
        email: value.Email,
        userProfileId: value.UserProfileId,
    };
}

const mapRequestStepState = getForwardEnumMapper(backend.RequestStepState, RequestStepState);

const mapRequestStepResolution = getForwardEnumMapper(backend.StepResolution, RequestStepResolution);

const mapRequestStepParticipantDecision = getForwardEnumMapper(
    backend.RequestStepParticipantDecision,
    RequestStepParticipantDecision
);

const mapRequestStepParticipantOrigin = getForwardEnumMapper(
    backend.RequestStepParticipantOrigin,
    RequestStepParticipantOrigin
);

const mapRequestStepTypeOrigin = getForwardEnumMapper(backend.StepType, TemplateStepType);

const mapWatcher = (watcher: backend.WatchersAnswer) => ({
    addedBy: schemas.mapUser(watcher.AddedBy),
    createdDate: watcher.CreatedDate,
    watcher: schemas.mapUser(watcher.Watcher),
});

function mapStep(value: backend.RequestStepAnswer): RequestStep {
    return {
        id: value.StepId,
        createdDate: value.CreatedDate,
        modifiedDate: value.ModifiedDate,
        name: value.Name,
        state: mapRequestStepState(value.State),
        resolution: mapRequestStepResolution(value.Resolution),
        resolutionDate: value.ResolutionDate || null,
        dueDate: value.DueDate || null,
        participants: value.Participants.map((p) => ({
            userId: p.UserEmail,
            decision: mapRequestStepParticipantDecision(p.Decision),
            enabledAccordingToRule: Boolean(p.EnabledAccordingToRule),
            required: p.Required,
            decisionDate: p.DecisionDate || null,
            addedBy: p.AddedBy?.UserEmail || null,
            delegateFor: p.DelegateFor?.UserEmail || null,
            source: mapRequestStepParticipantOrigin(p.Source),
            canEdit: p.CanEdit,
            editPermissions: (p.EditPermissions || []).flatMap((r) => r.Conditions || []).map(mapMatrixCondition),
        })),
        editors: value.Editors.map((e) => ({
            userId: e.UserEmail,
            rules: (e.Rules || [])
                .flatMap((r) => r.Conditions || [])
                .map(mapMatrixCondition)
                .filter(selectors.matrix.filterBrokenConditions),
        })),
        requiredFieldIds: value.RequiredFieldIds || [],
        editPermissionsRequiredFieldIds: value.EditPermissionsRequiredFieldIds || [],
        type: mapRequestStepTypeOrigin(value.Type || 0),
        approvalCount: value.ApprovalCount || null,
    };
}

function mapMatchingAgreementDocument(value: backend.AgreementDocumentAnswer): RequestMatchingAgreementDocument {
    return {
        id: value.RequestId,
        authorId: value.RequestAuthor.UserEmail,
        amount: value.Amount,
        currency: value.Currency,
        name: value.Name,
        friendlyName: value.FriendlyName,
    };
}

function mapMatchingAgreement(value: backend.MatchingAgreementAnswer): RequestMatchingAgreement | null {
    if (!value) {
        return null;
    }

    return {
        id: value.AgreementId,
        currency: value.AgreementCurrency,
        openingNetAmountTotal: value.OpeningNetAmountTotal,
        closingNetAmountTotal: value.ClosingNetAmountTotal,
        openingNetAmountTotalBase: value.OpeningNetAmountTotalBase,
        closingNetAmountTotalBase: value.ClosingNetAmountTotalBase,
        fullyMatched: value.IsFullyMatched,
        overBudget: value.IsOverBudget,
        readonly: Boolean(value.IsReadOnly),
        openingDocuments: value.OpeningDocuments.map(mapMatchingAgreementDocument),
        closingDocuments: value.ClosingDocuments.map(mapMatchingAgreementDocument),
    };
}

function mapEmailToContact(value: backend.EmailToContact): EmailToContact | null {
    if (!value) {
        return null;
    }

    return {
        emailHasToBeSent: value.EmailHasToBeSent,
        emailCC: value.EmailCC,
        emailBCC: value.EmailCC,
        emailBody: value.EmailBody,
        emailFrom: value.EmailFrom,
        emailTo: value.EmailTo,
        emailIsSent: value.EmailIsSent,
        emailReplyTo: value.EmailReplyTo,
        emailSubject: value.EmailSubject,
        attachments: (value.Attachments || [])
            .map((a) => mapAttachment(a))
            .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
    };
}

export function mapFieldValues(value: backend.RequestFieldValueAnswer[]): RequestFieldEntry[] {
    const fieldEntries = value.map((v) => ({
        field: mapField(v),
        value:
            v.Values && v.Values.length > 0
                ? {
                      id: v.Values[0].FieldValueId,
                      text: v.Values[0].Value,
                  }
                : null,
    }));

    return arrayHelpers.arraySort<RequestFieldEntry>(fieldEntries, (a, b) => {
        return compareHelpers.stringComparator2AscI(a.field.name, b.field.name);
    });
}

function mapHistoryFieldChanges(value: backend.FieldChangedData): RequestHistoryFieldChangeData {
    return {
        fieldName: value.FieldName,
        oldValue: value.OldValue,
        newValue: value.NewValue,
        oldDetails: value.OldDetails,
        newDetails: value.NewDetails,
    };
}

function mapHistoryStepChanges(value: backend.StepChangeData): RequestHistoryStepChangeData {
    return {
        step: {
            id: value.StepId,
            text: value.StepName,
        },
        name: value.Name,
        deadline: value.Deadline,
        deadlineRemoved: value.DeadlineWasDeleted,
        addedApprovers: value.AddedApprovers
            ? value.AddedApprovers.map((a) => ({
                  user: a,
                  delegateFor: a.DelegateFor,
              }))
            : undefined,
        removedApprovers: value.RemovedApprovers
            ? value.RemovedApprovers.map((a) => ({
                  user: a,
                  delegateFor: a.DelegateFor,
              }))
            : undefined,
    };
}

const mapRequestHistoryEventType = getForwardEnumMapper(backend.RequestHistoryEventType, RequestHistoryEventType);

const mapRevewStepChanges = (value?: backend.ReviewStepChangeData): RequestHistoryReviewStepChangeData | undefined => {
    if (!value) return undefined;

    return {
        stepId: value.StepId,
        addedReviewers: value.AddedReviewers,
        removedReviewers: value.RemovedReviewers,
    };
};

function mapHistoryEvent(value: backend.RequestHistoryEventAnswer): RequestHistoryEvent {
    const changes = value.Changes;

    return {
        id: value.EventId,
        date: value.EventDate,
        description: value.Description,
        type: mapRequestHistoryEventType(value.EventType),
        step: value.Step
            ? {
                  id: value.Step,
                  text: value.StepName,
              }
            : undefined,
        nextStep: value.NextStep
            ? {
                  id: value.NextStep,
                  text: value.NextStepName,
              }
            : undefined,
        authorId: value.Author?.UserEmail,
        onBehalfOfId: value.OnBehalfOf?.UserEmail,
        comment: value.Comment
            ? {
                  id: value.Comment.CommentId,
                  text: value.Comment.Text,
                  createdDate: value.Comment.CreatedDate,
                  author: value.Comment.Author.UserEmail,
                  attachments: (value.Comment.CommentAttachments || [])
                      .map(mapAttachment)
                      .sort(
                          compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')
                      ),
                  mentionedUsers: (value.Comment.MentionedUsers || []).map(
                      (mentionedUser: backend.MentionedUserAnswer) => mapMentionedUser(mentionedUser)
                  ),
              }
            : undefined,
        isSystem: value.IsSystem,
        userPlatform: value.UserPlatform,
        changes: changes
            ? {
                  newName: changes.NewName,
                  newDescription: changes.NewDescription,
                  fieldChanges: changes.FieldsChanges ? changes.FieldsChanges.map(mapHistoryFieldChanges) : undefined,
                  amountFieldChange: changes.AmountFieldChange
                      ? mapHistoryFieldChanges(changes.AmountFieldChange)
                      : undefined,
                  addedAttachments: changes.AddedAttachments ? changes.AddedAttachments.map(mapAttachment) : undefined,
                  removedAttachments: changes.RemovedAttachments
                      ? changes.RemovedAttachments.map(mapAttachment)
                      : undefined,
                  stepChanges: changes.StepsChanges ? changes.StepsChanges.map(mapHistoryStepChanges) : undefined,
                  reviewStepChanges: mapRevewStepChanges(changes.ReviewStepsChanges),
                  matchedDocuments: changes.MatchedDocuments
                      ? changes.MatchedDocuments.map(mapMatchingAgreementDocument)
                      : undefined,
                  unmatchedDocuments: changes.UnmatchedDocuments
                      ? changes.UnmatchedDocuments.map(mapMatchingAgreementDocument)
                      : undefined,
              }
            : undefined,
    };
}

const mapRequestFraudulentActivity = getForwardEnumMapper(backend.RequestFraudulentActivity, RequestFraudulentActivity);

function mapRequestOrigin(value: backend.RequestsRequestOrigin) {
    switch (value) {
        case backend.RequestsRequestOrigin.ApprovalMax:
            return RequestOrigin.ApprovalMax;

        case backend.RequestsRequestOrigin.Xero:
            return RequestOrigin.Xero;

        case backend.RequestsRequestOrigin.QBooks:
            return RequestOrigin.QBooks;

        case backend.RequestsRequestOrigin.ReceiptBank:
            return RequestOrigin.ReceiptBank;

        case backend.RequestsRequestOrigin.NetSuite:
            return RequestOrigin.NetSuite;

        case backend.RequestsRequestOrigin.Dear:
            return RequestOrigin.Dear;

        case backend.RequestsRequestOrigin.PublicApi:
            return RequestOrigin.PublicApi;

        case backend.RequestsRequestOrigin.Email:
            return RequestOrigin.Email;

        default:
            throw errorHelpers.assertNever(value);
    }
}

const mapRequestResolutionOrigin = getForwardEnumMapper(
    backend.RequestsRequestResolutionOrigin,
    RequestResolutionOrigin
);

export const mapPossibleDuplicateItem = (value: backend.PossibleDuplicatesAnswer): PossibleDuplicateItem => {
    return {
        friendlyName: value.FriendlyName,
        requestId: value.RequestId,
    };
};

const getUserEntities = (value: backend.RequestAnswer): backend.UserAnswer[] => {
    const stepsUsers = value.Steps.reduce<backend.UserAnswer[]>((accumData, step) => {
        const participantsUsers = step.Participants.flatMap((p) => [p, p.AddedBy, p.DelegateFor]);
        const editorUsers = step.Editors.map((p) => p);

        return [...accumData, ...participantsUsers, ...editorUsers];
    }, []);

    const historyUsers = value.History.reduce<(backend.UserAnswer | undefined)[]>((accumData, history) => {
        const matchedDocumentsUsers = history.Changes?.MatchedDocuments?.map((p) => p.RequestAuthor) || [];
        const unMatchedDocumentsUsers = history.Changes?.UnmatchedDocuments?.map((p) => p.RequestAuthor) || [];
        const addedAttachmentsUsers = history.Changes?.AddedAttachments?.map((p) => p.Author) || [];
        const removedAttachmentsUsers = history.Changes?.RemovedAttachments?.map((p) => p.Author) || [];
        const attachmentsUsers = history.Comment?.CommentAttachments?.map((a) => a.Author) || [];
        const addedReviewers = history.Changes?.ReviewStepsChanges?.AddedReviewers || [];
        const removedReviewers = history.Changes?.ReviewStepsChanges?.RemovedReviewers || [];

        return [
            history.Author,
            history.OnBehalfOf,
            history.Comment?.Author,
            ...attachmentsUsers,
            ...matchedDocumentsUsers,
            ...unMatchedDocumentsUsers,
            ...addedAttachmentsUsers,
            ...removedAttachmentsUsers,
            ...addedReviewers,
            ...removedReviewers,
        ];
    }, []);

    const requestUserEntities: (backend.UserAnswer | undefined)[] = [
        value.Author,
        ...(value.Attachments?.map((a) => a.Author) || []),
        ...stepsUsers,
        ...(value.MatchingAgreement?.OpeningDocuments.map((d) => d.RequestAuthor) || []),
        ...(value.MatchingAgreement?.ClosingDocuments.map((d) => d.RequestAuthor) || []),
        ...value.History.flatMap((h) => [h.Author, h.OnBehalfOf, h?.Comment?.Author]),
        ...historyUsers,
        ...(value.ReviewStep?.Reviewers || []).flatMap((reviewer) => [reviewer, reviewer.DelegateFor]),
    ];

    return requestUserEntities.reduce<backend.UserAnswer[]>((accumData, userAnswer) => {
        return userAnswer ? [...accumData, userAnswer] : accumData;
    }, []);
};

const mapRequestReviewer = (value: backend.RequestReviewerAnswer): domain.RequestReviewer => {
    return {
        ...schemas.mapUser(value),
        addedBy: value.AddedBy?.UserEmail || null,
        source: value.Source,
        delegateFor: value.DelegateFor?.UserEmail || null,
    };
};

const mapReviewStep = (value: backend.RequestAnswer['ReviewStep']): domain.Request['reviewStep'] => {
    if (!value) return null;

    return {
        reviewStepId: value.ReviewStepId,
        reviewers: value.Reviewers.map(mapRequestReviewer),
        completedAt: value.CompletedAt,
        completedByUser: value.CompletedByUser && schemas.mapUser(value.CompletedByUser),
        isCompleted: value.IsCompleted,
    };
};

const mapRequest = (value: backend.RequestAnswer): domain.Request & { userEntities: backend.UserAnswer[] } => {
    if (!miscHelpers.isEnumValue(domain.IntegrationCode, value.IntegrationCode) && value.IntegrationCode !== null) {
        throw errorHelpers.notSupportedError('Unknown integration code.');
    }

    let requestSpecifics: RequestSpecifics;

    switch (getIntegrationTypeByCode(value.IntegrationCode)) {
        case IntegrationType.Xero:
            requestSpecifics = mapXeroRequestSpecifics(value);

            break;

        case IntegrationType.QBooks:
            requestSpecifics = getQbooksBase(value);
            break;

        case IntegrationType.NetSuite:
            requestSpecifics = mapNetSuiteRequestSpecifics(value);
            break;

        case IntegrationType.Dear:
            requestSpecifics = getDearRequestSpecifics(value);
            break;

        case IntegrationType.None:
            requestSpecifics = getStandaloneRequestSpecifics(value);
            break;
    }

    const authorRules = (value.AuthorRules || [])
        .flatMap((r) => r.Conditions || [])
        .map(mapMatrixCondition)
        .filter(selectors.matrix.filterBrokenConditions);

    const canCreateContact = authorRules.some(
        (c) =>
            [
                FieldSystemPurpose.QBooksVendor,
                FieldSystemPurpose.QBooksPayeeVendor,
                FieldSystemPurpose.QBooksInvoiceCustomer,
                FieldSystemPurpose.XeroSupplier,
                FieldSystemPurpose.XeroCustomer,
                FieldSystemPurpose.NetSuiteVendor,
            ].includes(c.fieldSystemPurpose) && c.allowCreation
    );
    const canCreateBeneficiary = authorRules.some(
        (c) => c.fieldSystemPurpose === FieldSystemPurpose.AirwallexBeneficiary && c.allowCreation
    );

    const canEditManualRate = authorRules.some((condition) => {
        if (
            condition.fieldSystemPurpose === FieldSystemPurpose.XeroManualExchangeRate ||
            condition.fieldSystemPurpose === FieldSystemPurpose.QBooksManualExchangeRate
        ) {
            return condition.allowEditing;
        }

        return false;
    });

    const userEntities = getUserEntities(value);

    const baseRequest: domain.BaseRequest = {
        id: value.RequestId,
        authorId: value.Author.UserEmail,
        companyId: value.CompanyId,
        createdDate: value.CreatedDate,
        modifiedDate: value.ModifiedDate,
        name: value.Name,
        displayName: value.FriendlyName,
        description: value.Description,
        requestNote: {
            value: value.RequestNote,
            errorMessages: null,
        },
        currency: value.Currency || statics.currency.defaultCurrency,
        currencyExchangeRate: typeof value.CurrencyExchangeRate === 'number' ? value.CurrencyExchangeRate : null,
        amount: value.Amount,
        companyCurrency: value.CompanyCurrency,
        auditReportUrl: value.AuditReportUrl,
        template: {
            id: value.TemplateId,
            text: value.TemplateName || '',
        },
        templateVersionIsObsolete: !value.TemplateVersionIsActual,
        exchangeRate: value.ExchangeRate || null,
        exchangeRateDate: value.ExchangeRateDate || null,
        exchangeRateSource: value.ExchangeRateSource || null,
        statusV2: value.StatusV2,
        resolutionDate: value.ResolutionDate || null,
        resolutionOrigin: mapRequestResolutionOrigin(value.ResolutionOrigin),
        origin: mapRequestOrigin(value.Origin),
        fraudulentActivity: value.FraudulentActivity.map(mapRequestFraudulentActivity),
        requiresEditingForReviewerV1: Boolean(value.IMustEdit),
        requiresEditingForEditOnApproval: Boolean(value.IMustEditForEditOnApproval),
        editPermissionsRequiredFieldsThatShouldBeFilled:
            value.EditPermissionsRequiredFieldsThatShouldBeFilled?.map(mapField) || [],
        canCreateContact,
        canCreateBeneficiary,
        authorRules,
        matchingAgreement: mapMatchingAgreement(value.MatchingAgreement),
        steps: value.Steps.map(mapStep),
        requiredFieldIds: value.RequiredFieldIds || [],
        version: value.Version,
        // The data below might be missing when requested via GetStartOverRequests.
        fieldValues: value.FieldsValues ? mapFieldValues(value.FieldsValues) : [],
        history: value.History ? value.History.map(mapHistoryEvent) : [],
        attachments: (value.Attachments || [])
            .map((a) => mapAttachment(a))
            .sort(compareHelpers.comparatorFor<RequestAttachment>(compareHelpers.stringComparator2Asc, 'name')),
        isManualExchangeRate: value.IsManualExchangeRate,
        canEditManualRate,
        payers: (value.Payers || [])?.map(schemas.mapUser),
        deadlineDate: value.DeadlineDate || null,
        watchers: (value.Watchers || [])?.map(mapWatcher),
        workflowVersionId: value.WorkflowVersionId,
        reviewStep: mapReviewStep(value.ReviewStep),
        emailToContact: value.EmailToContact && mapEmailToContact(value.EmailToContact),
    };

    return {
        ...baseRequest,
        ...requestSpecifics,
        userEntities,
    };
};

export default new schema.Entity(
    'requests',
    { userEntities: [userSchema] },
    { idAttribute: 'RequestId', processStrategy: mapRequest }
);
