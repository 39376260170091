import { Button, Text } from '@approvalmax/ui/src/components';
import { ColumnDefinition } from '@approvalmax/ui/src/components/Table/Table.types';
import { errorHelpers, intl, numberHelpers } from '@approvalmax/utils';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { noValue } from 'shared/constants';
import { getPath, Path } from 'urlBuilder';

import { renderDate, renderPaymentDate } from './RequestList.helpers';
import { messages } from './RequestList.messages';
import { ColumnId } from './RequestList.types';

export const friendlyNameColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.FriendlyName,
    name: messages.friendlyNameColumnText,
    value: (request) => (
        <Button
            as='a'
            target='_blank'
            uppercase={false}
            size='small'
            href={getPath(Path.request, request.id, request.companyId)}
        >
            {request.displayName}
        </Button>
    ),
};

export const statusColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Status,
    name: messages.statusColumnText,
    value: (request: selectors.types.ExpandedRequest) => {
        switch (request.statusV2) {
            case domain.RequestStatusV2.OnApproval:
                return messages.onApproval;

            case domain.RequestStatusV2.Rejected:
                return messages.rejected;

            case domain.RequestStatusV2.Approved:
                return messages.approved;

            case domain.RequestStatusV2.Cancelled:
                return messages.cancelled;

            case domain.RequestStatusV2.OnReview:
                return messages.onReview;

            case domain.RequestStatusV2.OnHold:
                return messages.onHold;

            case domain.RequestStatusV2.Draft:
            default:
                throw errorHelpers.throwError('Unexpected value of request.statusV2');
        }
    },
};

export const dateColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Date,
    name: messages.dateColumnText,
    value: (request) => renderDate(request),
};

export const requesterColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Requester,
    name: messages.requesterColumnText,
    value: (request: selectors.types.ExpandedRequest) => {
        switch (request.origin) {
            case domain.RequestOrigin.ReceiptBank:
                return messages.receivedFromReceiptBankOrigin;

            case domain.RequestOrigin.ApprovalMax:
            case domain.RequestOrigin.PublicApi:
            case domain.RequestOrigin.Email:
                return request.author.displayName;

            case domain.RequestOrigin.QBooks:
            case domain.RequestOrigin.Xero:
            case domain.RequestOrigin.NetSuite:
            case domain.RequestOrigin.Dear:
                return messages.pulledFromOrigin({
                    integrationName: selectors.integration.getIntegrationTypeName(request.integrationType),
                });

            default:
                throw errorHelpers.assertNever(request.origin);
        }
    },
};

export const amountColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Amount,
    name: messages.amountColumnText,
    headerTextAlign: 'right',
    textAlign: 'right',
    value: (request: selectors.types.ExpandedRequest) => {
        const amount = request.amount;

        return numberHelpers.isNumber(amount)
            ? intl.formatCurrency(amount, request.currency)
            : messages.requestAmountEmptyValue({
                  currency: request.currency,
              });
    },
};

export const workflowStepColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.WorkflowStep,
    name: messages.workflowStepColumnText,
    value: (request) => (
        <Text font='body' ellipsis={1}>
            {!request.reviewStep || request.reviewStep.isCompleted
                ? request.activeStep?.name || noValue
                : messages.reviewStepText}
        </Text>
    ),
};

export const paymentDateColumn: ColumnDefinition<selectors.types.ExpandedRequest> = {
    id: ColumnId.Date,
    name: messages.paymentDateColumnText,
    value: (request) => renderPaymentDate(request),
};
