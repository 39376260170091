import { hooks } from '@approvalmax/utils';
import {
    ChangeEvent,
    forwardRef,
    ForwardRefExoticComponent,
    memo,
    MemoExoticComponent,
    RefAttributes,
    useCallback,
} from 'react';

import { CheckIcon, SubtractIcon } from '../../icons';
import { useChecked, useGroup, useValidate } from './Checkbox.hooks';
import { Control, Icon, Input, Label, Root } from './Checkbox.styles';
import { CheckboxProps, ChildrenComponents } from './Checkbox.types';
import { Controller, Group } from './components';

/**
 * Checkboxes allow the user to select one or more items from a set. Also, Checkbox can be used to turn an option on or off.
 */
const Checkbox = memo(
    forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
        const {
            innerRef,
            size = 'medium',
            children,
            checked = false,
            disabled,
            onChange,
            invalid,
            indeterminate,
            block,
            alignItems,
            ...inputProps
        } = useGroup({ props, ref });

        const { checkedValue, setCheckedValue } = useChecked(checked);
        const { invalidValue, setInvalidValue } = useValidate(invalid);
        const { inputRef, parentProps } = hooks.useCaptureFocus(innerRef);

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setCheckedValue(event.target.checked);
                setInvalidValue(false);

                onChange && onChange(event.target.checked, event);
            },
            [onChange, setCheckedValue, setInvalidValue]
        );

        return (
            <Root
                $size={size}
                $checked={checkedValue}
                $indeterminate={indeterminate}
                $disabled={disabled}
                $invalid={invalidValue}
                $block={block}
                $alignItems={alignItems}
                {...parentProps}
            >
                <Control>
                    <Input
                        type='checkbox'
                        checked={checkedValue}
                        // we are using `aria-checked` because `styled` doesn't pass it to the actual DOM
                        // while `checked` attr is important for e2e-tests
                        aria-checked={checkedValue}
                        aria-invalid={invalidValue}
                        ref={inputRef}
                        disabled={disabled}
                        onChange={handleChange}
                        {...inputProps}
                    />

                    {checkedValue && <Icon as={indeterminate ? SubtractIcon : CheckIcon} />}
                </Control>

                {children && <Label>{children}</Label>}
            </Root>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<CheckboxProps & RefAttributes<HTMLInputElement>>> &
    ChildrenComponents;

Checkbox.Controller = Controller;
Checkbox.Group = Group;

export default Checkbox;
