import { Reference } from '@approvalmax/types';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { domain } from 'modules/data';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useGetFields } from 'shared/data';

const halfAnHour = 30 * 60 * 1000;

const notSupportedFieldSystemPurposes = [
    domain.FieldSystemPurpose.QBooksCustom,
    domain.FieldSystemPurpose.QBooksTerm,
    domain.FieldSystemPurpose.Amount,
    domain.FieldSystemPurpose.XeroManualExchangeRate,
    domain.FieldSystemPurpose.QBooksManualExchangeRate,
    domain.FieldSystemPurpose.Requester,
    domain.FieldSystemPurpose.General,
];

interface Props extends PropsWithChildren {
    field: domain.Field;
    staticValues?: Reference[];
    integrationCode?: domain.IntegrationCode | null;
    query?: string;
}

export const useFieldData = (props: Props) => {
    const { field, integrationCode = null, staticValues = [], query } = props;

    const [enabled, setEnabled] = useState(false);
    const [items, setItems] = useState<Reference[]>([]);

    const { data, isFetching } = useGetFields(
        {
            companyId: field.companyId,
            integrationCode,
            query,
            withValues: true,
            fieldsId: [field.id],
            startFrom: 0,
            rowNum: 116,
        },
        {
            enabled: !notSupportedFieldSystemPurposes.includes(field.systemPurpose) && enabled,
            cacheTime: halfAnHour,
            staleTime: halfAnHour,
        }
    );

    useEffect(() => {
        if (data) {
            const exactValues = data.fields[0]?.exactValues || [];

            const newItems = exactValues.map((exactValue) => {
                if (
                    field.systemPurpose === domain.FieldSystemPurpose.AirwallexPaymentPurpose ||
                    field.systemPurpose === domain.FieldSystemPurpose.AirwallexBatchPaymentStatus
                ) {
                    return {
                        id: exactValue.fieldValueId,
                        text: capitalize(lowerCase(exactValue.value)),
                    };
                }

                return {
                    id: exactValue.fieldValueId,
                    text: exactValue.value,
                };
            });

            setItems([...newItems, ...staticValues]);
        }
    }, [data, field.systemPurpose, staticValues]);

    const trigger = useCallback(() => {
        setEnabled(true);
    }, []);

    return {
        items,
        isFetching,
        trigger,
    };
};
