import { domHelpers } from '@approvalmax/utils';

import Checkbox from '../../../Checkbox/Checkbox';
import { checkboxColumnId, typedMemo } from '../../Table.helpers';
import { BaseItem, TableCheckType } from '../../Table.types';
import { ColumnHeader } from '..';
import { HeaderCellDragHandler } from '../ColumnHeader/ColumnHeader.styles';
import { useCheckedColumn } from './HeaderRow.hooks';
import { Root } from './HeaderRow.styles';
import { HeaderRowProps } from './HeaderRow.types';

const HeaderRow = typedMemo(<Item extends BaseItem>(props: HeaderRowProps<Item>) => {
    const {
        columns,
        getRowId,
        checkedItems,
        onCheckedItemsChange,
        bordered,
        data,
        onSort,
        headerColor,
        stickyHeader,
        checkboxColumnCover,
        isReorderableColumns,
        isReorderableRows,
        checkType,
        qa,
        ...otherProps
    } = props;

    const { checkboxColumnDefinition, handleColumnCheck } = useCheckedColumn(
        data,
        checkedItems,
        getRowId,
        onCheckedItemsChange
    );

    return (
        <Root $bordered={bordered} data-qa={domHelpers.generateDataQa(qa, 'table-header')} {...otherProps}>
            <HeaderCellDragHandler $hide={!isReorderableRows} />

            {checkedItems && (
                <ColumnHeader
                    key={checkboxColumnId}
                    columnDefinition={checkboxColumnCover ? checkboxColumnCover : checkboxColumnDefinition}
                    color={headerColor}
                    bordered={bordered}
                    onSort={onSort}
                    stickyHeader={stickyHeader}
                >
                    {checkType === TableCheckType.checkbox && (
                        <Checkbox
                            size='small'
                            indeterminate={(checkedItems?.length ?? 0) < data.length}
                            checked={(checkedItems?.length ?? 0) > 0}
                            onChange={handleColumnCheck}
                        />
                    )}
                </ColumnHeader>
            )}

            {columns.map((columnDefinition) => {
                const ColumnComponent = columnDefinition.columnComponent || ColumnHeader;

                return (
                    <ColumnComponent
                        key={columnDefinition.id}
                        columnDefinition={columnDefinition}
                        color={headerColor}
                        bordered={bordered}
                        onSort={onSort}
                        stickyHeader={stickyHeader}
                        reorderable={isReorderableColumns}
                        data-column-id={columnDefinition.id}
                    />
                );
            })}
        </Root>
    );
});

export default HeaderRow;
