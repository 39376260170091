import { ApiError, Command, ErrorCode } from '@approvalmax/types';
import { errorHelpers, intl } from '@approvalmax/utils';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { domain, State, stateTree } from 'modules/data';
import createCachedSelector from 're-reselect';
import { defineMessages, MessageDescriptor } from 'react-intl';

import * as companySelectors from './companySelectors';
import { ExpandedIntegration, IntegrationError, IntegrationState } from './types';

type DefinedMessagesType = {
    [messageKey: string]: MessageDescriptor;
};

const xeroErrorMessages: DefinedMessagesType = defineMessages({
    error_text_5003: {
        id: 'xero_integration_short_error_text_5003',
        defaultMessage: 'Connection error: OAuth token expired. Please reconnect to Xero again.',
    },
    error_text_5004: {
        id: 'xero_integration_short_error_text_5004',
        defaultMessage: 'Connection error: OAuth token is not authorized. Please reconnect.',
    },
    error_text_5006: {
        id: 'xero_integration_short_error_text_5006',
        defaultMessage: 'Connection error: Request token key does not match. Please reconnect to Xero again.',
    },
    error_text_5013: {
        id: 'xero_integration_short_error_text_5013',
        defaultMessage: 'Connection error: Xero API call rate exceeded. Please retry in a minute.',
    },
    error_text_5014: {
        id: 'xero_integration_short_error_text_5014',
        defaultMessage: 'Connection error: OAuth token rejected. Please reconnect to Xero again.',
    },
    error_text_5015: {
        id: 'xero_integration_short_error_text_5015',
        defaultMessage: 'Connection error: General integration authentication error. Please reconnect to Xero again.',
    },
    error_text_4050: {
        id: 'xero_integration_short_error_text_4050',
        defaultMessage: 'Connection error: Generic error during integraion',
    },
});

const qbooksErrorMessages: DefinedMessagesType = defineMessages({});

const xeroMessages = defineMessages({
    error_text_generic: {
        id: 'xero_integration_short_error_text_generic',
        defaultMessage: 'Connection error: Generic error in Xero connection',
    },
});

const qbooksMessages = defineMessages({
    error_text_generic: {
        id: 'qbooks_integration_short_error_text_generic',
        defaultMessage: 'Connection error: Generic error in QuickBooks connection',
    },
});

const dearErrorMessages: { [messageKey: string]: MessageDescriptor } = defineMessages({
    errorText7504: {
        id: 'dearIntegrationErrorText7504',
        defaultMessage: 'Connection error: Invalid login attempt',
    },
});

const dearMessages = defineMessages({
    integrationGenericErrorText: {
        id: 'dearIntegrationGenericErrorText',
        defaultMessage: 'Connection error: Generic error in Cin7 Core connection',
    },
});

const netSuiteErrorMessages: {
    [messageKey: string]: MessageDescriptor;
} = defineMessages({
    errorText7504: {
        id: 'netSuiteIntegrationErrorText7504',
        defaultMessage: 'Connection error: Invalid login attempt',
    },
});

const netSuiteMessages = defineMessages({
    integrationGenericErrorText: {
        id: 'netSuiteIntegrationGenericErrorText',
        defaultMessage: 'Connection error: Generic error in Oracle NetSuite connection',
    },
});

const i18nPrefix = 'common.integrationSelectors';
const messages = defineMessages({
    xeroIntegrationDisplayName: {
        id: `${i18nPrefix}.xeroIntegrationDisplayName`,
        defaultMessage: 'Xero',
    },
    qbooksIntegrationDisplayName: {
        id: `${i18nPrefix}.qbooksIntegrationDisplayName`,
        defaultMessage: 'QuickBooks Online',
    },
    netsuiteIntegrationDisplayName: {
        id: `${i18nPrefix}.netsuiteIntegrationDisplayName`,
        defaultMessage: 'Oracle NetSuite',
    },
    dearIntegrationDisplayName: {
        id: `${i18nPrefix}.dearIntegrationDisplayName`,
        defaultMessage: 'Cin7 Core',
    },
});

function getIntegrationError(integration: domain.Integration | null): IntegrationError | null {
    if (!integration) {
        return null;
    }

    if (integration.status !== domain.IntegrationStatus.Connected && integration.errorStatusCode) {
        let description;

        switch (integration.integrationType) {
            case domain.IntegrationType.Xero:
                description = intl.formatMessage(
                    xeroErrorMessages[`error_text_${integration.errorStatusCode}`] || xeroMessages.error_text_generic
                );
                break;

            case domain.IntegrationType.QBooks:
                description = intl.formatMessage(
                    qbooksErrorMessages[`error_text_${integration.errorStatusCode}`] ||
                        qbooksMessages.error_text_generic
                );
                break;

            case domain.IntegrationType.Dear:
                description = intl.formatMessage(
                    dearErrorMessages[`errorText${integration.errorStatusCode}`] ||
                        dearMessages.integrationGenericErrorText
                );
                break;

            case domain.IntegrationType.NetSuite:
                description = intl.formatMessage(
                    netSuiteErrorMessages[`errorText${integration.errorStatusCode}`] ||
                        netSuiteMessages.integrationGenericErrorText
                );
                break;

            default:
                throw errorHelpers.notSupportedError(
                    `IntegrationType '${integration.integrationType}' is not supported.`
                );
        }

        return {
            statusCode: integration.errorStatusCode,
            description,
        };
    }

    return null;
}

export function isIntegrationError(error?: Error): error is ApiError {
    const errorCode = errorHelpers.getErrorCode(error);

    if (errorCode) {
        return false;
    }

    const integrationErrorCodes: string[] = [
        ErrorCode.E5001_XERO_GENERAL_INTEGRATION_ERROR,
        ErrorCode.E5002_XERO_API_CLIENT_MUST_BE_AUTHORIZED,
        ErrorCode.E5003_XERO_OAUTH_TOKEN_EXPIRED,
        ErrorCode.E5005_XERO_REQUEST_TOKEN_NOT_FOUND,
        ErrorCode.E5006_XERO_REQUEST_TOKEN_KEY_DOESNOT_MATCH,
        ErrorCode.E5014_XERO_OAUTH_TOKEN_REJECTED,
        ErrorCode.E5015_XERO_GENERAL_INTEGRATION_AUTHENTICATION_ERROR,
        ErrorCode.E4123_ANOTHER_COMPANY_IS_ALREADY_INTEGRATED,
        ErrorCode.E4043_INTEGRATION_MUST_BE_ACTIVE,
        ErrorCode.E4063_UNSUPPORTED_INTEGRATION_TYPE,
    ];

    return integrationErrorCodes.includes(errorCode);
}

export const expandIntegration: (integration: domain.Integration) => ExpandedIntegration = createCachedSelector(
    (integration: domain.Integration) => integration,
    (integration) => {
        const displayName = getIntegrationTypeName(integration.integrationType);

        return {
            ...integration,
            displayName,
            error: getIntegrationError(integration),
        };
    }
)((integration: domain.Integration) => integration.id);

export function findIntegrationById(state: stateTree.State, integrationId: string): ExpandedIntegration | null {
    if (!integrationId) {
        return null;
    }

    const integration = state.entities.integrations[integrationId];

    return integration ? expandIntegration(integration) : null;
}

export function getIntegrationById(state: stateTree.State, integrationId: string): ExpandedIntegration {
    const integration = state.entities.integrations[integrationId];

    if (!integration) {
        throw errorHelpers.notFoundError(`Integration ${integrationId} not found.`);
    }

    return expandIntegration(integration);
}

export function getIntegrationByCompanyId(state: stateTree.State, companyId: string): ExpandedIntegration | null {
    const integration = find(state.entities.integrations, (i) => i.companyId === companyId);

    return integration ? expandIntegration(integration) : null;
}

export function hasActiveIntegration(state: stateTree.State, companyId: string): boolean {
    const integration = getIntegrationByCompanyId(state, companyId);

    return Boolean(integration && integration.status === domain.IntegrationStatus.Connected);
}

export function getConnectedIntegrations(state: stateTree.State): ExpandedIntegration[] {
    return filter(
        state.entities.integrations,
        (integration) => integration.status === domain.IntegrationStatus.Connected
    ).map(expandIntegration);
}

export function getIntegrations(state: stateTree.State): ExpandedIntegration[] {
    return Object.values(state.entities.integrations).map(expandIntegration);
}

export function getIntegrationType(integrationCode: domain.IntegrationCode | null): domain.IntegrationType {
    switch (integrationCode) {
        case domain.IntegrationCode.XeroBill:
        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable:
        case domain.IntegrationCode.XeroInvoice:
        case domain.IntegrationCode.XeroContact:
        case domain.IntegrationCode.XeroPo:
        case domain.IntegrationCode.XeroBillBatchPayment:
        case domain.IntegrationCode.XeroAirwallexBatchPayment:
        case domain.IntegrationCode.XeroAmaxPayBatchPayment:
        case domain.IntegrationCode.XeroQuote:
        case domain.IntegrationCode.XeroManualJournal:
            return domain.IntegrationType.Xero;

        case domain.IntegrationCode.QBooksBill:
        case domain.IntegrationCode.QBooksExpense:
        case domain.IntegrationCode.QBooksInvoice:
        case domain.IntegrationCode.QBooksJournalEntry:
        case domain.IntegrationCode.QBooksPo:
        case domain.IntegrationCode.QBooksVendor:
            return domain.IntegrationType.QBooks;

        case domain.IntegrationCode.NetSuiteBill:
        case domain.IntegrationCode.NetSuitePO:
        case domain.IntegrationCode.NetSuiteSalesOrder:
        case domain.IntegrationCode.NetSuiteInvoice:
        case domain.IntegrationCode.NetSuiteExpenseReport:
        case domain.IntegrationCode.NetSuiteVRA:
        case domain.IntegrationCode.NetSuiteBillPayment:
        case domain.IntegrationCode.NetSuiteRA:
        case domain.IntegrationCode.NetSuiteJournalEntry:
            return domain.IntegrationType.NetSuite;

        case domain.IntegrationCode.DearPo:
            return domain.IntegrationType.Dear;

        case null:
            return domain.IntegrationType.None;

        default:
            throw errorHelpers.assertNever(integrationCode);
    }
}

export function getIntegrationTypeName(integrationType: domain.IntegrationType): string {
    switch (integrationType) {
        case domain.IntegrationType.Xero:
            return intl.formatMessage(messages.xeroIntegrationDisplayName);

        case domain.IntegrationType.QBooks:
            return intl.formatMessage(messages.qbooksIntegrationDisplayName);

        case domain.IntegrationType.NetSuite:
            return intl.formatMessage(messages.netsuiteIntegrationDisplayName);

        case domain.IntegrationType.Dear:
            return intl.formatMessage(messages.dearIntegrationDisplayName);

        case domain.IntegrationType.None:
            return '';

        default:
            throw errorHelpers.notImplementedError();
    }
}

export function getExplainedIntegrationState(integration: domain.Integration | null): IntegrationState {
    const neverConnectedBefore = Boolean(!integration || integration.isNew);

    let integrationStatus;

    if (!integration) {
        integrationStatus = domain.IntegrationStatus.Disconnected;
    } else {
        integrationStatus = integration.status;
    }

    const isConnected = integrationStatus === domain.IntegrationStatus.Connected;

    return {
        neverConnectedBefore,
        isDisconnected: integrationStatus === domain.IntegrationStatus.Disconnected,
        isDisconnecting: integrationStatus === domain.IntegrationStatus.Disconnecting,
        isConnecting: integrationStatus === domain.IntegrationStatus.Connecting,
        isConnectingFinalizing: integrationStatus === domain.IntegrationStatus.ConnectingFinalizing,
        isConnected,
        isConnectedFirstTime: isConnected && integration!.firstTimeConnection,
    };
}

function isReadonly(state: stateTree.State, companyId: string): boolean {
    const company = companySelectors.getCompanyById(state, companyId);

    return !companySelectors.canUpdateCompanySettings(state, company) || company.isReadonly;
}

export function getChangeIntegrationCommand(state: stateTree.State, companyId: string): Command {
    if (isReadonly(state, companyId)) {
        return {
            disabled: true,
        };
    }

    const integration = getIntegrationByCompanyId(state, companyId);
    const st = getExplainedIntegrationState(integration);
    const enabled = st.isConnected || st.isDisconnected;

    return {
        disabled: !enabled,
    };
}

export function getEditIntegrationTemplatesCommand(state: stateTree.State, companyId: string): Command {
    if (isReadonly(state, companyId)) {
        return {
            disabled: true,
        };
    }

    const integration = getIntegrationByCompanyId(state, companyId);

    if (!integration) {
        throw errorHelpers.invalidOperationError();
    }

    const st = getExplainedIntegrationState(integration);
    const enabled = st.isConnected;

    return {
        disabled: !enabled,
    };
}

export function getCreateStandaloneTemplatesCommand(state: stateTree.State, companyId: string): Command {
    return {
        disabled: isReadonly(state, companyId),
    };
}

export function getIntegrationCacheItems(
    state: stateTree.State,
    integration: domain.Integration
): domain.IntegrationCacheItem[] {
    return integration.cacheItems || [];
}

export function findCompanyByExternalOrgId(state: State, externalOrgId: string) {
    let integration = getIntegrations(state).find((x) => x.integratedCompanyId === externalOrgId);

    if (!integration) {
        return null;
    }

    return integration.companyId;
}
