import {
    DraftIcon,
    FileSearchIcon,
    FilesQuestionIcon,
    FileUserIcon,
    OcrIcon,
    ReadyForPaymentIcon,
} from '@approvalmax/ui';
import { selectors } from 'modules/common';
import { useSelector } from 'modules/react-redux';
import { RequestListFilter } from 'pages/requestList/config';
import { useMemo } from 'react';
import bemFactory from 'react-bem-factory';
import { isOcrEnableForCompany } from 'shared/helpers';
import { getPath, Path } from 'urlBuilder';

import { messages } from './AllCompaniesMenu.messages';

const qa = bemFactory.qa('drawer-header');

export const useAllCompaniesLinksItems = () => {
    const companies = useSelector(selectors.company.getCompanies);
    const showMyReviewRequiredLink = companies.some(selectors.company.getIsReviewStepAvailable);
    const showMyOcrRequests = useMemo(() => companies.some((company) => isOcrEnableForCompany(company)), [companies]);
    const showMyReadyToPay = useSelector(selectors.company.hasCompaniesWithActiveWorkflowsWhereUserIsPayer);

    return useMemo(
        () => [
            {
                id: RequestListFilter.MyDecisionRequired,
                to: getPath(Path.requestMyList, RequestListFilter.MyDecisionRequired),
                dataQa: qa('my-decisions-required-link'),
                dataQaName: 'myDecisionsRequired',
                title: messages.myDecisionRequiredLinkTitle,
                name: messages.myDecisionRequiredLinkName,
                startIcon: FilesQuestionIcon,
            },
            ...(showMyReviewRequiredLink
                ? [
                      {
                          id: RequestListFilter.MyReviewRequired,
                          to: getPath(Path.requestMyList, RequestListFilter.MyReviewRequired),
                          dataQa: qa('my-reviews-required-link'),
                          dataQaName: 'myReviewsRequired',
                          title: messages.myReviewRequiredLinkTitle,
                          name: messages.myReviewRequiredLinkName,
                          startIcon: FileSearchIcon,
                      },
                  ]
                : []),
            ...(showMyOcrRequests
                ? [
                      {
                          id: RequestListFilter.MyOcrRequests,
                          to: getPath(Path.requestMyList, RequestListFilter.MyOcrRequests),
                          dataQa: qa('my-ocr-requests-link'),
                          dataQaName: 'MyOcrRequests',
                          title: messages.myOcrRequestsLinkTitle,
                          name: messages.myOcrRequestsLinkName,
                          startIcon: OcrIcon,
                      },
                  ]
                : []),
            {
                id: RequestListFilter.MyOnlyOpen,
                to: getPath(Path.requestMyList, RequestListFilter.MyOnlyOpen),
                dataQa: qa('my-only-open-link'),
                dataQaName: 'myOnlyOpen',
                title: messages.myOpenLinkTitle,
                name: messages.myOpenLinkName,
                startIcon: FileUserIcon,
            },
            ...(showMyReadyToPay
                ? [
                      {
                          id: RequestListFilter.MyReadyToPay,
                          to: getPath(Path.requestMyList, RequestListFilter.MyReadyToPay),
                          dataQa: qa('my-ready-to-pay-link'),
                          dataQaName: 'myReadyToPay',
                          title: messages.myReadyToPayTitle,
                          name: messages.myReadyToPayName,
                          startIcon: ReadyForPaymentIcon,
                      },
                  ]
                : []),
            {
                id: RequestListFilter.MyDraft,
                to: getPath(Path.requestMyList, RequestListFilter.MyDraft),
                dataQa: qa('my-draft-link'),
                dataQaName: 'myDrafts',
                title: messages.myDraftLinkTitle,
                name: messages.myDraftLinkName,
                startIcon: DraftIcon,
            },
        ],
        [showMyReviewRequiredLink, showMyOcrRequests, showMyReadyToPay]
    );
};
