import { ErrorCode } from '@approvalmax/types';
import * as Sentry from '@sentry/browser';
import * as common from 'modules/common';
import { contextInitializationCompleted } from 'modules/common/actions';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import { api } from 'services/api';
import { errorService } from 'services/error';
import { storageService } from 'services/storage';
import { useGetUserContext } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

export const useApplicationUserContext = () => {
    const dispatch = useDispatch();

    const isAuthenticated = storageService.authenticated();
    const history = useHistory();
    const isTwoFaHardEnforcementPage = history.location.pathname === getPath(Path.twoFaHardEnforcement);
    const isMobileInfoPage = history.location.pathname === getPath(Path.mobile);
    const isAuthenticatedRef = useRef(isAuthenticated);
    const [loaded, setLoaded] = useState(false);

    const enabled = isAuthenticatedRef.current && !isTwoFaHardEnforcementPage && !isMobileInfoPage;

    const { data: context, isInitialLoading } = useGetUserContext({
        retry: 0,
        enabled,
        onError: (error) => {
            if (error.code === ErrorCode.E4181_TWO_FA_HARD_REQUIRED) {
                dispatch(common.actions.setAuthenticatedState(true));
            } else {
                dispatch(contextInitializationCompleted());
            }

            if (errorService.isAuthError(error)) {
                storageService.setAuthenticated(false);
            }
        },
    });

    useMount(() => {
        if (!enabled) {
            dispatch(contextInitializationCompleted());
        }
    });

    useLayoutEffect(() => {
        if (!context || loaded) return;

        if (!context.UserProfile.TimeZoneForEmail) {
            const timezone = window.ApprovalMax.userTimeZone;

            if (timezone) {
                api.profile.setTimeZoneForEmail({ timeZoneForEmail: timezone });
            }
        }

        Sentry.configureScope((scope) => {
            scope.setUser({
                id: context?.UserProfile?.UserId,
                email: context?.UserProfile?.Email,
            });
        });

        setLoaded(true);
        dispatch(
            common.actions.loadInitialAppData({
                context,
            })
        );
    }, [context, dispatch, loaded]);

    return {
        isInitialLoading,
    };
};
