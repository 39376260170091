import { useTheme } from '@approvalmax/theme';
import { ConfirmationManager, Toast } from '@approvalmax/ui/src/components';
import PagesRootLayout from 'modules/page/containers/RootLayout/RootLayout';
import { allModuleComponents } from 'modules/store';
import { memo } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { errorService } from 'services/error';
import { Layout, NewOrganisationPopup, NewRequestPopup } from 'shared/components';
import { userBetaFeaturesStatusState } from 'shared/states';
import { getDefaultPath } from 'urlBuilder';

import workflowsRoute from './(workspace)/[companyId]/workflows';
import { PageRoute, UnsavedChangesModule, WorkflowWizard } from './resources/components';
import { withProviders } from './resources/hocs/withProviders';
import { useActiveCompanyId } from './resources/hooks/useActiveCompanyId';
import { useFileUploadTimestampsSync } from './resources/hooks/useFileUploadTimestampsSync';
import { useHasChangesLegacy } from './resources/hooks/useHasChangesLegacy';
import { useLoadApplication } from './resources/hooks/useLoadApplication';
import { useRoutes } from './resources/hooks/useRoutes';

// export separately for tests
export const RootLayout = memo(() => {
    const isAppLoading = useLoadApplication();
    const { isBelgradeUINewOrg, isBelgradeUINewRequest } = useRecoilValue(userBetaFeaturesStatusState);

    useFileUploadTimestampsSync();
    useActiveCompanyId();
    useHasChangesLegacy();
    errorService.useGlobalApiErrors();

    const { routerRedesign, routerBasic } = useRoutes();
    const { theme } = useTheme();

    if (isAppLoading) return null;

    return (
        <SkeletonTheme baseColor={theme.color.silver80} highlightColor={theme.color.silver100} borderRadius={4}>
            <Layout>
                <Switch>
                    <Route path={routerRedesign.paths} exact>
                        <Switch>{routerRedesign.routes.map(PageRoute)}</Switch>
                    </Route>

                    <Route path={routerBasic.paths} exact>
                        <PagesRootLayout>
                            <Switch>
                                {routerBasic.routes.map(PageRoute)}

                                <Route path='/'>
                                    <Redirect to={getDefaultPath()} />
                                </Route>
                            </Switch>
                        </PagesRootLayout>
                    </Route>

                    <Route path='/*'>
                        <Redirect to={getDefaultPath()} />
                    </Route>
                </Switch>
            </Layout>

            <UnsavedChangesModule />

            {allModuleComponents.map((ModuleComponent, i) => (
                <ModuleComponent key={i} />
            ))}

            <Toast />

            <ConfirmationManager />

            {isBelgradeUINewOrg && <NewOrganisationPopup />}

            {isBelgradeUINewRequest && <NewRequestPopup />}

            <Route path={workflowsRoute.path}>
                <WorkflowWizard />
            </Route>
        </SkeletonTheme>
    );
});

export default withProviders(RootLayout);
