import { forwardRef, memo } from 'react';

import { Root } from './Flex.styles';
import { FlexProps } from './Flex.types';

/**
 * The Flex component is designed to create rows and columns with any content.
 * The Flex system is based on `24' columns. You can use a Flex inside another
 * Flex component or use a single Flex column to set the alignment of child
 * elements.
 *
 * Each Flex component can be used as a container or as a column.
 */
export const Flex = memo(
    forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
        const {
            spacing = '16',
            wrap = true,
            basis,
            shrink,
            grow,
            size,
            justifyContent,
            alignItems,
            container,
            height,
            width,
            maxHeight,
            maxWidth,
            minHeight,
            minWidth,
            inline,
            offset,
            direction = 'row',
            overflowY,
            children,
            ...restProps
        } = props;

        const spacingArr = spacing.split(' ').map((item) => Number(item));
        const [rowSpacing, colSpacing] = spacingArr.length === 1 ? [spacingArr[0], spacingArr[0]] : spacingArr;

        return (
            <Root
                {...restProps}
                $rowSpacing={rowSpacing}
                $colSpacing={colSpacing}
                $wrap={wrap}
                $basis={basis}
                $grow={grow}
                $shrink={shrink}
                $size={size}
                $justifyContent={justifyContent}
                $alignItems={alignItems}
                $container={container}
                $height={height}
                $maxHeight={maxHeight}
                $width={width}
                $maxWidth={maxWidth}
                $minHeight={minHeight}
                $minWidth={minWidth}
                $inline={inline}
                $offset={offset}
                $direction={direction}
                $overflowY={overflowY}
                ref={ref}
            >
                {children}
            </Root>
        );
    })
);

Flex.displayName = 'Flex';
