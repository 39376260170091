import { arrayHelpers, intl } from '@approvalmax/utils';
import { constants } from 'modules/common';
import { domain } from 'modules/data';
import { defineMessages } from 'react-intl';

import { QBooksCustomerCreationConditionCell } from '../components/MatrixPopupContent/components/QBooksCustomerCreationConditionCell/QBooksCustomerCreationConditionCell';
import QBooksVendorCreationConditionCell from '../components/MatrixPopupContent/components/QBooksVendorCreationConditionCell/QBooksVendorCreationConditionCell';
import { AccessType, MatrixType } from '../types/matrix';
import {
    getFilteredDefinitions,
    InternalMatrixDefinitionMap,
    MatrixDefinition,
    renderAmountCell,
    renderDefaultHeader,
    renderExactAsyncCell,
    renderHeaderWithAccessType,
    RenderOptions,
} from './matrixDefinitions.shared';

const { qBooksConstants } = constants;

const i18nPrefix = 'app.WorkflowId.config.matrixDefinitions.qbooks';
const messages = defineMessages({
    qbooksApprovalMatrixAmountHeaderText: {
        id: `${i18nPrefix}.qbooksApprovalMatrixAmountHeaderText`,
        defaultMessage: 'Total amount',
    },
});

const getAutoApproveStep = (options: { integrationCode: domain.IntegrationCode | null; matrixType: MatrixType }) => {
    const expenseColumns =
        options.integrationCode === domain.IntegrationCode.QBooksExpense
            ? [
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPaymentAccount,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPaymentType,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
                  {
                      systemPurpose: domain.FieldSystemPurpose.QBooksPaymentMethod,
                      renderHeader: (options: RenderOptions) => renderDefaultHeader(options),
                      renderCell: renderExactAsyncCell,
                  },
              ]
            : [];

    return {
        columns: [
            {
                systemPurpose: domain.FieldSystemPurpose.Amount,
                renderHeader: (options) =>
                    renderDefaultHeader(options, {
                        fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                    }),
                renderCell: (options) =>
                    renderAmountCell({
                        ...options,
                        minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                        maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                    }),
            },
            {
                systemPurpose: domain.FieldSystemPurpose.Requester,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                renderHeader: renderDefaultHeader,
                renderCell: renderExactAsyncCell,
            },
            ...expenseColumns,
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                renderHeader: renderDefaultHeader,
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                renderHeader: renderDefaultHeader,
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
            {
                systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                renderHeader: (options) => renderDefaultHeader(options),
                renderCell: renderExactAsyncCell,
            },
        ],
    } as MatrixDefinition;
};

const qboJournalEntryApprovalColumns: MatrixDefinition['columns'] = [
    {
        systemPurpose: domain.FieldSystemPurpose.Amount,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
            }),
        renderCell: (options) =>
            renderAmountCell({
                ...options,
                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
            }),
    },
    {
        systemPurpose: domain.FieldSystemPurpose.Requester,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: 'Vendor',
            }),
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: 'Customer',
            }),
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: 'Employee',
            }),
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
        renderHeader: (options) =>
            renderDefaultHeader(options, {
                fieldName: 'Account',
            }),
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksCurrency,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
    {
        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
        renderHeader: renderDefaultHeader,
        renderCell: renderExactAsyncCell,
    },
];

export function getQBooksMatrixDefinitions(options: {
    integrationCode: domain.IntegrationCode | null;
    matrixType: MatrixType;
}) {
    const matrixDefinitions: InternalMatrixDefinitionMap = {
        [domain.IntegrationCode.QBooksPo]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            matrixType,
                            requiredFieldIds,
                        }) => {
                            return (
                                <QBooksVendorCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    templateSubmitters={arrayHelpers.emptyArray()}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: getAutoApproveStep(options),
        },
        [domain.IntegrationCode.QBooksInvoice]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksInvoiceCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksTax,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksInvoiceCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            condition,
                            integrationCode,
                            onConditionChange,
                            field,
                            lineId,
                            matrixType,
                            rule,
                            readonly,
                        }) => (
                            <QBooksCustomerCreationConditionCell
                                integrationCode={integrationCode}
                                onConditionChange={onConditionChange}
                                field={field}
                                lineId={lineId}
                                matrixType={matrixType}
                                rule={rule}
                                readonly={readonly}
                                condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                            />
                        ),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksTax,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: getAutoApproveStep(options),
        },
        [domain.IntegrationCode.QBooksBill]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            matrixType,
                            requiredFieldIds,
                        }) => {
                            return (
                                <QBooksVendorCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    templateSubmitters={arrayHelpers.emptyArray()}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: getAutoApproveStep(options),
        },
        [domain.IntegrationCode.QBooksExpense]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentType,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentMethod,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Amount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: intl.formatMessage(messages.qbooksApprovalMatrixAmountHeaderText),
                            }),
                        renderCell: (options) =>
                            renderAmountCell({
                                ...options,
                                minValue: qBooksConstants.TOTAL_MIN_AMOUNT,
                                maxValue: qBooksConstants.MATRIX_TOTAL_MAX_AMOUNT,
                            }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentType,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentMethod,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
                        renderHeader: renderDefaultHeader,
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            matrixType,
                            requiredFieldIds,
                        }) => {
                            return (
                                <QBooksVendorCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    templateSubmitters={arrayHelpers.emptyArray()}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentType,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPaymentMethod,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: getAutoApproveStep(options),
        },
        [domain.IntegrationCode.QBooksVendor]: {
            [MatrixType.Approval]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Reviewer]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.Requester,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksVendor,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: ({
                            rule,
                            field,
                            readonly,
                            integrationCode,
                            lineId,
                            condition,
                            onConditionChange,
                            matrixType,
                            requiredFieldIds,
                        }) => {
                            return (
                                <QBooksVendorCreationConditionCell
                                    rule={rule}
                                    field={field}
                                    readonly={readonly}
                                    integrationCode={integrationCode}
                                    lineId={lineId}
                                    onConditionChange={onConditionChange}
                                    condition={condition as domain.ExactValuesCondition | domain.AlwaysTrueCondition}
                                    templateSubmitters={arrayHelpers.emptyArray()}
                                    requiredFieldIds={requiredFieldIds}
                                    matrixType={matrixType}
                                />
                            );
                        },
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPoItem,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory],
                                defaultAccessType: AccessType.Mandatory,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCustomer,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
        },
        [domain.IntegrationCode.QBooksJournalEntry]: {
            [MatrixType.Approval]: { columns: qboJournalEntryApprovalColumns },
            [MatrixType.Reviewer]: { columns: qboJournalEntryApprovalColumns },
            [MatrixType.Requester]: {
                columns: [
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeVendor,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: 'Vendor',
                            }),
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeCustomer,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: 'Customer',
                            }),
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksPayeeEmployee,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: 'Employee',
                            }),
                        renderCell: (options) => renderExactAsyncCell({ ...options, noEmptyValue: true }),
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksAccount,
                        renderHeader: (options) =>
                            renderDefaultHeader(options, {
                                fieldName: 'Account',
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksCurrency,
                        renderHeader: renderDefaultHeader,
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksDepartment,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                    {
                        systemPurpose: domain.FieldSystemPurpose.QBooksClass,
                        renderHeader: (options) =>
                            renderHeaderWithAccessType(options, {
                                accessTypeOptions: [AccessType.Mandatory, AccessType.Optional],
                                defaultAccessType: AccessType.Optional,
                            }),
                        renderCell: renderExactAsyncCell,
                    },
                ],
            },
            [MatrixType.AutoApproval]: { columns: qboJournalEntryApprovalColumns },
        },
    };

    return getFilteredDefinitions(matrixDefinitions, options);
}
