import { Guid, Reference, UrlReference } from '@approvalmax/types';
import { dateTimeHelpers, errorHelpers, intl } from '@approvalmax/utils';
import { constants, selectors, statics } from 'modules/common';
import { backend, domain, du, State } from 'modules/data';
import { isGrnAvailable } from 'modules/utils';
import moment from 'moment';
import { ComponentClass, FC, PropsWithChildren } from 'react';
import { getPath, Path } from 'urlBuilder';

import { showDocumentPreview } from '../../actions';
import DateCell from '../../components/cells/DateCell';
import DateTimeCell from '../../components/cells/DateTimeCell';
import { HTMLCell } from '../../components/cells/HTMLCell';
import ReferenceCell from '../../components/cells/ReferenceCell';
import StringCell from '../../components/cells/StringCell';
import UrlReferenceCell from '../../components/cells/UrlReferenceCell';
import UserCell from '../../components/cells/UserCell';
import * as cellParsers from '../../data/cellParsers';
import * as cellPrinters from '../../data/cellPrinters';
import * as reportDomain from '../../data/domain';
import { NullableBoolean } from '../../data/domain';
import * as dateRangeFilter from '../../data/filters/dateRangeFilter';
import { Filter, FilterType } from '../../data/filters/index';
import * as numberRangeFilter from '../../data/filters/numberRangeFilter';
import * as referenceListFilter from '../../data/filters/referenceListFilter';
import { ReferenceListFilter } from '../../data/filters/referenceListFilter';
import * as referenceValueFilter from '../../data/filters/referenceValueFilter';
import { ReferenceValueFilter } from '../../data/filters/referenceValueFilter';
import * as stringFilter from '../../data/filters/stringFilter';
import * as userListFilter from '../../data/filters/userListFilter';
import { createCachedOptionsSelector } from '../../data/filters/utils';
import { ReportConfigColumn } from '../../data/reportConfig';
import { PreviewMode } from '../../reducers/page/documentPreviewReducer';
import { isCustomField } from '../../selectors/reportConfigSelectors';
import { ColumnGroup } from '../columnGroups';
import { ParseCellValueFunction } from '../types';
import {
    auditReportStatusColumnMessages,
    fraudulentActivityColumnMessages,
    lineItemClassesColumnMessages,
    matchedBillsColumnMessages,
    matchedPOColumnMessages,
    messages,
    qboPOStatusMessages,
    resolutionColumnMessages,
    retrospectivePOMessages,
    taxTypeColumnMessages,
} from './columnDefinitions.messages';
import ColumnKind from './columnKind';

const dispatch = (action: any) => window.ApprovalMax.app.getStore().dispatch(action);

const otherWorkflows = [
    domain.IntegrationCode.XeroContact,
    domain.IntegrationCode.QBooksVendor,
    domain.IntegrationCode.XeroBillBatchPayment,
    domain.IntegrationCode.XeroAirwallexBatchPayment,
    domain.IntegrationCode.XeroAmaxPayBatchPayment,
    domain.IntegrationCode.XeroManualJournal,
];

interface CellComponentProps {
    rowId: Guid;
    value: any;
    className?: string;
}

export interface DataProviderProps extends PropsWithChildren {}

export interface ReportColumnDefinition {
    // 1. General info
    id: string;
    kind: ColumnKind;
    name: string;
    title: string;
    group: ColumnGroup;
    requiresConnectedIntegration: boolean;
    requiresConnectedServices?: { airwallex?: boolean };
    isNotVisible?: boolean;
    visibleByDefault: boolean;
    sortable: boolean;
    scope: {
        // undefined integrationType means that the column doesn't require any integration
        integrationType?: domain.IntegrationType[];
        // undefined integrationCode means that the column is only scoped within its integrationType
        integrationCode?: domain.IntegrationCode[];
        excludedIntegrationCode?: domain.IntegrationCode[];
        xeroMatchingV1Required?: boolean;
        xeroMatchingV2Required?: boolean;
        qBooksMatchingRequired?: boolean;
    };
    fieldSystemPurpose?: domain.FieldSystemPurpose[];
    filterType: FilterType;
    // 2. Data rendering
    parseCellValue: ParseCellValueFunction;
    printCell: (value: any) => string;
    cellComponent: ComponentClass<CellComponentProps> | FC<CellComponentProps>;
    // 3. Filters
    dataProvider?: ComponentClass<DataProviderProps> | FC<DataProviderProps>;

    getOptions?(
        state: State,
        column: ReportConfigColumn | null,
        companyId: string,
        reportType?: domain.ReportType
    ): Reference[];

    parseFilter?(filterAnswer: any | null, state: State, companyId: string): Filter;

    getFilterTransfer?(filter: Filter): any;

    exactValuesToSetVisibleByDefault?: string[];
}

export interface extendedReportColumnDefinition extends Omit<ReportColumnDefinition, 'name'> {
    name: ((integrationType?: domain.IntegrationType) => string) | string;
}

export interface TemplateReference extends Reference {
    integrationCode: domain.IntegrationCode | null;
    lineItemCode?: domain.ReportCode | null;
    otherWorkflow: boolean;
}

export function getColumnDefinitionByKind(
    columnKind: ColumnKind,
    company?: selectors.types.ExpandedCompany
): ReportColumnDefinition {
    const colDef = getColumnDefinitions(company).find((x) => x.kind === columnKind);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return colDef;
}

export function getColumnDefinitionById(id: string, company?: selectors.types.ExpandedCompany): ReportColumnDefinition {
    const colDef = getColumnDefinitions(company).find((x) => x.id === id);

    if (!colDef) {
        throw errorHelpers.notImplementedError();
    }

    return colDef;
}

function getOptionById(columnDefinition: ReportColumnDefinition, state: State, companyId: string, id: string) {
    return columnDefinition.getOptions!(state, null, companyId).find((o) => o.id === id)!;
}

export function getScopedColumns(columns: ReportConfigColumn[], company: selectors.types.ExpandedCompany) {
    const integration = company.integration;
    const templateColumn = columns.find((c) => c.kind === ColumnKind.TemplateId)!;
    const templateValues = (templateColumn.filter as ReferenceListFilter).values as TemplateReference[];

    function isEnabledIntegrationCode(code: domain.IntegrationCode | null) {
        return templateValues.length === 0 || templateValues.some((v) => (v.integrationCode || null) === code);
    }

    return columns.filter((column) => {
        const grnColumn = column.id === ColumnKind.GrnStatus;
        const grnAvailable = isGrnAvailable(company);

        if (grnColumn && !grnAvailable) {
            return false;
        }

        const colDef: ReportColumnDefinition | null = isCustomField(column.kind)
            ? getColumnDefinitionByKind(column.kind as ColumnKind, company)
            : getColumnDefinitionById(column.id, company);

        // scope 0 (precondition): if the column requires integration => we hide it if the company has never been connected.
        if (colDef.requiresConnectedIntegration && !integration) {
            return false;
        }

        // scope 1: integration code. If included, templates values must include the code.
        if (
            colDef.scope.integrationCode &&
            !colDef.scope.integrationCode.some((code) => isEnabledIntegrationCode(code))
        ) {
            return false;
        }

        // scope 2: matcihng. If matching is enabled for qbo and xero
        const { xeroMatchingV1Required, xeroMatchingV2Required, qBooksMatchingRequired } = colDef.scope;
        const isXero = integration?.integrationType === domain.IntegrationType.Xero;
        const isQBooks = integration?.integrationType === domain.IntegrationType.QBooks;

        if (isXero) {
            const { isXeroMatchingV1ReadOnly, isXeroMatchingV2ReadOnly, isXeroMatchingV2 } = company.flags;
            const showMatchingV1Columns = isXeroMatchingV1ReadOnly;
            const showMatchingV2Columns = isXeroMatchingV2 || isXeroMatchingV2ReadOnly;

            if (xeroMatchingV1Required && xeroMatchingV2Required) {
                if (!showMatchingV1Columns && !showMatchingV2Columns) {
                    return false;
                }
            } else if (xeroMatchingV1Required) {
                if (!isXeroMatchingV1ReadOnly) {
                    return false;
                }
            } else if (xeroMatchingV2Required) {
                if (!showMatchingV2Columns) {
                    return false;
                }
            }
        }

        if (isQBooks) {
            const { isQBooksMatching } = company.flags;

            if (qBooksMatchingRequired && !isQBooksMatching) {
                return false;
            }
        }

        // scope 3: integration type. If exluded:
        if (colDef.scope.excludedIntegrationCode) {
            const hasNotExcludedTemplate = templateValues.some(
                (t) => !colDef?.scope.excludedIntegrationCode?.includes(t.integrationCode!)
            );

            if (!hasNotExcludedTemplate) {
                return false;
            }
        }

        // scope 4: integration type. If included:
        if (colDef.scope.integrationType) {
            // Option 1: match if column is scoped to standalone workflows and they are enabled.
            const hasStandaloneTemplate = templateValues.length === 0 || templateValues.some((v) => !v.integrationCode);

            const hasEmptyIntegrationType = colDef.scope.integrationType.includes(domain.IntegrationType.None);

            if (hasEmptyIntegrationType && hasStandaloneTemplate) {
                return true;
            }

            // Option 2: match if column is scoped to the integration that the company is connected to + some integration codes are checked
            const matchesCompanyIntegration =
                integration && colDef.scope.integrationType.some((type) => integration.integrationType === type);

            const someIntegrationTemplatesChecked =
                templateValues.length === 0 || templateValues.some((v) => Boolean(v.integrationCode));

            if (matchesCompanyIntegration && someIntegrationTemplatesChecked) {
                return true;
            }

            // integration type mismatch
            return false;
        }

        return true;
    });
}

export const amaxPayPaymentStatusOptions = [
    reportDomain.ANY_VALUE_REFERENCE,
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.None,
        text: messages.amaxPayBatchPaymentStatusNone,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.AwaitingPayment,
        text: messages.amaxPayBatchPaymentStatusAwaitingPayment,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.Processing,
        text: messages.amaxPayBatchPaymentStatusProcessing,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.Paid,
        text: messages.amaxPayBatchPaymentStatusPaid,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.PartiallyPaid,
        text: messages.amaxPayBatchPaymentStatusPartiallyPaid,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.Failed,
        text: messages.amaxPayBatchPaymentStatusFailed,
    },
    {
        id: domain.AmaxPayXeroBatchPaymentStatus.Cancelled,
        text: messages.amaxPayBatchPaymentStatusCancelled,
    },
];

const columnDefinitions: extendedReportColumnDefinition[] = [
    {
        id: ColumnKind.CustomField,
        kind: ColumnKind.CustomField,
        name: 'CUSTOM FIELD',
        title: 'CUSTOM FIELD',
        group: ColumnGroup.StandaloneWorkflowFields,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.None],
        },
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions(state: State | null, column: ReportConfigColumn | null) {
            if (!state || !column) {
                throw errorHelpers.invalidOperationError();
            }

            const field = selectors.field.getFieldById(state, column.id);

            return field.exactValues;
        },
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksCustomField,
        kind: ColumnKind.QBooksCustomField,
        name: 'CUSTOM FIELD',
        title: 'CUSTOM FIELD',
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksPo],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Name,
        kind: ColumnKind.Name,
        name: messages.nameColumnName,
        title: messages.nameColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {
            excludedIntegrationCode: [domain.IntegrationCode.XeroContact, domain.IntegrationCode.QBooksVendor],
        },
        parseCellValue: (value, { requestId }, col, companyId) =>
            ({
                id: requestId,
                text: value,
                url: getPath(Path.request, requestId, companyId),
            }) as UrlReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.TemplateId,
        kind: ColumnKind.TemplateId,
        name: messages.templateIdColumnName,
        title: messages.templateIdColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            // this column should always be visible,
            // TODO: just make viewable: false, for this setting if we don't want to show him for some integration type or integration code
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        getOptions(state, column, companyId, reportType): TemplateReference[] {
            return selectors.template
                .getTemplatesByCompanyId(state, companyId)
                .filter(
                    (t) =>
                        reportType !== domain.ReportType.LineItem ||
                        (t.integrationCode !== domain.IntegrationCode.XeroContact &&
                            t.integrationCode !== domain.IntegrationCode.QBooksVendor &&
                            t.integrationCode !== domain.IntegrationCode.XeroBillBatchPayment)
                )
                .map((t) =>
                    t.integrationCode
                        ? {
                              id: t.integrationCode,
                              text: t.displayName,
                              integrationCode: t.integrationCode,
                              lineItemCode: selectors.report.getLineItemTypeByIntegrationCode(t.integrationCode),
                              otherWorkflow: otherWorkflows.includes(t.integrationCode),
                          }
                        : {
                              id: t.id,
                              text: t.displayName,
                              integrationCode: null,
                              otherWorkflow: false,
                          }
                );
        },
        parseFilter(filterAnswer: any): referenceListFilter.ReferenceListFilter {
            const values = (filterAnswer || []).map((x: any) =>
                x.TemplateId
                    ? {
                          id: x.TemplateId,
                          text: x.TemplateName,
                      }
                    : {
                          id: x.IntegrationCode,
                          text: selectors.template.getTemplateDisplayNameByCode(x.IntegrationCode)!,
                          integrationCode: x.IntegrationCode,
                      }
            );

            return {
                type: FilterType.ReferenceList,
                values,
            };
        },
        getFilterTransfer(filter: referenceListFilter.ReferenceListFilter): any {
            return filter.values.map((x: any) =>
                x.integrationCode
                    ? {
                          integrationCode: x.integrationCode,
                          templateName: x.text,
                      }
                    : {
                          templateId: x.id,
                          templateName: x.text,
                      }
            );
        },
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ActiveStepName,
        kind: ColumnKind.ActiveStepName,
        name: messages.activeStepNameColumnName,
        title: messages.activeStepNameColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {},
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Attachment,
        kind: ColumnKind.Attachment,
        name: messages.attachmentColumnName,
        title: messages.attachmentColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            excludedIntegrationCode: constants.xeroConstants.batchPaymentIntegrationCodes,
        },
        parseCellValue(value, { requestId }, col, companyId) {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, requestId, companyId, { attachmentId: reference.id }),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: (props) => (
            <UrlReferenceCell
                {...props}
                onOpen={() => {
                    dispatch(
                        showDocumentPreview({
                            requestId: props.rowId,
                            attachmentId: props.value.id,
                            previewMode: PreviewMode.Attachment,
                        })
                    );
                }}
            />
        ),
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Resolution,
        kind: ColumnKind.Resolution,
        name: messages.resolutionColumnName,
        title: messages.resolutionColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {},
        parseCellValue(value: domain.RequestStatusV2, rowId, state, companyId): Reference {
            return getOptionById(this, state, companyId, value);
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions: createCachedOptionsSelector(() => {
            return [
                {
                    id: domain.RequestStatusV2.OnReview,
                    text: resolutionColumnMessages.onReview,
                },
                {
                    id: domain.RequestStatusV2.OnApproval,
                    text: resolutionColumnMessages.onApproval,
                },
                {
                    id: domain.RequestStatusV2.Approved,
                    text: resolutionColumnMessages.approved,
                },
                {
                    id: domain.RequestStatusV2.Cancelled,
                    text: resolutionColumnMessages.cancelled,
                },
                {
                    id: domain.RequestStatusV2.Rejected,
                    text: resolutionColumnMessages.rejected,
                },
                {
                    id: domain.RequestStatusV2.OnHold,
                    text: resolutionColumnMessages.onHold,
                },
            ];
        }),
        parseFilter: referenceListFilter.enumMappers.createParseFilter(backend.RequestsRequestResolution),
        getFilterTransfer: referenceListFilter.enumMappers.createGetFilterTransfer(backend.RequestsRequestResolution),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.FraudulentActivity,
        kind: ColumnKind.FraudulentActivity,
        name: messages.fraudulentActivityColumnName,
        title: messages.fraudulentActivityColumnTitle,
        group: ColumnGroup.Compliance,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroManualJournal,
            ],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            const mapRequestPendingStatus = du.getForwardEnumMapper(
                backend.RequestFraudulentActivity,
                reportDomain.RequestsFraudulentActivity
            );

            return getOptionById(this, state, companyId, mapRequestPendingStatus(value));
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions(state, column, companyId) {
            const integration = selectors.company.getCompanyById(state, companyId).integration;
            const integrationType = integration ? integration.integrationType : null;

            let options = [
                reportDomain.RequestsFraudulentActivity.None,
                reportDomain.RequestsFraudulentActivity.AdminEnforcedResolving,
                reportDomain.RequestsFraudulentActivity.AutomaticResolving,
                reportDomain.RequestsFraudulentActivity.DroppingOffApprovers,
            ].map((val) => ({
                id: val,
                text: fraudulentActivityColumnMessages[val],
            }));

            if (
                integrationType === domain.IntegrationType.Xero ||
                integrationType === domain.IntegrationType.NetSuite
            ) {
                options.push(
                    ...[
                        reportDomain.RequestsFraudulentActivity.ExternalResolving,
                        reportDomain.RequestsFraudulentActivity.PostApprovalChange,
                    ].map((val) => {
                        if (
                            val === reportDomain.RequestsFraudulentActivity.ExternalResolving &&
                            integrationType === domain.IntegrationType.NetSuite
                        ) {
                            return {
                                id: val,
                                text: fraudulentActivityColumnMessages.ExternalResolvingNetSuite,
                            };
                        }

                        return {
                            id: val,
                            text: (fraudulentActivityColumnMessages as any)[val]({
                                integrationName: selectors.integration.getIntegrationTypeName(integrationType!),
                            }),
                        };
                    })
                );
            }

            return options;
        },
        parseFilter: referenceListFilter.enumMappers.createParseFilter(backend.RequestFraudulentActivity, {
            nullValue: reportDomain.RequestsFraudulentActivity.None,
        }),
        getFilterTransfer: referenceListFilter.enumMappers.createGetFilterTransfer(backend.RequestFraudulentActivity, {
            nullValue: reportDomain.RequestsFraudulentActivity.None,
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.CreationDate,
        kind: ColumnKind.CreationDate,
        name: messages.creationDateColumnName,
        title: messages.creationDateColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {},
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateTimeCell,
        cellComponent: DateTimeCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Description,
        kind: ColumnKind.Description,
        name: messages.descriptionColumnName,
        title: messages.descriptionColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.None],
        },
        parseCellValue: (value) => value,
        printCell: () => '',
        cellComponent: HTMLCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ResolutionDate,
        kind: ColumnKind.ResolutionDate,
        name: messages.resolutionDateColumnName,
        title: messages.resolutionDateColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {},
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateTimeCell,
        cellComponent: DateTimeCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.DueDate,
        kind: ColumnKind.DueDate,
        name: messages.dueDateColumnName,
        title: messages.dueDateColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [
                domain.IntegrationType.Xero,
                domain.IntegrationType.QBooks,
                domain.IntegrationType.NetSuite,
            ],
            integrationCode: [
                domain.IntegrationCode.XeroBill,
                domain.IntegrationCode.XeroCreditNotesPayable,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksInvoice,
                domain.IntegrationCode.QBooksExpense,
                domain.IntegrationCode.XeroInvoice,
                domain.IntegrationCode.NetSuiteExpenseReport,
                domain.IntegrationCode.NetSuiteBill,
                domain.IntegrationCode.NetSuitePO,
                domain.IntegrationCode.NetSuiteInvoice,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.DeliveryDate,
        kind: ColumnKind.DeliveryDate,
        name: messages.deliveryDateColumnName,
        title: messages.deliveryDateColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks, domain.IntegrationType.Dear],
            integrationCode: [
                domain.IntegrationCode.XeroPo,
                domain.IntegrationCode.QBooksPo,
                domain.IntegrationCode.DearPo,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroBillBatchPaymentPaymentDate,
        kind: ColumnKind.XeroBillBatchPaymentPaymentDate,
        name: messages.xeroBPPaymentDateColumnName,
        title: messages.xeroBPPaymentDateColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroBillBatchPayment],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.PaymentDate,
        kind: ColumnKind.PaymentDate,
        name: messages.paymentDateColumnName,
        title: messages.paymentDateColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroBill],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.DocumentDate,
        kind: ColumnKind.DocumentDate,
        name: messages.documentDateColumnName,
        title: messages.documentDateColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [
                domain.IntegrationType.Xero,
                domain.IntegrationType.QBooks,
                domain.IntegrationType.NetSuite,
                domain.IntegrationType.Dear,
                domain.IntegrationType.None,
            ],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.XeroBillBatchPayment,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ResolutionTime,
        kind: ColumnKind.ResolutionTime,
        name: messages.resolutionTimeColumnName,
        title: messages.resolutionTimeColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {},
        parseCellValue(value) {
            return dateTimeHelpers.formatDuration(moment.duration(value, 'seconds'));
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroBillBatchPaymentTotalAmountDue,
        kind: ColumnKind.XeroBillBatchPaymentTotalAmountDue,
        name: messages.xeroBPAmountColumnName,
        title: messages.xeroBPAmountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroBillBatchPayment],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AirwallexBatchPaymentTotalAmount,
        kind: ColumnKind.AirwallexBatchPaymentTotalAmount,
        name: messages.airwallexBPAmountColumnName,
        title: messages.airwallexBPAmountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAirwallexBatchPayment],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AmaxPayBatchPaymentTotalAmount,
        kind: ColumnKind.AmaxPayBatchPaymentTotalAmount,
        name: messages.amaxPayBatchAmountColumnName,
        title: messages.amaxPayBatchAmountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAmaxPayBatchPayment],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Amount,
        kind: ColumnKind.Amount,
        name: messages.amountColumnName,
        title: messages.amountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetAmount,
        kind: ColumnKind.NetAmount,
        name: messages.netAmountColumnName,
        title: messages.netAmountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [
                domain.IntegrationType.Xero,
                domain.IntegrationType.NetSuite,
                domain.IntegrationType.Dear,
                domain.IntegrationType.QBooks,
            ],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroManualJournal,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksExpense,
                domain.IntegrationCode.QBooksPo,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.QBooksJournalEntry,
                domain.IntegrationCode.NetSuiteBillPayment,
            ],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.TaxAmount,
        kind: ColumnKind.TaxAmount,
        name: messages.taxAmountColumnName,
        title: messages.taxAmountColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroManualJournal,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
                domain.IntegrationCode.NetSuiteBillPayment,
            ],
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Currency,
        kind: ColumnKind.Currency,
        name: messages.currencyColumnName,
        title: messages.currencyColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            excludedIntegrationCode: [
                domain.IntegrationCode.NetSuiteExpenseReport,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroManualJournal,
                domain.IntegrationCode.NetSuiteBill,
                domain.IntegrationCode.NetSuitePO,
                domain.IntegrationCode.NetSuiteSalesOrder,
                domain.IntegrationCode.NetSuiteVRA,
            ],
        },
        parseCellValue(value) {
            return statics.currency.getCurrencyShortText(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        getOptions(): Reference[] {
            return statics.currency.currencyReferences;
        },
        parseFilter: referenceListFilter.idMappers.createParseFilter(),
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuiteCurrency,
        kind: ColumnKind.NetSuiteCurrency,
        name: messages.netSuiteCurrencyColumnName,
        title: messages.netSuiteCurrencyColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [
                domain.IntegrationCode.NetSuiteBill,
                domain.IntegrationCode.NetSuitePO,
                domain.IntegrationCode.NetSuiteExpenseReport,
                domain.IntegrationCode.NetSuiteSalesOrder,
                domain.IntegrationCode.NetSuiteVRA,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.NetSuiteCurrency],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroContactName,
        kind: ColumnKind.XeroContactName,
        name: messages.contactNameColumnName,
        title: messages.contactNameColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroContact],
        },
        parseCellValue: (value, { requestId }, col, companyId) =>
            ({
                id: requestId,
                text: value,
                url: getPath(Path.request, requestId, companyId),
            }) as UrlReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroContactTaxNumber,
        kind: ColumnKind.XeroContactTaxNumber,
        name: messages.taxNumberColumnName,
        title: messages.taxNumberColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroContact],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroContactBankAccountDetails,
        kind: ColumnKind.XeroContactBankAccountDetails,
        name: messages.bankAccountDetailsColumnName,
        title: messages.bankAccountDetailsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroContact],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroContactSalesTaxCode,
        kind: ColumnKind.XeroContactSalesTaxCode,
        name: messages.salesTaxCodeColumnName,
        title: messages.salesTaxCodeColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroContact],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTax],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroContactPurchaseTaxCode,
        kind: ColumnKind.XeroContactPurchaseTaxCode,
        name: messages.purchaseTaxCodeColumnName,
        title: messages.purchaseTaxCodeColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroContact],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTax],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksVendorName,
        kind: ColumnKind.QBooksVendorName,
        name: messages.vendorNameColumnName,
        title: messages.vendorNameColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksVendor],
        },
        parseCellValue: (value, { requestId }, col, companyId) =>
            ({
                id: requestId,
                text: value,
                url: getPath(Path.request, requestId, companyId),
            }) as UrlReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    // TODO: vendor: add under certain conditions
    // {
    //     id: ColumnKind.QBooksVendorBusinessNumber,
    //     kind: ColumnKind.QBooksVendorBusinessNumber,
    //     name: messages.businessNumberColumnName,
    //     title: messages.businessNumberColumnTitle,
    //     group: ColumnGroup.KeyData,
    //     requiresConnectedIntegration: false,
    //     visibleByDefault: false,
    //     sortable: true,
    //     scope: {
    //         integrationType: [domain.IntegrationType.QBooks],
    //         integrationCode: [domain.IntegrationCode.QBooksVendor],
    //     },
    //     parseCellValue: cellParsers.parseNoop,
    //     printCell: cellPrinters.printCellNoop,
    //     CellComponent: StringCell,
    //     filterType: FilterType.String,
    //     parseFilter: stringFilter.parseFilter,
    //     getFilterTransfer: stringFilter.getFilterTransfer,
    // } as ReportColumnDefinition,

    {
        id: ColumnKind.TaxType,
        kind: ColumnKind.TaxType,
        name: messages.taxTypeColumnName,
        title: messages.taxTypeColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksPo,
                domain.IntegrationCode.QBooksJournalEntry,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.QBooksExpense,
            ],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return getOptionById(this, state, companyId, value);
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        getOptions: createCachedOptionsSelector(() => {
            return [
                {
                    id: reportDomain.TaxType.TaxExclusive,
                    text: taxTypeColumnMessages.taxExclusive,
                },
                {
                    id: reportDomain.TaxType.TaxInclusive,
                    text: taxTypeColumnMessages.taxInclusive,
                },
                { id: reportDomain.TaxType.NoTax, text: taxTypeColumnMessages.noTax },
            ];
        }),
        parseFilter: referenceListFilter.idMappers.createParseFilter(),
        getFilterTransfer: referenceListFilter.idMappers.createGetFilterTransfer(),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.DocumentNumber,
        kind: ColumnKind.DocumentNumber,
        name: messages.documentNumberColumnName,
        title: messages.documentNumberColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [
                domain.IntegrationCode.XeroInvoice,
                domain.IntegrationCode.XeroPo,
                domain.IntegrationCode.XeroQuote,
                domain.IntegrationCode.XeroCreditNotesReceivable,
                domain.IntegrationCode.QBooksPo,
                domain.IntegrationCode.QBooksInvoice,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Requesters,
        kind: ColumnKind.Requesters,
        name: messages.requestersColumnName,
        title: messages.requestersColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {},
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserList,
        parseFilter: userListFilter.parseFilter,
        getFilterTransfer: userListFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ParticipantsApproved,
        kind: ColumnKind.ParticipantsApproved,
        name: messages.participantsApprovedColumnName,
        title: messages.participantsApprovedColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {},
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserList,
        parseFilter: userListFilter.parseFilter,
        getFilterTransfer: userListFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ParticipantsRejected,
        kind: ColumnKind.ParticipantsRejected,
        name: messages.participantsRejectedColumnName,
        title: messages.participantsRejectedColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {},
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserList,
        parseFilter: userListFilter.parseFilter,
        getFilterTransfer: userListFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ParticipantsNonResponded,
        kind: ColumnKind.ParticipantsNonResponded,
        name: messages.participantsNonRespondedColumnName,
        title: messages.participantsNonRespondedColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: false,
        scope: {},
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserList,
        parseFilter: userListFilter.parseFilter,
        getFilterTransfer: userListFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Contact,
        kind: ColumnKind.Contact,
        name: (integrationType?: domain.IntegrationType) => {
            if (integrationType === domain.IntegrationType.QBooks) {
                return messages.contactQBOColumnName;
            }

            return messages.contactColumnName;
        },
        title: messages.contactColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroManualJournal,
                domain.IntegrationCode.QBooksVendor,
                domain.IntegrationCode.QBooksInvoice,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroSupplier, domain.FieldSystemPurpose.QBooksVendor],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as extendedReportColumnDefinition,

    {
        id: ColumnKind.Customer,
        kind: ColumnKind.Customer,
        name: messages.customerColumnName,
        title: messages.customerColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            IntegrationCode: [domain.IntegrationCode.QBooksInvoice],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksCustomer],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as extendedReportColumnDefinition,

    {
        id: ColumnKind.QBooksPayeeCustomer,
        kind: ColumnKind.QBooksPayeeCustomer,
        name: messages.qBooksPayeeCustomerColumnName,
        title: messages.qBooksPayeeCustomerColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksExpense],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPayeeCustomer],
        isNotVisible: true,
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksPayeeEmployee,
        kind: ColumnKind.QBooksPayeeEmployee,
        name: messages.qBooksPayeeEmployeeColumnName,
        title: messages.qBooksPayeeEmployeeColumnTitle,
        group: ColumnGroup.KeyData,
        isNotVisible: true,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksExpense],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPayeeEmployee],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksPaymentAccount,
        kind: ColumnKind.QBooksPaymentAccount,
        name: messages.qBooksPaymentAccountColumnName,
        title: messages.qBooksPaymentAccountColumnTitle,
        group: ColumnGroup.ExpenseDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksExpense],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPaymentAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksPaymentType,
        kind: ColumnKind.QBooksPaymentType,
        name: messages.qBooksPaymentTypeColumnName,
        title: messages.qBooksPaymentTypeColumnTitle,
        group: ColumnGroup.ExpenseDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksExpense],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPaymentType],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksPaymentMethod,
        kind: ColumnKind.QBooksPaymentMethod,
        name: messages.qBooksPaymentMethodColumnName,
        title: messages.qBooksPaymentMethodColumnTitle,
        group: ColumnGroup.ExpenseDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksExpense],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksPaymentMethod],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Reference,
        kind: ColumnKind.Reference,
        name: messages.referenceColumnName,
        title: messages.referenceColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.NetSuite],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroManualJournal,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        parseCellValue(value) {
            return value || null;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Narration,
        kind: ColumnKind.Narration,
        name: messages.narrationColumnName,
        title: messages.narrationColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroManualJournal],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.String,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Branding,
        kind: ColumnKind.Branding,
        name: messages.brandingColumnName,
        title: messages.brandingColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroBranding],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.SentToSupplier,
        kind: ColumnKind.SentToSupplier,
        name: messages.sentToSupplierColumnName,
        title: messages.sentToSupplierColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            const idResolver = (currentValue: any) =>
                currentValue ? domain.EmailToSupplierIsSent.Yes : domain.EmailToSupplierIsSent.No;

            return getOptionById(this, state, companyId, idResolver(value));
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.DearSupplier,
        kind: ColumnKind.DearSupplier,
        name: messages.dearSupplierColumnName,
        title: messages.dearSupplierColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Dear],
            integrationCode: [domain.IntegrationCode.DearPo],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.DearSupplier],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AuditReportStatus,
        kind: ColumnKind.AuditReportStatus,
        name: messages.auditReportStatusColumnName,
        title: messages.auditReportStatusColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: false,
        scope: {
            excludedIntegrationCode: [domain.IntegrationCode.XeroBillBatchPayment],
        },
        parseCellValue(value, { requestId }, state, companyId): UrlReference {
            const reference = (this.parseFilter!([value], state, companyId) as ReferenceValueFilter).value;

            let url;

            if (reference.id === reportDomain.RequestAuditReportState.Complete) {
                url = getPath(Path.request, requestId, companyId, { auditReport: true });
            }

            return {
                ...reference,
                url,
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: (props) => (
            <UrlReferenceCell
                {...props}
                onOpen={() => {
                    dispatch(
                        showDocumentPreview({
                            requestId: props.rowId, // here
                            previewMode: PreviewMode.AuditReport,
                        })
                    );
                }}
            />
        ),
        filterType: FilterType.ReferenceValue,
        getOptions: createCachedOptionsSelector(() => {
            return [
                reportDomain.ANY_VALUE_REFERENCE,
                {
                    id: reportDomain.RequestAuditReportState.Complete,
                    text: auditReportStatusColumnMessages.auditReportStatusColumnValueTextReady,
                },
                {
                    id: reportDomain.RequestAuditReportState.Underway,
                    text: auditReportStatusColumnMessages.auditReportStatusColumnValueTextUnderway,
                },
                {
                    id: reportDomain.RequestAuditReportState.Reserved,
                    text: auditReportStatusColumnMessages.auditReportStatusColumnValueTextUnderway,
                },
            ];
        }),
        parseFilter(filterAnswer, state, companyId): ReferenceValueFilter {
            const firstValue = (filterAnswer || [])[0];

            let value: Reference;

            switch (firstValue) {
                case backend.RequestAuditReportsRequestAuditReportState.Underway:
                    value = getOptionById(this, state, companyId, reportDomain.RequestAuditReportState.Underway);
                    break;

                case backend.RequestAuditReportsRequestAuditReportState.Complete:
                    value = getOptionById(this, state, companyId, reportDomain.RequestAuditReportState.Complete);
                    break;

                case backend.RequestAuditReportsRequestAuditReportState.Reserved:
                    value = getOptionById(this, state, companyId, reportDomain.RequestAuditReportState.Reserved);
                    break;

                default:
                    value = getOptionById(this, state, companyId, reportDomain.ANY_VALUE_REFERENCE_ID);
                    break;
            }

            return {
                type: FilterType.ReferenceValue,
                value,
            };
        },
        getFilterTransfer(filter: ReferenceValueFilter): any {
            switch (filter.value.id) {
                case reportDomain.ANY_VALUE_REFERENCE_ID:
                    return [];

                case reportDomain.RequestAuditReportState.Underway:
                    return [
                        backend.RequestAuditReportsRequestAuditReportState.Underway,
                        backend.RequestAuditReportsRequestAuditReportState.Reserved,
                    ];

                case reportDomain.RequestAuditReportState.Complete:
                    return [backend.RequestAuditReportsRequestAuditReportState.Complete];

                default:
                    throw errorHelpers.invalidOperationError();
            }
        },
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Location,
        kind: ColumnKind.Location,
        name: messages.locationColumnName,
        title: messages.locationColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [
                domain.IntegrationCode.QBooksPo,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksExpense,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksDepartment],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.LineItemClasses,
        kind: ColumnKind.LineItemClasses,
        name: messages.lineItemClassesColumnName,
        title: messages.lineItemClassesColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksPo],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksClass],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    ((): ReportColumnDefinition => {
        return {
            id: ColumnKind.LineItemContacts,
            kind: ColumnKind.LineItemContacts,
            name: lineItemClassesColumnMessages.columnName,
            title: lineItemClassesColumnMessages.columnTitle,
            group: ColumnGroup.PoDetails,
            requiresConnectedIntegration: true,
            visibleByDefault: false,
            sortable: false,
            scope: {
                integrationType: [domain.IntegrationType.QBooks],
                integrationCode: [domain.IntegrationCode.QBooksPo],
            },
            fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksCustomer],
            parseCellValue: cellParsers.parseReference,
            printCell: cellPrinters.printReferenceCell,
            cellComponent: ReferenceCell,
            filterType: FilterType.ReferenceList,
            parseFilter: referenceListFilter.referenceMappers.parseFilter,
            getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
        };
    })(),

    {
        id: ColumnKind.LineItemAccounts,
        kind: ColumnKind.LineItemAccounts,
        name: messages.lineItemAccountsColumnName,
        title: messages.lineItemAccountsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.XeroBillBatchPaymentBankAccount,
        kind: ColumnKind.XeroBillBatchPaymentBankAccount,
        name: messages.bankAccountsColumnName,
        title: messages.bankAccountsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroBillBatchPayment],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroBankAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksLineItemAccounts,
        kind: ColumnKind.QBooksLineItemAccounts,
        name: messages.qbooksLineItemAccountsColumnName,
        title: messages.qbooksLineItemAccountsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            excludedIntegrationCode: [domain.IntegrationCode.QBooksVendor, domain.IntegrationCode.QBooksInvoice],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.QBooksAccount],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.LineItemTrackings1,
        kind: ColumnKind.LineItemTrackings1,
        name: messages.lineItemTrackings1ColumnName,
        title: messages.lineItemTrackings1ColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTracking],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.LineItemTrackings2,
        kind: ColumnKind.LineItemTrackings2,
        name: messages.lineItemTrackings2ColumnName,
        title: messages.lineItemTrackings2ColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: false,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            excludedIntegrationCode: [
                domain.IntegrationCode.XeroContact,
                domain.IntegrationCode.XeroBillBatchPayment,
                domain.IntegrationCode.XeroAirwallexBatchPayment,
                domain.IntegrationCode.XeroAmaxPayBatchPayment,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.XeroTracking],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.BillBalance,
        kind: ColumnKind.BillBalance,
        name: messages.matchedBillsBalanceName,
        title: messages.matchedBillsBalanceTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroBill],
            xeroMatchingV2Required: true,
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.SumOfAllocations,
        kind: ColumnKind.SumOfAllocations,
        name: messages.sumOfAllocationsName,
        title: messages.sumOfAllocationsTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo, domain.IntegrationCode.XeroBill],
            xeroMatchingV2Required: true,
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.PurchaseOrderBalance,
        kind: ColumnKind.PurchaseOrderBalance,
        name: messages.purchaseOrderBalanceColumnName,
        title: messages.purchaseOrderBalanceColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo],
            xeroMatchingV1Required: true,
            xeroMatchingV2Required: true,
        },
        parseCellValue(value) {
            return intl.formatNumber(value);
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.NumberRange,
        parseFilter: numberRangeFilter.parseFilter,
        getFilterTransfer: numberRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.MatchedPurchaseOrders,
        kind: ColumnKind.MatchedPurchaseOrders,
        name: messages.matchedPurchaseOrdersColumnName,
        title: messages.matchedPurchaseOrdersColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.XeroBill, domain.IntegrationCode.QBooksBill],
            xeroMatchingV1Required: true,
            xeroMatchingV2Required: true,
            qBooksMatchingRequired: true,
        },
        parseCellValue(value, meta, col, companyId): UrlReference {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, reference.id, companyId),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.ReferenceValue,
        exactValuesToSetVisibleByDefault: [reportDomain.RequestMatchingStatus.Matched],

        getOptions() {
            return [
                reportDomain.ANY_VALUE_REFERENCE,
                {
                    id: reportDomain.RequestMatchingStatus.Matched,
                    text: matchedPOColumnMessages.matched,
                },
                {
                    id: reportDomain.RequestMatchingStatus.NotMatched,
                    text: matchedPOColumnMessages.notMatched,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RequestMatchingStatus.AnyValue]: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RequestMatchingStatus.Matched]: reportDomain.RequestMatchingStatus.Matched,
            [backend.RequestMatchingStatus.NotMatched]: reportDomain.RequestMatchingStatus.NotMatched,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.ANY_VALUE_REFERENCE_ID]: backend.RequestMatchingStatus.AnyValue,
            [reportDomain.RequestMatchingStatus.Matched]: backend.RequestMatchingStatus.Matched,
            [reportDomain.RequestMatchingStatus.NotMatched]: backend.RequestMatchingStatus.NotMatched,
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.MatchedBills,
        kind: ColumnKind.MatchedBills,
        name: messages.matchedBillsColumnName,
        title: messages.matchedBillsColumnTitle,
        group: ColumnGroup.Matching,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.XeroPo, domain.IntegrationCode.QBooksPo],
            xeroMatchingV1Required: true,
            xeroMatchingV2Required: true,
            qBooksMatchingRequired: true,
        },
        parseCellValue(value, meta, col, companyId): UrlReference {
            const reference = cellParsers.parseReference(value);

            return {
                ...reference,
                url: getPath(Path.request, reference.id, companyId),
            };
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: UrlReferenceCell,
        filterType: FilterType.ReferenceValue,
        exactValuesToSetVisibleByDefault: [reportDomain.RequestMatchingStatus.Matched],
        getOptions() {
            return [
                reportDomain.ANY_VALUE_REFERENCE,
                {
                    id: reportDomain.RequestMatchingStatus.Matched,
                    text: matchedBillsColumnMessages.matched,
                },
                {
                    id: reportDomain.RequestMatchingStatus.NotMatched,
                    text: matchedBillsColumnMessages.notMatched,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RequestMatchingStatus.AnyValue]: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RequestMatchingStatus.Matched]: reportDomain.RequestMatchingStatus.Matched,
            [backend.RequestMatchingStatus.NotMatched]: reportDomain.RequestMatchingStatus.NotMatched,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.ANY_VALUE_REFERENCE_ID]: backend.RequestMatchingStatus.AnyValue,
            [reportDomain.RequestMatchingStatus.Matched]: backend.RequestMatchingStatus.Matched,
            [reportDomain.RequestMatchingStatus.NotMatched]: backend.RequestMatchingStatus.NotMatched,
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.IsBilled,
        kind: ColumnKind.IsBilled,
        name: messages.isBilledColumnName,
        title: messages.isBilledColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.IsPaid,
        kind: ColumnKind.IsPaid,
        name: messages.isPaidColumnName,
        title: messages.isPaidColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [
                domain.IntegrationCode.XeroBill,
                domain.IntegrationCode.XeroCreditNotesPayable,
                domain.IntegrationCode.XeroCreditNotesReceivable,
                domain.IntegrationCode.XeroInvoice,
                domain.IntegrationCode.QBooksBill,
                domain.IntegrationCode.QBooksInvoice,
            ],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ShippingDate,
        kind: ColumnKind.ShippingDate,
        name: messages.shippingDateColumnName,
        title: messages.shippingDateColumnTitle,
        group: ColumnGroup.SalesInvoiceDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksInvoice],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.RetrospectivePurchaseOrder,
        kind: ColumnKind.RetrospectivePurchaseOrder,
        name: messages.retrospectivePurchaseOrderColumnName,
        title: messages.retrospectivePurchaseOrderColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroPo],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceValue,
        getOptions() {
            return [
                reportDomain.ANY_VALUE_REFERENCE,
                {
                    id: reportDomain.RetrospectivePurchaseOrder.IsRetrospective,
                    text: retrospectivePOMessages.retrospective,
                },
                {
                    id: reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective,
                    text: retrospectivePOMessages.notRetrospective,
                },
            ];
        },
        parseFilter: referenceValueFilter.enumBackend.createParseFilter({
            null: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RetrospectivePurchaseOrder.AnyValue]: reportDomain.ANY_VALUE_REFERENCE_ID,
            [backend.RetrospectivePurchaseOrder.IsRetrospective]:
                reportDomain.RetrospectivePurchaseOrder.IsRetrospective,
            [backend.RetrospectivePurchaseOrder.IsNotRetrospective]:
                reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective,
        }),
        getFilterTransfer: referenceValueFilter.enumBackend.createGetFilterTransfer({
            [reportDomain.ANY_VALUE_REFERENCE_ID]: backend.RetrospectivePurchaseOrder.AnyValue,
            [reportDomain.RetrospectivePurchaseOrder.IsRetrospective]:
                backend.RetrospectivePurchaseOrder.IsRetrospective,
            [reportDomain.RetrospectivePurchaseOrder.IsNotRetrospective]:
                backend.RetrospectivePurchaseOrder.IsNotRetrospective,
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.FullyPaidDate,
        kind: ColumnKind.FullyPaidDate,
        name: messages.fullyPaidDateColumnName,
        title: messages.fullyPaidDateColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [
                domain.IntegrationCode.XeroBill,
                domain.IntegrationCode.XeroCreditNotesPayable,
                domain.IntegrationCode.XeroCreditNotesReceivable,
                domain.IntegrationCode.XeroInvoice,
            ],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateCell,
        cellComponent: DateCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuiteVendor,
        kind: ColumnKind.NetSuiteVendor,
        name: messages.netSuiteVendorColumnName,
        title: messages.netSuiteVendorColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [
                domain.IntegrationCode.NetSuiteBill,
                domain.IntegrationCode.NetSuitePO,
                domain.IntegrationCode.NetSuiteVRA,
            ],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.NetSuiteVendor],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuitePayee,
        kind: ColumnKind.NetSuitePayee,
        name: messages.netSuitePayeeColumnName,
        title: messages.netSuitePayeeColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [domain.IntegrationCode.NetSuiteBillPayment],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.NetSuitePayee],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuiteEmployee,
        kind: ColumnKind.NetSuiteEmployee,
        name: messages.netSuiteEmployeeColumnName,
        title: messages.netSuiteEmployeeColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [domain.IntegrationCode.NetSuiteExpenseReport],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.NetSuiteEmployee],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuiteCustomer,
        kind: ColumnKind.NetSuiteCustomer,
        name: messages.netSuiteCustomerColumnName,
        title: messages.netSuiteCustomerColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: true,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [domain.IntegrationCode.NetSuiteSalesOrder, domain.IntegrationCode.NetSuiteInvoice],
        },
        fieldSystemPurpose: [domain.FieldSystemPurpose.NetSuiteCustomer],
        parseCellValue: cellParsers.parseReference,
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.NetSuiteTerms,
        kind: ColumnKind.NetSuiteTerms,
        name: messages.netSuiteTermsColumnName,
        title: messages.netSuiteTermsColumnTitle,
        group: ColumnGroup.BillDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.NetSuite],
            integrationCode: [domain.IntegrationCode.NetSuiteBill],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.QBooksPurchaseOrderStatus,
        kind: ColumnKind.QBooksPurchaseOrderStatus,
        name: messages.qboPurchaseOrderStatusColumnName,
        title: messages.qboPurchaseOrderStatusColumnTitle,
        group: ColumnGroup.PoDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.QBooksPo],
            qBooksPOPullingRequired: true,
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            const idResolver = du.getForwardEnumMapper(
                domain.QBooksPurchaseOrderStatusNumeric,
                domain.QBooksPurchaseOrderStatus
            );

            return getOptionById(this, state, companyId, idResolver(value));
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: createCachedOptionsSelector(() => [
            reportDomain.ANY_VALUE_REFERENCE,
            {
                id: domain.QBooksPurchaseOrderStatus.Open,
                text: qboPOStatusMessages.qboPurchaseOrderStatusOptionOpen,
            },
            {
                id: domain.QBooksPurchaseOrderStatus.Closed,
                text: qboPOStatusMessages.qboPurchaseOrderStatusOptionClosed,
            },
        ]),
        parseFilter(filterAnswer, state, companyId): ReferenceValueFilter {
            let value: Reference;

            switch (filterAnswer?.Id) {
                case domain.QBooksPurchaseOrderStatusNumeric.Open:
                    value = getOptionById(this, state, companyId, domain.QBooksPurchaseOrderStatus.Open);
                    break;

                case domain.QBooksPurchaseOrderStatusNumeric.Closed:
                    value = getOptionById(this, state, companyId, domain.QBooksPurchaseOrderStatus.Closed);
                    break;

                default:
                    value = getOptionById(this, state, companyId, reportDomain.ANY_VALUE_REFERENCE_ID);
                    break;
            }

            return {
                type: FilterType.ReferenceValue,
                value,
            };
        },
        getFilterTransfer: (filter: ReferenceValueFilter): any => {
            switch (filter.value.id) {
                case reportDomain.ANY_VALUE_REFERENCE_ID:
                    return null;

                case domain.QBooksPurchaseOrderStatus.Open:
                    return {
                        id: domain.QBooksPurchaseOrderStatusNumeric.Open,
                        text: domain.QBooksPurchaseOrderStatus.Open,
                    };

                case domain.QBooksPurchaseOrderStatus.Closed:
                    return {
                        id: domain.QBooksPurchaseOrderStatusNumeric.Closed,
                        text: domain.QBooksPurchaseOrderStatus.Closed,
                    };

                default:
                    throw errorHelpers.invalidOperationError();
            }
        },
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AirwallexBatchPaymentStatus,
        kind: ColumnKind.AirwallexBatchPaymentStatus,
        name: messages.XeroAirwallexBPPaymentStatusColumnName,
        title: messages.XeroAirwallexBPPaymentStatusColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAirwallexBatchPayment],
        },
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
        getOptions: createCachedOptionsSelector(() => {
            return [
                reportDomain.ANY_VALUE_REFERENCE,
                {
                    id: domain.AirwallexPaymentStatus.None,
                    text: domain.AirwallexPaymentStatus.None,
                },
                {
                    id: domain.AirwallexPaymentStatus.AwaitingPayment,
                    text: domain.AirwallexPaymentStatus.AwaitingPayment,
                },
                {
                    id: domain.AirwallexPaymentStatus.Processing,
                    text: domain.AirwallexPaymentStatus.Processing,
                },
                {
                    id: domain.AirwallexPaymentStatus.Paid,
                    text: domain.AirwallexPaymentStatus.Paid,
                },
                {
                    id: domain.AirwallexPaymentStatus.PartiallyPaid,
                    text: domain.AirwallexPaymentStatus.PartiallyPaid,
                },
                {
                    id: domain.AirwallexPaymentStatus.Failed,
                    text: domain.AirwallexPaymentStatus.Failed,
                },
            ];
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AmaxPayBatchPaymentStatus,
        kind: ColumnKind.AmaxPayBatchPaymentStatus,
        name: messages.amaxPayBatchPaymentStatusColumnName,
        title: messages.amaxPayBatchPaymentStatusColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAmaxPayBatchPayment],
        },
        parseCellValue: (value) => {
            if (typeof value === 'string') {
                const paymentStatus = amaxPayPaymentStatusOptions.find((option) => option.id === value);

                return paymentStatus?.text ?? value;
            }

            return value;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.ReferenceList,
        parseFilter: referenceListFilter.referenceMappers.parseFilter,
        getFilterTransfer: referenceListFilter.referenceMappers.getFilterTransfer,
        getOptions: createCachedOptionsSelector(() => {
            return amaxPayPaymentStatusOptions;
        }),
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AmaxPayBankAccountNames,
        kind: ColumnKind.AmaxPayBankAccountNames,
        name: messages.amaxPayBankAccountNamesColumnName,
        title: messages.amaxPayBankAccountNamesColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAmaxPayBatchPayment],
        },
        parseCellValue: (value) => {
            if (Array.isArray(value)) {
                return value.filter((item) => item && typeof item === 'string').join(', ');
            }

            return value;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
        parseFilter: stringFilter.parseFilter,
        getFilterTransfer: stringFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.Payers,
        kind: ColumnKind.Payers,
        name: messages.payersColumnName,
        title: messages.payersColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAirwallexBatchPayment],
        },
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserList,
        parseFilter: userListFilter.parseFilter,
        getFilterTransfer: userListFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.AmaxPayPaidByUserIds,
        kind: ColumnKind.AmaxPayPaidByUserIds,
        name: messages.payersColumnName,
        title: messages.payersColumnTitle,
        group: ColumnGroup.Workflow,
        requiresConnectedIntegration: false,
        visibleByDefault: true,
        sortable: true,
        scope: {
            integrationCode: [domain.IntegrationCode.XeroAmaxPayBatchPayment],
        },
        parseCellValue: cellParsers.parseUser,
        printCell: cellPrinters.printUserCell,
        cellComponent: UserCell,
        filterType: FilterType.UserIdsList,
        parseFilter: userListFilter.parseByIdFilter,
        getFilterTransfer: userListFilter.getFilterByIdTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.GrnStatus,
        kind: ColumnKind.GrnStatus,
        name: messages.grnColumnName,
        title: messages.grnColumnTitle,
        group: ColumnGroup.GrnStatus,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.XeroPo, domain.IntegrationCode.QBooksPo],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: createCachedOptionsSelector(() => [
            reportDomain.ANY_VALUE_REFERENCE,
            {
                id: domain.GoodsReceivedNotesStatus.NotReceived,
                text: messages.grnNotReceived,
            },
            {
                id: domain.GoodsReceivedNotesStatus.PartiallyReceived,
                text: messages.grnPartiallyReceived,
            },
            {
                id: domain.GoodsReceivedNotesStatus.FullyReceived,
                text: messages.grnFullyReceived,
            },
        ]),
        parseFilter(filterAnswer): ReferenceValueFilter {
            let value: Reference;

            switch (filterAnswer) {
                case domain.GoodsReceivedNotesStatus.NotReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.NotReceived,
                        text: messages.grnNotReceived,
                    };
                    break;

                case domain.GoodsReceivedNotesStatus.PartiallyReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.PartiallyReceived,
                        text: messages.grnPartiallyReceived,
                    };
                    break;

                case domain.GoodsReceivedNotesStatus.FullyReceived:
                    value = {
                        id: domain.GoodsReceivedNotesStatus.FullyReceived,
                        text: messages.grnFullyReceived,
                    };
                    break;

                default:
                    value = reportDomain.ANY_VALUE_REFERENCE;
                    break;
            }

            return {
                type: FilterType.ReferenceValue,
                value,
            };
        },
        getFilterTransfer(filter: ReferenceValueFilter): any {
            switch (filter.value.id) {
                case reportDomain.ANY_VALUE_REFERENCE_ID:
                    return null;

                case domain.GoodsReceivedNotesStatus.NotReceived:
                case domain.GoodsReceivedNotesStatus.PartiallyReceived:
                case domain.GoodsReceivedNotesStatus.FullyReceived:
                    return filter.value.id;

                default:
                    throw errorHelpers.invalidOperationError();
            }
        },
    } as ReportColumnDefinition,
    {
        id: ColumnKind.isAccepted,
        kind: ColumnKind.isAccepted,
        name: messages.isAcceptedColumnName,
        title: messages.isAcceptedColumnTitle,
        group: ColumnGroup.QuoteDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroQuote],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        getOptions: createCachedOptionsSelector(() => [
            reportDomain.ANY_VALUE_REFERENCE,
            {
                id: NullableBoolean.Yes,
                text: messages.isAcceptedTrue,
            },
            {
                id: NullableBoolean.No,
                text: messages.isAcceptedFalse,
            },
        ]),
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.CustomerDecisionDate,
        kind: ColumnKind.CustomerDecisionDate,
        name: messages.customerDecisionDateColumnName,
        title: messages.customerDecisionDateColumnTitle,
        group: ColumnGroup.QuoteDetails,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroQuote],
        },
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        parseCellValue: cellParsers.parseNoop,
        printCell: cellPrinters.printDateTimeCell,
        cellComponent: DateTimeCell,
        filterType: FilterType.DateRange,
        parseFilter: dateRangeFilter.parseFilter,
        getFilterTransfer: dateRangeFilter.getFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.isSent,
        kind: ColumnKind.isSent,
        name: messages.isSentColumnName,
        title: messages.isSentColumnTitle,
        group: ColumnGroup.QuoteDetails,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero, domain.IntegrationType.QBooks],
            integrationCode: [domain.IntegrationCode.XeroQuote, domain.IntegrationCode.QBooksInvoice],
        },
        parseCellValue(value, rowId, state, companyId): Reference {
            return (this.parseFilter!(value, state, companyId) as ReferenceValueFilter).value;
        },
        printCell: cellPrinters.printReferenceCell,
        cellComponent: ReferenceCell,
        filterType: FilterType.ReferenceValue,
        getOptions: referenceValueFilter.getNullableBooleanOptions,
        parseFilter: referenceValueFilter.parseNullableBooleanFilter,
        getFilterTransfer: referenceValueFilter.getNullableBooleanFilterTransfer,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.TotalCreditTax,
        kind: ColumnKind.TotalCreditTax,
        name: messages.totalCreditTaxColumnName,
        title: messages.totalCreditTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroManualJournal],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.TotalDebitTax,
        kind: ColumnKind.TotalDebitTax,
        name: messages.totalDebitTaxColumnName,
        title: messages.totalDebitTaxColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroManualJournal],
        },
        parseCellValue(value) {
            return intl.formatNumber(value, 'auto');
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,

    {
        id: ColumnKind.ShowOnCashBasisReports,
        kind: ColumnKind.ShowOnCashBasisReports,
        name: messages.showOnCashBasisReportsColumnName,
        title: messages.showOnCashBasisReportsColumnTitle,
        group: ColumnGroup.KeyData,
        requiresConnectedIntegration: false,
        visibleByDefault: false,
        sortable: true,
        scope: {
            integrationType: [domain.IntegrationType.Xero],
            integrationCode: [domain.IntegrationCode.XeroManualJournal],
        },
        parseCellValue(value) {
            return value ? messages.yes : messages.no;
        },
        printCell: cellPrinters.printCellNoop,
        cellComponent: StringCell,
        filterType: FilterType.None,
    } as ReportColumnDefinition,
];

const getColumnDefinitions = (company?: selectors.types.ExpandedCompany): ReportColumnDefinition[] => {
    const integrationType = company?.integration?.integrationType;

    return columnDefinitions.map((columnDefinition) => {
        if (columnDefinition.id === ColumnKind.isSent && integrationType === domain.IntegrationType.QBooks) {
            columnDefinition.group = ColumnGroup.SalesInvoiceDetails;
        }

        return {
            ...columnDefinition,
            name:
                typeof columnDefinition.name === 'function'
                    ? columnDefinition.name(integrationType)
                    : columnDefinition.name,
        };
    });
};

export default getColumnDefinitions;
