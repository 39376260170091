import { selectors } from 'modules/common';
import { domain, State } from 'modules/data';
import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActiveTemplate } from '../../selectors/pageSelectors';
import CollapseAllButton from '../CollapseAllButton/CollapseAllButton';
import FixedStepContainer from '../FixedStepContainer/FixedStepContainer';
import SelectRBSubmitter from '../SelectRBSubmitter/SelectRBSubmitter';
import StepSubmitterSection from '../StepSubmitterSection/StepSubmitterSection';
import { messages } from './QBooksStartStep.messages';

const QBooksStartStep = memo(() => {
    const template = useSelector(getActiveTemplate)!;
    const company = useSelector((state: State) => selectors.company.getCompanyById(state, template.companyId));
    const noMatrix = template.integrationCode === domain.IntegrationCode.QBooksVendor;
    const canConnectDext =
        template.integrationCode === domain.IntegrationCode.QBooksBill ||
        template.integrationCode === domain.IntegrationCode.QBooksExpense;

    const showDextConnect = company.receiptBankIntegration?.isConnected && canConnectDext;

    const [collapsedRbSubmitterSection, setCollapsedRbSubmitterSection] = useState<boolean>();
    const [collapsedSubmitterSection, setCollapsedSubmitterSection] = useState<boolean>();

    const onCollapse = useCallback((collapsed: boolean) => {
        setCollapsedRbSubmitterSection(collapsed);
        setCollapsedSubmitterSection(collapsed);
    }, []);

    const allCollapsed = showDextConnect
        ? collapsedSubmitterSection && collapsedRbSubmitterSection
        : collapsedSubmitterSection;

    return (
        <FixedStepContainer
            bubbleText={messages.bubbleText({ templateName: template.displayName })}
            color='brandQBO100'
        >
            {showDextConnect && <CollapseAllButton collapsed={Boolean(allCollapsed)} onCollapse={onCollapse} />}

            <StepSubmitterSection
                cacheId='QBooksStartStepSubmitter'
                required
                headerText={messages.createInAmBubbleText}
                description={messages.submitterListDescriptionNonEmpty({ templateName: template.displayNamePlural })}
                descriptionEmpty={messages.submitterListDescriptionEmpty({ templateName: template.displayName })}
                collapsed={collapsedSubmitterSection}
                onCollapse={setCollapsedSubmitterSection}
                noMatrix={noMatrix}
            />

            {showDextConnect && (
                <SelectRBSubmitter
                    cacheId='QBooksStartStepRBSubmitter'
                    collapsed={collapsedRbSubmitterSection}
                    onCollapse={setCollapsedRbSubmitterSection}
                    required={false}
                />
            )}
        </FixedStepContainer>
    );
});

export default QBooksStartStep;
