import { domain, State } from 'modules/data';
import moment from 'moment';

import { createQBooksAccountLineItem } from '../../data/qbooks/QBooksAccountLineItem';
import { QBooksContext } from '../../data/qbooks/QBooksContext';
import { createQBooksLineItem } from '../../data/qbooks/QBooksLineItem';
import { getPage } from '../pageSelectors';
import { getComputedPrice } from './lineItemSelectors';

export function getInitialQBooksRequest(request: domain.QBooksRequest, qbooksContext: QBooksContext) {
    const details = request.details;

    if (
        details.integrationCode === domain.IntegrationCode.QBooksJournalEntry ||
        details.integrationCode === domain.IntegrationCode.QBooksVendor
    ) {
        return request;
    }

    if ('vendor' in details && qbooksContext.vendor) {
        details.vendor = qbooksContext.vendor;
    }

    if ('customer' in details && qbooksContext.invoiceCustomer) {
        details.customer = qbooksContext.invoiceCustomer;
    }

    if ('payee' in details && details.payee && qbooksContext.payee) {
        details.payee = {
            ...details.payee,
            address: qbooksContext.payee.address,
            email: qbooksContext.payee.email,
            taxId: qbooksContext.payee.taxId,
        };
    }

    if (details.lineItems?.length === 0) {
        details.lineItems.push(createQBooksLineItem());
    }

    if ('accountLineItems' in details && details.accountLineItems?.length === 0) {
        details.accountLineItems.push(createQBooksAccountLineItem());
    }

    if (!details.date) {
        details.date = moment().startOf('day').utcOffset(0, true).toISOString();
    }

    if (
        (details.integrationCode === domain.IntegrationCode.QBooksBill ||
            details.integrationCode === domain.IntegrationCode.QBooksInvoice) &&
        qbooksContext.terms
    ) {
        details.term = qbooksContext.terms.find(({ id }) => id === details.term?.id)!;
    }

    if (details.integrationCode === domain.IntegrationCode.QBooksInvoice) {
        details.shipping.priceGross = getComputedPrice({
            price: details.shipping.price,
            qbooksContext,
            date: details.date,
            lineAmountType: details.lineAmountType,
            taxCode: details.shippingTaxCode ?? undefined,
            taxApplicableOnType: domain.TaxApplicableOnType.Sales,
        }).computedPriceGross;
    }

    if (qbooksContext.hasTaxesFeature) {
        if (!qbooksContext.hasValidLineAmountType) {
            // Hardcoded value for QBO
            details.lineAmountType = qbooksContext.defaultLineAmountType;
        }
    } else {
        if (details.lineAmountType !== domain.LineAmountType.NoTax) {
            details.lineAmountType = domain.LineAmountType.NoTax;
        }
    }

    return request;
}

export function getQBooksContext(state: State) {
    return getPage(state).qbooksContext;
}
