import { numberHelpers, stringHelpers } from '@approvalmax/utils';
import startCase from 'lodash/startCase';
import uniqWith from 'lodash/uniqWith';
import { constants, selectors } from 'modules/common';
import { REQUEST_NOTE_EXCLUDED_ATTRIBUTES } from 'modules/common/constants/commonConstants';
import { backend, domain, du, State } from 'modules/data';
import { DiscountType } from 'modules/data/backend';
import { XeroContact } from 'pages/requestForm/data/xero/XeroContact';
import { emailToSupplierHelpers } from 'shared/helpers';

import {
    EditableXeroContact,
    EditableXeroContactAddress,
    EditableXeroContactPhone,
    EditableXeroTrackingCategory,
} from '../../data/xero/EditableXeroContact';
import { isNewXeroLineItem } from '../../data/xero/XeroLineItem';
import { getContext, getRequest } from '../pageSelectors';
import { ExpandedXeroLineItem } from '../types/ExpandedXeroLineItem';
import { addBillOrInvoiceCisDeductionIfNeed } from './cisDeductionUtils';
import { getXeroLineItems, getXeroLineItemsSummary } from './lineItemSelectors';
import { getXeroContext } from './xeroSelectors';

function toServerString(
    value: number | null | undefined,
    precision: number = constants.xeroConstants.PRECISION_TO_SERVER_STRING
) {
    if (value == null) {
        return '';
    }

    return numberHelpers.toString(value, precision);
}

export function getXeroLineItemTransfer(
    state: State,
    li: ExpandedXeroLineItem,
    request:
        | domain.XeroPoRequest
        | domain.XeroBillRequest
        | domain.XeroQuoteRequest
        | domain.XeroInvoiceRequest
        | domain.XeroCreditNotesPayableRequest
        | domain.XeroCreditNotesReceivableRequest
): backend.Transfer<backend.transfers.LineItemTransfer> {
    const context = getXeroContext(state);
    const emptyTaxCode = context.emptyTaxCode;
    const d = request.details;
    const id = isNewXeroLineItem(li) ? '' : li.id || '';

    let discountValue;

    switch (li.discountType) {
        case DiscountType.Rate:
        case DiscountType.PreRate:
            discountValue = { discount: toServerString(li.discount, 2) };
            break;

        case DiscountType.Amount:
        case DiscountType.PreAmount:
            discountValue = { discountAmount: toServerString(li.discountAmount, 2) };
            break;

        default:
            discountValue = { discount: toServerString(li.discount, 2) };
            break;
    }

    // Tracking Category can be deleted in Xero after Request creation
    const existingTrackingCategories = li.tracking.filter((lineItemTrackingCategory) =>
        context.trackingCategories.find(
            (trackingCategory) => trackingCategory.category.id === lineItemTrackingCategory.category.id
        )
    );

    return {
        id,
        itemId: du.getReferenceId(li.item) || '',
        description: li.description?.trim() || '',
        ...(li.descriptionOnly
            ? {
                  qty: '',
                  unitPrice: '',
                  accountId: '',
                  taxCode: '',
                  trackingCategories: [],
                  amount: '',
                  taxAmount: '',
              }
            : {
                  qty: toServerString(li.qty),
                  unitPrice: toServerString(li.unitPrice),
                  accountId: du.getReferenceId(li.account) || '',
                  amount: toServerString(li.computedAmount, 2),
                  taxAmount: toServerString(li.taxAmount != null ? li.taxAmount : li.computedTaxAmount, 2),
                  taxCode:
                      d.lineAmountType === domain.LineAmountType.NoTax
                          ? emptyTaxCode.id
                          : du.getReferenceId(li.tax) || '',
                  trackingCategories: existingTrackingCategories.map((v) => ({
                      id: v.category.id,
                      valueId: du.getReferenceId(v.value),
                  })),
              }),
        ...discountValue,
    };
}

function getSharedXeroDetailsTransfer(
    state: State,
    request: domain.XeroPoRequest | domain.XeroBillRequest | domain.XeroQuoteRequest | domain.XeroInvoiceRequest
): backend.Transfer<backend.transfers.IntegrationXeroFinancialDocumentTransfer> {
    const d = request.details;

    return {
        contactId: du.getReferenceId(d.contact) || '',
        reference: d.reference || '',
        date: d.date || '',
        lineItems: getXeroLineItems(state, request)
            .filter((li) => !li.empty)
            .map((li) => getXeroLineItemTransfer(state, li, request)),
        lineAmountType: d.lineAmountType || domain.LineAmountType.NoTax,
    };
}

export function getXeroPurchaseOrderDetailsTransfer(
    state: State,
    request: domain.XeroPoRequest
): backend.Transfer<backend.transfers.IntegrationXeroFinancialDocumentTransfer> {
    const { details } = request;

    return {
        ...getSharedXeroDetailsTransfer(state, request),
        deliveryDate: details.deliveryDate || '',
        ...(details.delivery
            ? {
                  deliveryAddress: details.delivery.address || '',
                  attentionTo: details.delivery.attentionTo || '',
                  deliveryInstructions: details.delivery.instructions || '',
                  phone: details.delivery.phone || '',
              }
            : {}),
        brandingThemeId: du.getReferenceId(details.brandingTheme) || null,
    };
}

export function getXeroEmailToContactParams(
    requestDetails: domain.XeroPurchaseOrderDetails | domain.XeroQuoteDetails
): backend.transfers.EmailToContact | undefined {
    const emailsToContactTo =
        requestDetails.emailToSupplier?.to
            .map((toEmail) => stringHelpers.parseEmail(toEmail))
            .filter((toEmail) => toEmail) || [];
    const emailTo =
        uniqWith(emailsToContactTo, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',') || '';

    const emailsToContactCC =
        requestDetails.emailToSupplier?.cc
            .map((ccEmail) => stringHelpers.parseEmail(ccEmail))
            .filter((ccEmail) => ccEmail && !emailTo.toLocaleLowerCase().includes(ccEmail.toLocaleLowerCase())) || [];
    const emailCC = uniqWith(emailsToContactCC, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',');

    const supplier = requestDetails.contact as XeroContact | null;
    const contactPerson = supplier?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

    let subject = emailToSupplierHelpers.removeRootParagraph(requestDetails.emailToSupplier?.subject || '');

    subject = emailToSupplierHelpers.cleanupSubjectString(subject);

    let emailSubject = emailToSupplierHelpers
        .removeMentionExtensionTags(subject)
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    emailSubject = emailToSupplierHelpers.returnExpressions(emailSubject);

    const emailBody = emailToSupplierHelpers
        .removeMentionExtensionTags(requestDetails.emailToSupplier?.body || '')
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    return requestDetails.emailToSupplier
        ? {
              emailHasToBeSent: requestDetails.sendToSupplier,
              emailTo,
              emailCC,
              emailFrom: requestDetails.emailToSupplier.from || '',
              emailReplyTo: requestDetails.emailToSupplier.replyTo || '',
              emailSubject,
              emailBody,
              attachments: requestDetails.emailToSupplier.attachments.map((a) => a.id) || [],
          }
        : undefined;
}

export function excludeEmptyPersons(person: domain.XeroContactPerson) {
    return Object.values(person).some((value?: string) => Boolean(value?.trim()));
}

export function getXeroContactDetailsTransfer(
    state: State,
    request: domain.XeroContactRequest
): backend.Transfer<backend.transfers.IntegrationXeroContactTransfer> {
    const { details } = request;

    return {
        accountNumber: details.accountNumber,
        name: details.name?.trim().replace(/\s+/g, ' '),
        firstName: details.firstName,
        lastName: details.lastName,
        emailAddress: details.emailAddress,
        persons: details.persons.filter(excludeEmptyPersons),

        phoneNumber: details.phoneNumber,
        fax: details.fax,
        mobile: details.mobile,
        directDial: details.directDial,
        postalAddress: details.postalAddress,
        mailingAddress: details.mailingAddress,
        taxNumber: details.taxNumber,
        companyNumber: details.companyNumber,
        salesAccountId: details.salesAccount?.id,
        salesTaxCode: details.salesTax?.id,
        purchaseTaxCode: details.purchaseTax?.id,
        purchasesAccountId: details.purchasesAccount?.id,
        salesTrackingCategories: getTrackingCategoriesTransfer(
            details.salesTrackingCategories.filter((category) => !!category.value)
        ),
        purchasesTrackingCategories: getTrackingCategoriesTransfer(
            details.purchasesTrackingCategories.filter((category) => !!category.value)
        ),
        defaultCurrency: details.defaultCurrency,
        bankAccountDetails: details.sortCode
            ? `${details.sortCode}${details.bankAccountDetails}`
            : details.bankAccountDetails,
        skypeName: details.skypeName,
        xeroContact: {},
        isStatusPushPending: details.isStatusPushPending,
        url: details.url,
    } as backend.transfers.IntegrationXeroContactTransfer;
}

export function getXeroInvoiceDetailsTransfer(
    state: State,
    request: domain.XeroBillRequest | domain.XeroInvoiceRequest
): backend.Transfer<backend.transfers.IntegrationXeroInvoiceTransfer> {
    const details = request.details;

    const invoiceDetailsTransfer = {
        ...getSharedXeroDetailsTransfer(state, request),
        brandingThemeId: du.getReferenceId(details.brandingTheme) || null,
        dueDate: details.dueDate || '', // Note: dueDate does not exist in Backend model
    };

    return addBillOrInvoiceCisDeductionIfNeed(invoiceDetailsTransfer, state, request);
}

export function getXeroQuoteDetailsTransfer(
    state: State,
    request: domain.XeroQuoteRequest
): backend.Transfer<backend.transfers.IntegrationXeroQuoteTransfer> {
    const { details } = request;

    return {
        ...getSharedXeroDetailsTransfer(state, request),
        expiryDate: details.expiryDate || '', // Note: dueDate does not exist in Backend model
        brandingThemeId: du.getReferenceId(details.brandingTheme) || null,
        number: details.number || null,
        title: details.title || '',
        summary: details.summary || '',
        terms: details.terms || '',
    };
}

const getXeroInvoiceEmailToContactParams = (
    requestDetails: domain.XeroInvoiceDetails
): backend.transfers.EmailToContact | undefined => {
    const emailsTo =
        requestDetails.emailToSupplier?.to
            .map((toEmail) => stringHelpers.parseEmail(toEmail))
            .filter((toEmail) => toEmail) || [];
    const emailTo = uniqWith(emailsTo, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',') || '';

    const client = requestDetails.contact as XeroContact | null;
    const contactPerson = client?.contactPersons?.find((p) => Boolean(p.firstName)) || null;

    let subject = emailToSupplierHelpers.removeRootParagraph(requestDetails.emailToSupplier?.subject || '');

    subject = emailToSupplierHelpers.cleanupSubjectString(subject);

    let emailSubject = emailToSupplierHelpers
        .removeMentionExtensionTags(subject)
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    emailSubject = emailToSupplierHelpers.returnExpressions(emailSubject);

    const emailBody = emailToSupplierHelpers
        .removeMentionExtensionTags(requestDetails.emailToSupplier?.body || '')
        .replaceAll('[CONTACTPERSONNAME]', contactPerson?.firstName || '');

    const emailsCC =
        requestDetails.emailToSupplier?.cc
            .map((ccEmail) => stringHelpers.parseEmail(ccEmail))
            .filter((ccEmail) => ccEmail && !emailTo.toLocaleLowerCase().includes(ccEmail.toLocaleLowerCase())) || [];

    const emailCC = uniqWith(emailsCC, (a, b) => a?.toLocaleLowerCase() === b?.toLocaleLowerCase()).join(',');

    return requestDetails.emailToSupplier
        ? {
              emailHasToBeSent: requestDetails.sendToSupplier,
              emailTo,
              emailCC,
              emailFrom: requestDetails.emailToSupplier.from || '',
              emailReplyTo: requestDetails.emailToSupplier.replyTo || '',
              emailSubject,
              emailBody,
              attachments: requestDetails.emailToSupplier.attachments.map(({ id }) => id) || [],
          }
        : undefined;
};

const cleanUpRequestNote = (note: string) => stringHelpers.cleanHTMLAttributes(REQUEST_NOTE_EXCLUDED_ATTRIBUTES, note);

export function getXeroRequestTransfer(
    state: State,
    request: domain.Request
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    const result: backend.Transfer<backend.transfers.RequestEditTransfer> = {
        attachments: request.attachments.map((a) => a.id),
        companyId: request.companyId,
        currency: request.currency,
        exchangeRate: request.exchangeRate,
        exchangeRateDate: request.exchangeRateDate,
        exchangeRateSource: request.exchangeRateSource,
        requestId: request.id,
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
    };

    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroPo:
            result.purchaseOrder = getXeroPurchaseOrderDetailsTransfer(state, request);
            result.amount = getXeroLineItemsSummary(state, request).totalAmount;
            result.emailToContact = getXeroEmailToContactParams(request.details);
            break;

        case domain.IntegrationCode.XeroQuote:
            result.xeroQuoteDetails = getXeroQuoteDetailsTransfer(state, request);
            result.amount = getXeroLineItemsSummary(state, request).totalAmount;
            result.emailToContact = getXeroEmailToContactParams(request.details);
            break;

        case domain.IntegrationCode.XeroBill:
            result.xeroInvoiceDetails = getXeroInvoiceDetailsTransfer(state, request);
            result.amount = getXeroLineItemsSummary(state, request).totalAmount;
            result.ocrAttachment = request.ocrAttachment;

            break;

        case domain.IntegrationCode.XeroInvoice:
            result.xeroSalesInvoiceDetails = getXeroInvoiceDetailsTransfer(state, request);
            result.amount = getXeroLineItemsSummary(state, request).totalAmount;
            result.emailToContact = getXeroInvoiceEmailToContactParams(request.details);
            break;

        case domain.IntegrationCode.XeroContact:
            result.xeroContactDetails = getXeroContactDetailsTransfer(state, request);
            break;

        case domain.IntegrationCode.XeroBillBatchPayment: {
            const xeroBillBatchPaymentDetails = request.details;

            result.amount = getXeroBillBatchPaymentTotalAmount(xeroBillBatchPaymentDetails.items);
            result.xeroBillBatchPaymentDetails = getXeroBillBatchPaymentDetailsTransfer(xeroBillBatchPaymentDetails);
            break;
        }

        case domain.IntegrationCode.XeroAirwallexBatchPayment: {
            const xeroBillBatchPaymentDetails = request.details;

            result.airwallexXeroBatchPaymentDetails =
                getXeroAirwallexBatchPaymentDetailsTransfer(xeroBillBatchPaymentDetails);
            break;
        }

        case domain.IntegrationCode.XeroCreditNotesPayable:
        case domain.IntegrationCode.XeroCreditNotesReceivable: {
            const key =
                request.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable
                    ? 'xeroPayableCreditNoteDetails'
                    : 'xeroReceivableCreditNoteDetails';

            result[key] = xeroCreditNoteDetailsTransfer(state, request);

            break;
        }

        case domain.IntegrationCode.XeroAmaxPayBatchPayment: {
            const xeroBillBatchPaymentDetails = request.details;

            result.amaxPayXeroBatchPaymentDetails =
                getXeroAmaxPayBatchPaymentDetailsTransfer(xeroBillBatchPaymentDetails);
            break;
        }
    }

    return result;
}

export function getXeroContactRequestTransfer(
    state: State,
    request: domain.Request,
    commentText: string
): backend.transfers.RequestEditTransfer {
    const result: backend.Transfer<backend.transfers.RequestEditTransfer> = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
        commentText,
        companyId: request.companyId,
    };

    switch (request.integrationCode) {
        case domain.IntegrationCode.XeroPo:
            result.purchaseOrder = getXeroPurchaseOrderDetailsTransfer(state, request);
            break;

        case domain.IntegrationCode.XeroBill:
            result.xeroInvoiceDetails = getXeroInvoiceDetailsTransfer(state, request);
            break;

        case domain.IntegrationCode.XeroContact:
            result.xeroContactDetails = getXeroContactDetailsTransfer(state, request);
            break;
    }

    return result;
}

function getXeroBillBatchPaymentDetailsTransfer(
    details: domain.XeroBillBatchPaymentDetails,
    countryCode?: backend.OrganisationVersion
): backend.transfers.IntegrationXeroBillBatchPaymentTransfer {
    const isNZ = countryCode === backend.OrganisationVersion.NZ;

    return {
        xeroBillBatchPaymentId: details.id,
        narrative: details.narrative,
        paymentDate: details.paymentDate!,
        details: details.details,
        bankAccountId: details.bankAccount?.id || '',
        reference: details.reference,
        particulars: details.particulars,
        code: details.code,
        items: details.items.map((i) => ({
            xeroBillInvoiceRequestId: i.xeroBillInvoiceRequestId,
            bankAccountNumber: i.bankAccount || null,
            amount: i.amount,
            details: (!isNZ && i.details) || null,
            code: i.code || null,
            reference: i.reference || null,
            particulars: i.particulars || null,
        })),
    };
}

const xeroCreditNoteDetailsTransfer = (
    state: State,
    request: domain.XeroCreditNotesPayableRequest | domain.XeroCreditNotesReceivableRequest
): backend.Transfer<backend.transfers.XeroCreditNotesTransfer> => {
    const { details } = request;

    return {
        contactId: details.contact?.id || null,
        reference: details.reference || null,
        brandingThemeId: details.brandingTheme?.id || null,
        date: details.date || null,
        lineAmountType: details.lineAmountType,
        lineItems: getXeroLineItems(state, request)
            .filter((li) => !li.empty)
            .map((li) => getXeroLineItemTransfer(state, li, request)),
        number: details.number || null,
    };
};

const getXeroAmaxPayBatchPaymentDetailsTransfer = (
    details: domain.XeroAmaxPayBatchPaymentDetails
): backend.transfers.IntegrationXeroAmaxPayBatchPaymentTransfer => {
    return {
        items: details.items.map((item) => ({
            xeroBillInvoiceRequestId: item.xeroBillInvoiceRequestId || null,
            amount: item.amount,
            paymentReference: details.isMergedBySupplier
                ? (details.referencesForMergedItems &&
                      item.contact &&
                      item.paymentDetails &&
                      details.referencesForMergedItems[`${item.contact.id},${item.paymentDetails.id}`]) ||
                  null
                : item.paymentReference || null,
            paymentDetailsVersionId: item.paymentDetails?.versionId || null,
            amaxPayBankAccountId: item.amaxPayBankAccount?.id || null,
        })),
        isMergedBySupplier: details.isMergedBySupplier,
    };
};

function getXeroAirwallexBatchPaymentDetailsTransfer(
    details: domain.XeroAirwallexBatchPaymentDetails
): backend.transfers.IntegrationXeroAirwallexBatchPaymentTransfer {
    return {
        items: details.items.map((i) => ({
            xeroBillInvoiceRequestId: i.xeroBillInvoiceRequestId || null,
            amount: i.amount,
            bankAccountId: i.bankAccount?.id || null,
            beneficiaryId: i.beneficiary?.id || null,
            paymentReference: i.paymentReference || null,
            paymentPurpose: i.paymentPurpose?.id || null,
            paymentMethod: i.beneficiary?.paymentMethod?.id ? startCase(i.beneficiary.paymentMethod.id) : null,
            sourceCurrency: i.sourceCurrency?.id || null,
            paymentFee: i.paymentFee?.id || null,
            beneficiaryVersion: i.beneficiary?.version ?? null,
        })),
    };
}

export function getXeroBillBatchPaymentTotalAmount(items: domain.PaymentItem[]) {
    return items.reduce((accumAmount, item) => accumAmount + item.amount, 0);
}

export function getXeroBillBatchPaymentRequestTransfer(
    state: State,
    request: domain.XeroBillBatchPaymentRequest,
    commentText: string
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    const xeroBillBatchPaymentDetails = request.details;
    const countryCode = selectors.integration.getIntegrationByCompanyId(state, request.companyId)?.countryCode;
    const xeroBillBatchPaymentDetailsTransfer = getXeroBillBatchPaymentDetailsTransfer(
        xeroBillBatchPaymentDetails,
        countryCode
    );
    const result: backend.Transfer<backend.transfers.RequestEditTransfer & { templateVersion: number }> = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
        xeroBillBatchPaymentDetails: xeroBillBatchPaymentDetailsTransfer,
        amount: getXeroBillBatchPaymentTotalAmount(xeroBillBatchPaymentDetails.items),
        commentText,
        companyId: request.companyId,
    };

    return result;
}

export const getXeroCreditNotesRequestTransfer = (
    state: State,
    request: domain.XeroCreditNotesPayableRequest | domain.XeroCreditNotesReceivableRequest,
    commentText: string
) => {
    const result: backend.Transfer<backend.transfers.RequestEditTransfer & { templateVersion: number }> = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
        commentText,
        companyId: request.companyId,
    };

    const creditNoteKey =
        request.integrationCode === domain.IntegrationCode.XeroCreditNotesPayable
            ? 'xeroPayableCreditNoteDetails'
            : 'xeroReceivableCreditNoteDetails';

    result[creditNoteKey] = xeroCreditNoteDetailsTransfer(state, request);

    return result;
};

export const getXeroAmaxPayBatchPaymentRequestTransfer = (
    state: State,
    request: domain.XeroAmaxPayBatchPaymentRequest,
    commentText?: string
): backend.Transfer<backend.transfers.RequestEditTransfer> => {
    const xeroAmaxPayBatchPaymentDetails = request.details;
    const xeroAmaxPayBatchPaymentDetailsTransfer =
        getXeroAmaxPayBatchPaymentDetailsTransfer(xeroAmaxPayBatchPaymentDetails);
    const result: backend.Transfer<backend.transfers.RequestEditTransfer & { templateVersion: number }> = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
        amaxPayXeroBatchPaymentDetails: xeroAmaxPayBatchPaymentDetailsTransfer,
        commentText,
        companyId: request.companyId,
    };

    return result;
};

export function getXeroAirwallexBatchPaymentRequestTransfer(
    state: State,
    request: domain.XeroAirwallexBatchPaymentRequest,
    commentText?: string
): backend.Transfer<backend.transfers.RequestEditTransfer> {
    const xeroAirwallexBatchPaymentDetails = request.details;
    const xeroAirwallexBatchPaymentDetailsTransfer = getXeroAirwallexBatchPaymentDetailsTransfer(
        xeroAirwallexBatchPaymentDetails
    );
    const result: backend.Transfer<backend.transfers.RequestEditTransfer & { templateVersion: number }> = {
        requestId: request.id,
        currency: request.currency,
        attachments: request.attachments.map((a) => a.id),
        requestNote: cleanUpRequestNote(request.requestNote.value),
        templateVersion: getContext(state).templateVersion,
        airwallexXeroBatchPaymentDetails: xeroAirwallexBatchPaymentDetailsTransfer,
        commentText,
        companyId: request.companyId,
    };

    return result;
}

function getEditableXeroContactPhoneTransfer(
    phone: EditableXeroContactPhone
): backend.Transfer<backend.XeroDomainPhone> {
    return {
        CountryCode: phone.countryCode || null,
        AreaCode: phone.areaCode || null,
        Number: phone.number || null,
    };
}

function getEditableXeroContactAddressTransfer(
    address: EditableXeroContactAddress
): backend.Transfer<backend.XeroDomainAddressInfo> {
    return {
        Address: address.address || null,
        AttentionTo: address.attentionTo || null,
        City: address.city || null,
        Country: address.country || null,
        PostalCode: address.postalCode || null,
        Region: address.region || null,
    };
}

function getTrackingCategories(
    categories: EditableXeroTrackingCategory[]
): Array<backend.Transfer<backend.XeroDomainTrackingCategory>> {
    let result: Array<backend.Transfer<backend.XeroDomainTrackingCategory>> = [];

    if (categories) {
        categories
            .filter((category) => category)
            .forEach((category) => {
                result.push({
                    Name: category.name,
                    Option: category.text,
                });
            });
    }

    return result;
}

function getTrackingCategoriesTransfer(
    categories: domain.TrackingCategory[]
): Array<backend.Transfer<backend.XeroDomainTrackingCategory>> {
    return categories.map((categoryData) => ({
        Name: categoryData.category.text,
        Option: categoryData.value!.text,
    }));
}

export function getEditableXeroContactTransfer(
    state: State,
    contact: EditableXeroContact
): backend.transfers.IntegrationXeroCreateCounterpartyTransfer {
    return {
        companyId: getRequest(state).companyId,
        templateId: getRequest(state).template.id,
        name: contact.name,
        accountNumber: contact.accountNumber || null,
        firstName: contact.firstName || null,
        lastName: contact.lastName || null,
        emailAddress: contact.email || null,
        skypeName: contact.skypeName || null,
        website: contact.website || null,
        phone: getEditableXeroContactPhoneTransfer(contact.phone),
        fax: getEditableXeroContactPhoneTransfer(contact.fax),
        mobile: getEditableXeroContactPhoneTransfer(contact.mobile),
        directDial: getEditableXeroContactPhoneTransfer(contact.directDial),
        postalAddres: getEditableXeroContactAddressTransfer(contact.postalAddress),
        mailingAddres: getEditableXeroContactAddressTransfer(contact.mailingAddress),
        defaultCurrency: contact.defaultCurrency ? contact.defaultCurrency.id : null,
        bankAccountDetails: contact.bankAccountDetails,
        taxNumber: contact.taxNumber,
        companyNumber: contact.companyNumber,
        salesTaxCode: contact.salesTax?.id || null,
        purchaseTaxCode: contact.purchaseTax?.id || null,
        salesAccountCode: contact.salesAccount?.accountCode || null,
        purchaseAccountCode: contact.purchasesAccount?.accountCode || null,
        salesTrackingCategories: getTrackingCategories(contact.salesTrackingCategories),
        purchasesTrackingCategories: getTrackingCategories(contact.purchasesTrackingCategories),
    };
}
