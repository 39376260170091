import { ErrorCode, Guid } from '@approvalmax/types';
import { Text, toast } from '@approvalmax/ui/src/components';
import { dateTimeHelpers, errorHelpers, intl, miscHelpers } from '@approvalmax/utils';
import { actions, selectors } from 'modules/common';
import { MatchingRequester } from 'modules/common/selectors/types';
import { backend, domain, du, Entities, schemas, State, stateTree } from 'modules/data';
import { integrationActions } from 'modules/integration';
import { createAction, createAsyncAction, createErrorAction, ExtractActions } from 'modules/react-redux';
import * as search from 'modules/search';
import { normalize } from 'normalizr';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { amplitudeService } from 'services/amplitude';
import { api } from 'services/api';
import { notificationService } from 'services/notification';
import { routingService } from 'services/routing';
import { UseAmaxPayXeroRefreshResponse, UseGetSearchRequestsParams } from 'shared/data';
import { getPath, Path } from 'urlBuilder';

import {
    CompanyRequestListFilter,
    RequestListFilter,
    SEARCH_REQUESTS_LIST_PAGE_ID,
    TemplateRequestListFilter,
} from '../config';
import { SearchParameters } from '../data/SearchParameters';
import { OperationResult } from '../reducers/page/activePopup/operationResultPopupReducer';
import {
    getActiveFilter,
    getActiveRequest,
    getActiveRequestId,
    getActiveTemplate,
    getActiveTemplateId,
    getPagination,
    getRequests,
    getSearchParams,
    isInSearchMode,
} from '../selectors/pageSelectors';
import { matchesListFilter } from '../selectors/requestSelectors';

export const REQUEST_LIST_PAGE_SIZE = 40;

const i18nPrefix = 'requestList/actions/index';
const messages = defineMessages({
    startOverSuccessText: {
        id: `${i18nPrefix}.startOverSuccessText`,
        defaultMessage: 'Approval workflow for "{requestName}" has been started over',
    },
    editStepParticipantsSuccessText: {
        id: `${i18nPrefix}.editStepParticipantsSuccessText`,
        defaultMessage: 'Approvers changed for "{stepName}" step',
    },
    editStepReviewersSuccessText: {
        id: `${i18nPrefix}.editStepReviewersSuccessText`,
        defaultMessage: 'Reviewers changed for review step',
    },
    reassignRequestSuccessText: {
        id: `${i18nPrefix}.reassignRequestSuccessText`,
        defaultMessage: 'Request reassigned to {displayName}',
    },
    auditReportNotFoundNotificationText: {
        id: `${i18nPrefix}.auditReportNotFoundNotificationText`,
        defaultMessage: 'Audit report not found',
    },
    attachmentNotFoundNotificationText: {
        id: `${i18nPrefix}.attachmentNotFoundNotificationText`,
        defaultMessage: 'Document not found',
    },
    cancelRequestSuccessText: {
        id: `${i18nPrefix}.cancelRequestSuccessText`,
        defaultMessage: 'Request cancelled',
    },
    makeForceDecisionApprovedSuccess: {
        id: `${i18nPrefix}.makeForceDecisionApprovedSuccess`,
        defaultMessage: 'Request approved',
    },
    makeForceDecisionDeclinedSuccess: {
        id: `${i18nPrefix}.makeForceDecisionDeclinedSuccess`,
        defaultMessage: 'Request rejected',
    },
    makeDecisionApprovedSuccess: {
        id: `${i18nPrefix}.makeDecisionApprovedSuccess`,
        defaultMessage: 'Request approved',
    },
    completeReviewSuccess: {
        id: `${i18nPrefix}.completeReviewSuccess`,
        defaultMessage: 'The review has been completed, the request has been submitted for approval.',
    },
    sendToReviewSuccess: {
        id: `${i18nPrefix}.sendToReviewSuccess`,
        defaultMessage: 'The request has been returned to review.',
    },
    makeDecisionDeclinedSuccess: {
        id: `${i18nPrefix}.makeDecisionDeclinedSuccess`,
        defaultMessage: 'Request rejected',
    },
    revokeRequestSuccess: {
        id: `${i18nPrefix}.revokeRequestSuccess`,
        defaultMessage: 'Vote revoked',
    },
    submitRequestSuccess: {
        id: `${i18nPrefix}.submitRequestSuccess`,
        defaultMessage: 'Request submitted for approval',
    },
    deleteRequestSuccess: {
        id: `${i18nPrefix}.deleteRequestSuccess`,
        defaultMessage: 'Request deleted',
    },
});

const trackingCategorySortFn = (a: { category: { text: string } }, b: { category: { text: string } }) => {
    return a.category?.text > b.category?.text ? 1 : -1;
};

const isFolderChangedAfterCancelOrForceDecision = (selectedFilter: string | null) =>
    selectedFilter === RequestListFilter.MyDecisionRequired ||
    selectedFilter === RequestListFilter.MyReviewRequired ||
    selectedFilter === RequestListFilter.MyOnlyOpen ||
    selectedFilter === CompanyRequestListFilter.CompanyMyDecisionRequired ||
    selectedFilter === CompanyRequestListFilter.CompanyMyReviewRequired ||
    selectedFilter === TemplateRequestListFilter.OnApproval ||
    selectedFilter === TemplateRequestListFilter.OnReview ||
    selectedFilter === TemplateRequestListFilter.Rejected;

const isFolderChangedAfterDecision = (
    state: State,
    request: domain.Request,
    selectedFilter: string | null,
    decision?: domain.RequestStepParticipantDecision
) => {
    if (
        selectedFilter === RequestListFilter.MyOnlyOpen ||
        selectedFilter === RequestListFilter.MyReviewRequired ||
        selectedFilter === CompanyRequestListFilter.CompanyMyReviewRequired ||
        selectedFilter === TemplateRequestListFilter.OnReview
    ) {
        return true;
    }

    const currentUser = selectors.profile.getProfileUser(state);

    if (selectedFilter === TemplateRequestListFilter.OnApproval) {
        const lastStep = request.steps[request.steps.length - 1];
        const isFinalDecision =
            lastStep.state === domain.RequestStepState.Active &&
            lastStep.participants.every(
                (participant) =>
                    participant.userId === currentUser.userEmail ||
                    participant.decision !== domain.RequestStepParticipantDecision.NoResponse
            );

        return decision === domain.RequestStepParticipantDecision.Reject || isFinalDecision;
    }

    if (
        selectedFilter === RequestListFilter.MyDecisionRequired ||
        selectedFilter === CompanyRequestListFilter.CompanyMyDecisionRequired
    ) {
        const activeStep = request.steps.find((step) => step.state === domain.RequestStepState.Active);
        const firstNotStartedStep = request.steps.find((step) => step.state === domain.RequestStepState.NotStarted);

        if (!firstNotStartedStep) {
            return true;
        }

        // If the request is in Decision Required folder and there are participants who didn't make a decision,
        // or we don't participate in the next step, the folder changes after our decision
        const isParticipantsDecisionRequired =
            activeStep?.participants.some(
                (participant) =>
                    participant.userId !== currentUser.userEmail &&
                    participant.decision === domain.RequestStepParticipantDecision.NoResponse
            ) || firstNotStartedStep?.participants.every((participant) => participant.userId !== currentUser.userEmail);

        return decision === domain.RequestStepParticipantDecision.Reject || isParticipantsDecisionRequired;
    }

    return false;
};

const sortTrackingCategoriesInsideEntities = (entities: any) => {
    if (entities.requests && Object.keys(entities.requests).length) {
        Object.keys(entities.requests).forEach((requestId: string) => {
            const request = entities.requests[requestId];

            entities.requests[requestId] = {
                ...request,
                details: request.details
                    ? {
                          ...request.details,
                          lineItems: request.details.lineItems
                              ? request.details.lineItems.map((lineItem: domain.XeroLineItem) => ({
                                    ...lineItem,
                                    tracking: Array.isArray(lineItem.tracking)
                                        ? lineItem.tracking.sort(trackingCategorySortFn)
                                        : undefined,
                                }))
                              : undefined,
                      }
                    : undefined,
            };
        });
    }
};

enum LoadRequestsMethod {
    selectRequests = 'selectRequests',
    selectAllCompanyRequests = 'selectAllCompanyRequests',
    selectByTemplate = 'selectByTemplate',
}

interface LoadRequestsParams {
    entities: Entities;
    requests: string[];
    hasMore: boolean;
    continuationToken?: string;
}

export async function loadRequests(options: {
    filter: RequestListFilter | CompanyRequestListFilter | TemplateRequestListFilter;
    templateId: string | null;
    companyId: string | null;
    pageIndex?: number;
    continuationToken?: string;
}): Promise<LoadRequestsParams> {
    const requestsCount = REQUEST_LIST_PAGE_SIZE;

    let startFrom = (options.pageIndex || 0) * REQUEST_LIST_PAGE_SIZE;
    let serverFilter: number | undefined;
    let selectByWorkflowFilter: backend.transfers.SelectByWorkflowFilter;
    let methodName: LoadRequestsMethod;
    let apiV2Filter: backend.transfers.RequestSelectV2Filter | undefined;

    switch (options.filter) {
        case RequestListFilter.MyDecisionRequired:
            serverFilter = 0;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userDecisionRequired';
            break;

        case RequestListFilter.MyOcrRequests:
            serverFilter = 10;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userOcrRequests';
            break;

        case RequestListFilter.MyReviewRequired:
            serverFilter = 9;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userReviewRequired';
            break;

        case RequestListFilter.MyDraft:
            serverFilter = 8;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userDraftRequests';
            break;

        case RequestListFilter.MyOnlyOpen:
            serverFilter = 7;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userSubmittedOpenRequests';
            break;

        case RequestListFilter.MyReadyToPay:
            serverFilter = 11;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userReadyToPayRequests';
            break;

        case CompanyRequestListFilter.CompanyAll:
            serverFilter = 6;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'requestsUserCanSee';
            break;

        case CompanyRequestListFilter.CompanyAllOnHold:
            serverFilter = 6;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userOnHoldRequests';
            break;

        case CompanyRequestListFilter.CompanyMyDecisionRequired:
            serverFilter = 0;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userDecisionRequired';
            break;

        case CompanyRequestListFilter.CompanyMyReviewRequired:
            serverFilter = 0;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userReviewRequired';
            break;

        case CompanyRequestListFilter.CompanyMyOpen:
            serverFilter = 1;
            methodName = LoadRequestsMethod.selectRequests;
            apiV2Filter = 'userSubmittedOpenRequests';
            break;

        case CompanyRequestListFilter.CompanyAllOpen:
            serverFilter = 0;
            methodName = LoadRequestsMethod.selectAllCompanyRequests;
            break;

        case TemplateRequestListFilter.AllByType:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.All;
            break;

        case TemplateRequestListFilter.OnReview:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.OnReview;
            break;

        case TemplateRequestListFilter.OnApproval:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.OnApproval;
            break;

        case TemplateRequestListFilter.Approved:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Approved;
            break;

        case TemplateRequestListFilter.Rejected:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Rejected;
            break;

        case TemplateRequestListFilter.OnHold:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.OnHold;
            break;

        case TemplateRequestListFilter.Cancelled:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Cancelled;
            break;

        case TemplateRequestListFilter.Paid:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Paid;
            break;

        case TemplateRequestListFilter.Credited:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Credited;
            break;

        case TemplateRequestListFilter.Billed:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Billed;
            break;

        case TemplateRequestListFilter.NotBilled:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.NotBilled;
            break;

        case TemplateRequestListFilter.AwaitingPayment:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.AwaitingPayment;
            break;

        case TemplateRequestListFilter.PartiallyPaid:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.PartiallyPaid;
            break;

        case TemplateRequestListFilter.Failed:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Failed;
            break;

        case TemplateRequestListFilter.Processing:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Processing;
            break;

        case TemplateRequestListFilter.GrnNotReceived:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.GrnNotReceived;
            break;

        case TemplateRequestListFilter.GrnPartiallyReceived:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.PartiallyReceived;
            break;

        case TemplateRequestListFilter.GrnFullyReceived:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.GrnFullyReceived;
            break;

        case TemplateRequestListFilter.Accepted:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Accepted;
            break;

        case TemplateRequestListFilter.Declined:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Declined;
            break;

        case TemplateRequestListFilter.PartiallyReceived:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.PartiallyReceived;
            break;

        case TemplateRequestListFilter.PendingBill:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.PendingBill;
            break;

        case TemplateRequestListFilter.PendingReceipt:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.PendingReceipt;
            break;

        case TemplateRequestListFilter.Closed:
            methodName = LoadRequestsMethod.selectByTemplate;
            selectByWorkflowFilter = backend.transfers.SelectByWorkflowFilter.Closed;
            break;

        default:
            throw errorHelpers.assertNever(options.filter);
    }

    let response;

    switch (methodName) {
        case LoadRequestsMethod.selectRequests:
            if (miscHelpers.isEnumValue(CompanyRequestListFilter, options.filter)) {
                response = await api.requests.companySelectV2({
                    pageSize: requestsCount,
                    continuationToken: options.continuationToken,
                    filter: apiV2Filter,
                    companyId: options.companyId!,
                });
            } else {
                response = await api.requests.selectV2({
                    pageSize: requestsCount,
                    continuationToken: options.continuationToken,
                    filter: apiV2Filter,
                });
            }

            break;

        case LoadRequestsMethod.selectAllCompanyRequests:
            response = await api.requests.selectAll({
                filter: serverFilter,
                companyId: options.companyId,
                startFrom,
                rowNum: requestsCount,
            });
            break;

        case LoadRequestsMethod.selectByTemplate:
            response = await api.requests.selectByWorkflow({
                filter: selectByWorkflowFilter!,
                workflowId: options.templateId,
                companyId: options.companyId,
                startFrom,
                rowNum: requestsCount,
            });
            break;

        default:
            throw errorHelpers.assertNever(methodName);
    }

    const entities = normalize(response.Requests, [schemas.requestSchema]).entities as any;

    sortTrackingCategoriesInsideEntities(entities);

    return {
        entities,
        requests: response.Requests.map((r) => r.RequestId),
        hasMore: 'HasMore' in response ? response.HasMore : Boolean(response.ContinuationToken),
        continuationToken: 'ContinuationToken' in response ? response.ContinuationToken : undefined,
    };
}

export const mapSearchRequestsParams = ({
    searchParams,
    pageIndex,
}: {
    pageIndex: number;
    searchParams: SearchParameters;
}): UseGetSearchRequestsParams => {
    return {
        startFrom: pageIndex * REQUEST_LIST_PAGE_SIZE,
        rowNum: REQUEST_LIST_PAGE_SIZE,
        companyId: searchParams.company!.id,
        templateId: searchParams.template!.id,
        integrationCode: searchParams.template!.integrationCode,
        number:
            searchParams.xeroDocumentNumber ||
            searchParams.qbooksPoNumber ||
            searchParams.netSuiteTransactionNumber ||
            searchParams.netSuitePOReference ||
            searchParams.dearOrderNumber ||
            undefined,

        requestorId: searchParams.requestor ? searchParams.requestor.databaseId : undefined,
        requestStatusV2: searchParams.requestStatusV2,
        amountGreaterEquals: searchParams.amountGreaterEquals,
        amountLessEquals: searchParams.amountLessEquals,
        documentDateGreaterEquals: searchParams.documentDateGreaterEquals,
        documentDateLessEquals: searchParams.documentDateLessEquals,
        standaloneRequestName: searchParams.standaloneRequestName || undefined,
        standaloneRequestDescription: searchParams.standaloneRequestDescription || undefined,
        reference:
            searchParams.xeroDocumentReference ||
            searchParams.netSuiteReference ||
            searchParams.netSuitePOVendorReference ||
            undefined,
        xeroAccountId: du.getReferenceId(searchParams.xeroAccount),
        xeroContactId: du.getReferenceId(searchParams.xeroContact),
        xeroItemId: du.getReferenceId(searchParams.xeroItem),
        xeroTrackingOptions: searchParams.xeroTrackingCategories.map((c) => ({
            trackingOptionId: c.value.id,
            trackingCategoryId: c.category.id,
        })),
        airwallexScheduledPaymentDate: {
            greaterOrEqual: searchParams.airwallexScheduledPaymentDateGreaterEquals || undefined,
            lessOrEqual: searchParams.airwallexScheduledPaymentDateLessEquals
                ? dateTimeHelpers.getEndOfDateTimestampUTC(searchParams.airwallexScheduledPaymentDateLessEquals)
                : undefined,
        },
        xeroNarration: searchParams.xeroNarration,
        currencyISOCode: du.getReferenceId(searchParams.qbooksCurrency),
        qBooksClassId: du.getReferenceId(searchParams.qbooksClass),
        qBooksCustomerId: du.getReferenceId(searchParams.qbooksCustomer),
        qBooksDepartamentId: du.getReferenceId(searchParams.qbooksDepartment),
        qBooksProductId: du.getReferenceId(searchParams.qbooksProduct),
        qBooksVendorId: du.getReferenceId(searchParams.qbooksVendor),
        qBooksAccountId: du.getReferenceId(searchParams.qbooksAccount),
        qBooksPayeeVendorId: du.getReferenceId(searchParams.qbooksPayeeVendor),
        qBooksPayeeCustomerId: du.getReferenceId(searchParams.qbooksPayeeCustomer),
        qBooksPayeeEmployeeId: du.getReferenceId(searchParams.qbooksPayeeEmployee),
        qBooksPaymentAccountId: du.getReferenceId(searchParams.qbooksPaymentAccount),
        qBooksDocumentStatus: du.getReferenceId(searchParams.qbooksDocumentStatus),
        netSuiteVendor: du.getReferenceId(searchParams.netSuiteVendor),
        netSuiteDueDate: {
            greaterOrEqual: searchParams.netSuiteDueDateGreaterEquals || undefined,
            lessOrEqual: searchParams.netSuiteDueDateLessEquals || undefined,
        },
        netSuiteDate: {
            greaterOrEqual: searchParams.netSuiteDateGreaterEquals || undefined,
            lessOrEqual: searchParams.netSuiteDateLessEquals || undefined,
        },
        netSuiteCategory: du.getReferenceId(searchParams.netSuiteCategory),
        netSuiteAccount: du.getReferenceId(searchParams.netSuiteAccount),
        netSuiteItem: du.getReferenceId(searchParams.netSuiteItem),
        netSuiteDepartment: du.getReferenceId(searchParams.netSuiteDepartment),
        netSuiteClass: du.getReferenceId(searchParams.netSuiteClass),
        netSuiteLocation: du.getReferenceId(searchParams.netSuiteLocation),
        netSuiteEmployee: du.getReferenceId(searchParams.netSuiteEmployee),
        netSuiteCustomer: du.getReferenceId(searchParams.netSuiteCustomer),
        netSuiteTaxCode: du.getReferenceId(searchParams.netSuiteTaxCode),
        netSuiteCustomFields: searchParams.netSuiteCustomFields || undefined,
        dearSupplier: du.getReferenceId(searchParams.dearSuplier),
        dearProduct: du.getReferenceId(searchParams.dearProduct),
        dearInventoryAccount: du.getReferenceId(searchParams.dearInventoryAccount),
        dearLocation: searchParams.dearLocation?.text || undefined,
        dearOrderDate: {
            greaterOrEqual: searchParams.dearOrderDateGreaterEquals || undefined,
            lessOrEqual: searchParams.dearOrderDateLessEquals || undefined,
        },
        dearRequiredBy: {
            greaterOrEqual: searchParams.dearRequiredByGreaterEquals || undefined,
            lessOrEqual: searchParams.dearRequiredByLessEquals || undefined,
        },
    };
};

const loadSearchResults = async ({
    searchParams,
    pageIndex,
}: {
    pageIndex: number;
    searchParams: SearchParameters;
}): Promise<LoadRequestsParams> => {
    const response = await api.search.searchRequests(mapSearchRequestsParams({ searchParams, pageIndex }));

    const entities = normalize(response.Requests, [schemas.requestSchema]).entities as any as Entities;

    return {
        entities,
        requests: response.Requests.map((r) => r.RequestId),
        hasMore: response.HasMore,
    };
};

export async function loadOneRequest(
    requestId: string,
    companyId?: string
): Promise<{
    entities: stateTree.Entities;
    request: domain.Request;
} | null> {
    let response;

    if (!requestId) {
        return null;
    }

    response = await api.requests.get({
        requestId,
        companyId,
    });

    const entities = normalize<backend.RequestSelectAnswer, stateTree.Entities>(
        response.Requests[0],
        schemas.requestSchema
    ).entities;
    const request = Object.values(entities.requests)[0];

    return {
        entities,
        request,
    };
}

export const LEAVE_PAGE = 'LEAVE_PAGE/LOAD_PAGE_DATA';
export const leavePage = () => createAction(LEAVE_PAGE, {});

export const LOAD_PAGE_DATA = 'REQUESTLIST/LOAD_PAGE_DATA';
export const loadPageData = (options: {
    filter: CompanyRequestListFilter | TemplateRequestListFilter | RequestListFilter | null;
    templateId: Guid | null;
    companyId: Guid | null;
    requestId: Guid | null;
    requests: Guid[];
    hasMore: boolean;
    continuationToken?: string;
    entities: Partial<stateTree.Entities>;
    searchParameters?: SearchParameters;
    innerPageId?: string;
    initLoad?: boolean;
}) =>
    createAction(LOAD_PAGE_DATA, {
        ...options,
    });

export const LOAD_SEARCH_RESULTS_DATA_RESPONSE = 'REQUESTLIST/LOAD_SEARCH_RESULTS_DATA_RESPONSE';
export const LOAD_SEARCH_RESULTS_DATA_FAILURE = 'REQUESTLIST/LOAD_SEARCH_RESULTS_DATA_FAILURE';
export const loadSearchResultsDataFailure = () => createAction(LOAD_SEARCH_RESULTS_DATA_FAILURE, {});
export const loadSearchResultsDataResponse = (params: LoadRequestsParams) =>
    createAction(LOAD_SEARCH_RESULTS_DATA_RESPONSE, params, params.entities);

export const SET_ACTIVE_REQUEST_ID = 'REQUESTLIST/SET_ACTIVE_REQUEST_ID';
export const setActiveRequestId = (requestId: string) => createAction(SET_ACTIVE_REQUEST_ID, { requestId });

export const LOAD_MORE_REQUESTS = 'REQUESTLIST/LOAD_MORE_REQUESTS';
export const LOAD_MORE_REQUESTS_RESPONSE = 'REQUESTLIST/LOAD_MORE_REQUESTS_RESPONSE';
export const LOAD_MORE_REQUESTS_FAILURE = 'REQUESTLIST/LOAD_MORE_REQUESTS_FAILURE';
export const loadMoreRequests = () =>
    createAsyncAction({
        shouldSendRequest: (state: State) => {
            const pagination = getPagination(state);

            return pagination.hasMore && !pagination.loadingMore && !pagination.reloading;
        },

        request: (state: State) => {
            return createAction(LOAD_MORE_REQUESTS, {
                inSearch: isInSearchMode(state),
                searchParams: getSearchParams(state),
                companyId: selectors.navigation.getActiveCompanyId(state),
                templateId: getActiveTemplateId(state),
                filter: getActiveFilter(state)!,
                pageIndex: getPagination(state).pageIndex + 1,
                continuationToken: getPagination(state).continuationToken,
            });
        },

        response: async (request) => {
            const response = request.inSearch
                ? await loadSearchResults({
                      pageIndex: request.pageIndex,
                      searchParams: request.searchParams!,
                  })
                : await loadRequests(request);

            return createAction(LOAD_MORE_REQUESTS_RESPONSE, {
                request,
                hasMore: response.hasMore || Boolean(response.continuationToken),
                requests: response.requests,
                entities: response.entities,
                continuationToken: response.continuationToken,
            });
        },

        failure: (error, request) =>
            createErrorAction(LOAD_MORE_REQUESTS_FAILURE, error, {
                request,
            }),
    });

export const LOAD_MORE_SEARCH_RESULTS = 'REQUESTLIST/LOAD_MORE_SEARCH_RESULTS';
export const LOAD_MORE_SEARCH_RESULTS_RESPONSE = 'REQUESTLIST/LOAD_MORE_SEARCH_RESULTS_RESPONSE';
export const LOAD_MORE_SEARCH_RESULTS_FAILURE = 'REQUESTLIST/LOAD_MORE_SEARCH_RESULTS_FAILURE';
export const loadMoreSearchResults = () =>
    createAsyncAction({
        shouldSendRequest: (state: State) => {
            const pagination = getPagination(state);

            return pagination.hasMore && !pagination.loadingMore && !pagination.reloading;
        },

        request: (state: State) => {
            return createAction(LOAD_MORE_SEARCH_RESULTS, {
                companyId: selectors.navigation.getActiveCompanyId(state),
                templateId: getActiveTemplateId(state),
                filter: getActiveFilter(state)!,
                pageIndex: getPagination(state).pageIndex + 1,
            });
        },

        response: async (request) => {
            const response = await loadRequests(request);

            return createAction(LOAD_MORE_SEARCH_RESULTS_RESPONSE, {
                request,
                hasMore: response.hasMore,
                requests: response.requests,
                entities: response.entities,
            });
        },

        failure: (error, request) =>
            createErrorAction(LOAD_MORE_SEARCH_RESULTS_FAILURE, error, {
                request,
            }),
    });

export const RELOAD_REQUEST_LIST = 'REQUESTLIST/RELOAD_REQUEST_LIST';
export const RELOAD_REQUEST_LIST_RESPONSE = 'REQUESTLIST/RELOAD_REQUEST_LIST_RESPONSE';
export const RELOAD_REQUEST_LIST_FAILURE = 'REQUESTLIST/RELOAD_REQUEST_LIST_FAILURE';
export const reloadRequestList = () =>
    createAsyncAction({
        shouldSendRequest: (state: State) => {
            const pagination = getPagination(state);

            return !pagination.reloading && !pagination.loadingMore;
        },

        request: (state: State) => {
            const pagination = getPagination(state);

            return createAction(RELOAD_REQUEST_LIST, {
                companyId: selectors.navigation.getActiveCompanyId(state),
                templateId: getActiveTemplateId(state),
                filter: getActiveFilter(state)!,
                pagination,
                activeRequestId: getActiveRequestId(state),
            });
        },

        response: async (request) => {
            let pageIndex = 0;

            const response = await loadRequests({
                filter: request.filter,
                companyId: request.companyId,
                templateId: request.templateId,
                pageIndex,
            });

            return createAction(RELOAD_REQUEST_LIST_RESPONSE, {
                request,
                pageIndex,
                hasMore: response.hasMore,
                requests: response.requests,
                entities: response.entities,
                companyId: request.companyId,
            });
        },

        failure: (error) => createErrorAction(RELOAD_REQUEST_LIST_FAILURE, error, {}),

        didDispatchResponse: (request, response, state) => {
            const newActiveRequestId = getActiveRequestId(state);

            if (request.activeRequestId !== newActiveRequestId && newActiveRequestId && request.companyId) {
                // Synchronize the URL with the active request if it was changed
                const newUrl = getPath(Path.request, newActiveRequestId, request.companyId);

                if (!request.activeRequestId) {
                    // the default url (/request) should be replaced
                    routingService.replace(newUrl);
                } else {
                    routingService.push(newUrl);
                }
            }
        },
    });

export const SHOW_OPERATION_RESULT_POPUP = 'REQUESTLIST/SHOW_OPERATION_RESULT_POPUP';
export const showOperationResultPopup = (requestId: Guid, stepId: Guid | null, result: OperationResult) =>
    createAction(SHOW_OPERATION_RESULT_POPUP, { result, requestId, stepId });

export const RELOAD_REQUEST = 'REQUESTLIST/RELOAD_REQUEST';
export const RELOAD_REQUEST_RESPONSE = 'REQUESTLIST/RELOAD_REQUEST_RESPONSE';
export const RELOAD_REQUEST_FAILURE = 'REQUESTLIST/RELOAD_REQUEST_FAILURE';
export const reloadRequest = (requestId: Guid, companyId: string) =>
    createAsyncAction({
        request: () =>
            createAction(RELOAD_REQUEST, {
                requestId,
            }),

        response: async (request, getState) => {
            const response = await loadOneRequest(requestId, companyId);
            const state = getState();

            if (!response) {
                return createAction(RELOAD_REQUEST_RESPONSE, {
                    requestId,
                    entities: {},
                    activeRequestList: getRequests(state).map((request) => request.id),
                    requestMatchesFilter: false,
                });
            }

            return createAction(RELOAD_REQUEST_RESPONSE, {
                requestId,
                entities: response.entities,
                activeRequestList: getRequests(state).map((request) => request.id),
                requestMatchesFilter: matchesListFilter(
                    state,
                    response.request,
                    getActiveFilter(state),
                    selectors.navigation.getActiveCompanyId(state)
                ),
            });
        },

        failure: (error, request, state) => {
            const requestList = getRequests(state).map((r) => r.id);

            return createErrorAction(RELOAD_REQUEST_FAILURE, error, { requestId, requestList });
        },
    });

export const RELOAD_REQUEST_SYNC = 'REQUESTLIST/RELOAD_REQUEST_SYNC';
export const RELOAD_REQUEST_SYNC_RESPONSE = 'REQUESTLIST/RELOAD_REQUEST_SYNC_RESPONSE';
export const RELOAD_REQUEST_SYNC_FAILURE = 'REQUESTLIST/RELOAD_REQUEST_SYNC_FAILURE';
export const reloadRequestSync = (requestId: string, requests: backend.RequestAnswer[]) =>
    createAsyncAction({
        request: () =>
            createAction(RELOAD_REQUEST_SYNC, {
                requestId,
            }),

        response: async (req, getState) => {
            const entities = normalize(requests[0], schemas.requestSchema).entities;
            const request = Object.values(entities.requests || {})[0];
            const state = getState();

            if (!entities || !request) {
                return createAction(RELOAD_REQUEST_SYNC_RESPONSE, {
                    requestId: request.id,
                    entities: {},
                    activeRequestList: getRequests(state).map((r) => r.id),
                    requestMatchesFilter: false,
                });
            }

            return createAction(RELOAD_REQUEST_SYNC_RESPONSE, {
                requestId: request.id,
                entities,
                activeRequestList: getRequests(state).map((r) => r.id),
                requestMatchesFilter: matchesListFilter(
                    state,
                    request,
                    getActiveFilter(state),
                    selectors.navigation.getActiveCompanyId(state)
                ),
            });
        },

        failure: (error) => createErrorAction(RELOAD_REQUEST_SYNC_FAILURE, error, { requestId }),
    });

export const COMPLETE_REVIEW = 'REQUESTLIST/COMPLETE_REVIEW';
export const COMPLETE_REVIEW_RESPONSE = 'REQUESTLIST/COMPLETE_REVIEW_RESPONSE';
export const COMPLETE_REVIEW_FAILURE = 'REQUESTLIST/COMPLETE_REVIEW_FAILURE';
export const completeReview = (target: domain.Request, selectedFilter: string | null) =>
    createAsyncAction({
        request: (state: State) => {
            const reviewStep = target.reviewStep!;

            miscHelpers.invariant(reviewStep, 'The request must have a review step');

            const searchParams = getSearchParams(state);
            const activeFilter = getActiveFilter(state);
            const requestList = getRequests(state).map((r) => r.id);
            const removeFromList = Boolean(
                activeFilter === RequestListFilter.MyReviewRequired ||
                    activeFilter === CompanyRequestListFilter.CompanyMyReviewRequired ||
                    (searchParams && searchParams.requestStatusV2 === domain.RequestStatusV2.OnReview)
            );

            return createAction(COMPLETE_REVIEW, {
                stepId: reviewStep.reviewStepId,
                activeFilter,
                requestList,
                removeFromList,
                target,
            });
        },

        response: async (request) => {
            await api.requests.completeReview({
                requestId: target.id,
                requestVersion: target.version,
                companyId: target.companyId,
            });

            return createAction(COMPLETE_REVIEW_RESPONSE, { requestId: request.target.id });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            if (isFolderChangedAfterDecision(state, request.target, selectedFilter)) {
                toast.success(
                    <>
                        <Text font='body'>{intl.formatMessage(messages.completeReviewSuccess)}</Text>

                        <Link
                            to={getPath(Path.request, request.target.id, request.target.companyId)}
                        >{`Go to ${request.target.displayName}`}</Link>
                    </>
                );
            } else {
                toast.success(intl.formatMessage(messages.completeReviewSuccess));
            }

            await dispatch(reloadRequest(request.target.id, request.target.companyId));
        },

        failure: (error, request) =>
            createErrorAction(COMPLETE_REVIEW_FAILURE, error, {
                requestId: request.target.id,
                removeFromList: request.removeFromList,
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            let needsReload = false;

            const errorCode = errorHelpers.getErrorCode(error);

            switch (errorCode) {
                case ErrorCode.E2011_REQUEST_NOT_FOUND:
                    needsReload = false;
                    break;

                case ErrorCode.E4030_OPERATION_VALID_FOR_OPEN_ONLY:
                case ErrorCode.E4050_RESPONSE_OUTDATED:
                    needsReload = true;
                    break;
            }

            if (needsReload) {
                await dispatch(reloadRequest(request.target.id, request.target.companyId));
            }
        },
    });

export const RETURN_TO_REVIEW = 'REQUESTLIST/RETURN_TO_REVIEW';
export const RETURN_TO_REVIEW_RESPONSE = 'REQUESTLIST/RETURN_TO_REVIEW_RESPONSE';
export const RETURN_TO_REVIEW_FAILURE = 'REQUESTLIST/RETURN_TO_REVIEW_FAILURE';
export const returnToReview = (target: selectors.types.ExpandedRequest, selectedFilter: string | null) =>
    createAsyncAction({
        request: (state: State) => {
            const activeStep = target.steps.find((s) => s.state === domain.RequestStepState.Active)!;

            miscHelpers.invariant(activeStep, 'The request must have an active step');

            const searchParams = getSearchParams(state);
            const activeFilter = getActiveFilter(state);
            const requestList = getRequests(state).map((r) => r.id);
            const removeFromList = Boolean(
                activeFilter === RequestListFilter.MyDecisionRequired ||
                    activeFilter === CompanyRequestListFilter.CompanyMyDecisionRequired ||
                    (searchParams && searchParams.requestStatusV2 === domain.RequestStatusV2.OnApproval)
            );

            return createAction(RETURN_TO_REVIEW, {
                stepId: activeStep.id,
                activeFilter,
                requestList,
                removeFromList,
                target,
            });
        },

        response: async (request) => {
            await api.requests.returnToReview({
                requestId: target.id,
                requestVersion: target.version,
                companyId: target.companyId,
            });

            return createAction(RETURN_TO_REVIEW_RESPONSE, { requestId: request.target.id });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            if (isFolderChangedAfterDecision(state, request.target, selectedFilter)) {
                toast.success(
                    <>
                        <Text font='body'>{intl.formatMessage(messages.sendToReviewSuccess)}</Text>

                        <Link
                            to={getPath(Path.request, request.target.id, request.target.companyId)}
                        >{`Go to ${request.target.displayName}`}</Link>
                    </>
                );
            } else {
                toast.success(intl.formatMessage(messages.sendToReviewSuccess));
            }

            await dispatch(reloadRequest(request.target.id, request.target.companyId));
        },

        failure: (error, request) =>
            createErrorAction(RETURN_TO_REVIEW_FAILURE, error, {
                requestId: request.target.id,
                removeFromList: request.removeFromList,
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            let needsReload = false;

            const errorCode = errorHelpers.getErrorCode(error);

            switch (errorCode) {
                case ErrorCode.E2011_REQUEST_NOT_FOUND:
                    needsReload = false;
                    break;

                case ErrorCode.E4030_OPERATION_VALID_FOR_OPEN_ONLY:
                case ErrorCode.E4050_RESPONSE_OUTDATED:
                    needsReload = true;
                    break;
            }

            if (needsReload) {
                await dispatch(reloadRequest(request.target.id, request.target.companyId));
            }
        },
    });

export const MAKE_DECISION = 'REQUESTLIST/MAKE_DECISION';
export const MAKE_DECISION_RESPONSE = 'REQUESTLIST/MAKE_DECISION_RESPONSE';
export const MAKE_DECISION_FAILURE = 'REQUESTLIST/MAKE_DECISION_FAILURE';
export const makeDecision = ({
    target,
    decision,
    selectedFilter,
    commentText = null,
    showResultsExplicitly = false,
    mentionedUserIds = [],
}: {
    target: selectors.types.ExpandedRequest;
    decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject;
    selectedFilter: string | null;
    commentText?: string | null;
    showResultsExplicitly?: boolean;
    mentionedUserIds?: string[];
}) =>
    createAsyncAction({
        shouldSendRequest: (state, dispatch) => {
            const activeStep = target.steps.find((s) => s.state === domain.RequestStepState.Active);

            function dispatchOperationResult(operationResult: OperationResult) {
                dispatch(showOperationResultPopup(request.id, activeStep ? activeStep.id : null, operationResult));
            }

            const request = target;

            if (request.flags.status.isClosed) {
                switch (request.statusV2) {
                    case domain.RequestStatusV2.Approved:
                        dispatchOperationResult(OperationResult.FailedRequestClosedApproved);

                        return false;

                    case domain.RequestStatusV2.Rejected:
                        dispatchOperationResult(OperationResult.FailedRequestClosedRejected);

                        return false;

                    case domain.RequestStatusV2.Cancelled:
                        dispatchOperationResult(OperationResult.FailedRequestCancelled);

                        return false;

                    default:
                        throw errorHelpers.notSupportedError();
                }
            }

            if (decision === domain.RequestStepParticipantDecision.Approve) {
                const isEditingOnApprovalAvailable = selectors.company.getIsEditingOnApprovalAvailable(
                    request.company,
                    request.integrationCode
                );
                const requiresEditingForEditOnApproval =
                    request.requiresEditingForEditOnApproval && isEditingOnApprovalAvailable;
                const requiresEditingForReviewerV1 =
                    request.requiresEditingForReviewerV1 &&
                    request.company.betaFeatures.includes(domain.CompanyBetaFeature.ReviewerV1);

                // Additional validation for request approval
                if (requiresEditingForReviewerV1 || requiresEditingForEditOnApproval) {
                    dispatchOperationResult(OperationResult.FailedRequestRequiresEditing);

                    return false;
                }
            }

            return true;
        },

        request: (state: State) => {
            const activeStep = target.steps.find((s) => s.state === domain.RequestStepState.Active)!;

            miscHelpers.invariant(activeStep, 'The request must have an active step');

            const searchParams = getSearchParams(state);
            const activeFilter = getActiveFilter(state);
            const requestList = getRequests(state).map((r) => r.id);
            const removeFromList = Boolean(
                decision === domain.RequestStepParticipantDecision.Approve &&
                    (activeFilter === RequestListFilter.MyDecisionRequired ||
                        activeFilter === CompanyRequestListFilter.CompanyMyDecisionRequired ||
                        (searchParams && searchParams.requestStatusV2 === domain.RequestStatusV2.OnApproval))
            );

            return createAction(MAKE_DECISION, {
                stepId: activeStep.id,
                activeFilter,
                requestList,
                removeFromList,
                target,
                decision,
                commentText,
                showResultsExplicitly,
            });
        },

        response: async (request) => {
            await api.requests.respond({
                response:
                    decision === domain.RequestStepParticipantDecision.Approve
                        ? backend.RequestResponseTransfer.Approve
                        : backend.RequestResponseTransfer.Reject,
                commentText,
                requestId: target.id,
                stepId: request.stepId,
                requestVersion: target.version,
                companyId: target.companyId,
                mentionedUserIds,
            });

            return createAction(MAKE_DECISION_RESPONSE, { requestId: request.target.id });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            if (showResultsExplicitly) {
                await dispatch(
                    showOperationResultPopup(
                        request.target.id,
                        request.stepId,
                        decision === domain.RequestStepParticipantDecision.Approve
                            ? OperationResult.SuccessApproved
                            : OperationResult.SuccessRejected
                    )
                );
            } else {
                let message;

                if (decision === domain.RequestStepParticipantDecision.Approve) {
                    message = intl.formatMessage(messages.makeDecisionApprovedSuccess);
                } else {
                    message = intl.formatMessage(messages.makeDecisionDeclinedSuccess);
                }

                if (isFolderChangedAfterDecision(state, request.target, selectedFilter, decision)) {
                    toast.success(
                        <>
                            <Text font='body'>{message}</Text>

                            <Link
                                to={getPath(Path.request, request.target.id, request.target.companyId)}
                            >{`Go to ${request.target.displayName}`}</Link>
                        </>
                    );
                } else {
                    toast.success(message);
                }
            }

            await dispatch(reloadRequest(request.target.id, request.target.companyId));
        },

        failure: (error, request) =>
            createErrorAction(MAKE_DECISION_FAILURE, error, {
                requestId: request.target.id,
                removeFromList: request.removeFromList,
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            const isXeroMatchingV2 = target.company.flags.isXeroMatchingV2;
            const activeStep = target.steps.find((s) => s.state === domain.RequestStepState.Active);

            function dispatchOperationResult(operationResult: OperationResult) {
                dispatch(showOperationResultPopup(target.id, activeStep ? activeStep.id : null, operationResult));
            }

            let needsReload = false;
            let result: OperationResult | null = null;

            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E5018_XERO_RESPONSE_OUTDATED_DUE_TO_CHANGES_IN_XERO) {
                try {
                    await dispatch(integrationActions.syncIntegration({ companyId: request.target.companyId }));
                } finally {
                    result = OperationResult.FailedRequestExternallyUpdated;
                }
            } else {
                switch (errorCode) {
                    case ErrorCode.E2011_REQUEST_NOT_FOUND:
                        result = OperationResult.FailedRequestNotFound;
                        needsReload = false;
                        break;

                    case ErrorCode.E2013_REQUEST_STEP_PARTICIPANT_NOT_FOUND:
                        result = OperationResult.FailedNotAnApprover;
                        needsReload = true;
                        break;

                    case ErrorCode.E2012_REQUEST_STEP_NOT_FOUND:
                    case ErrorCode.E4030_OPERATION_VALID_FOR_OPEN_ONLY:
                    case ErrorCode.E4050_RESPONSE_OUTDATED:
                        result = OperationResult.FailedRequestExternallyUpdated;
                        needsReload = true;
                        break;

                    case ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT:
                        needsReload = true;
                        break;

                    case ErrorCode.E8003_OVERBUDGET_BILLS_APPROVAL_NOT_ALLOWED:
                        if (isXeroMatchingV2) {
                            dispatchOperationResult(OperationResult.FailedRequestMatchedPOsBalanceIsExceeded);
                        }

                        break;

                    case ErrorCode.E8005_MATCHING_AMOUNT_IS_EXCEEDED:
                        if (isXeroMatchingV2) {
                            dispatchOperationResult(OperationResult.FailedRequestAllAllocationsExceedBillAmount);
                        }

                        break;
                }
            }

            if (result) {
                dispatch(showOperationResultPopup(request.target.id, request.stepId, result));
            }

            if (needsReload) {
                await dispatch(reloadRequest(request.target.id, request.target.companyId));
            }
        },
    });

export const MAKE_FORCE_DECISION = 'REQUESTLIST/MAKE_FORCE_DECISION';
export const MAKE_FORCE_DECISION_RESPONSE = 'REQUESTLIST/MAKE_FORCE_DECISION_RESPONSE';
export const MAKE_FORCE_DECISION_FAILURE = 'REQUESTLIST/MAKE_FORCE_DECISION_FAILURE';
export const makeForceDecision = (
    target: domain.Request,
    decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject,
    selectedFilter: string | null,
    commentText: string = ''
) =>
    createAsyncAction({
        request: () =>
            createAction(MAKE_FORCE_DECISION, {
                target,
                decision,
                commentText,
            }),

        response: async (request) => {
            await api.requests.forceDecision({
                requestId: target.id,
                companyId: target.companyId,
                response:
                    decision === domain.RequestStepParticipantDecision.Approve
                        ? backend.RequestResponseTransfer.Approve
                        : backend.RequestResponseTransfer.Reject,
                commentText,
            });

            return createAction(MAKE_FORCE_DECISION_RESPONSE, {
                requestId: request.target.id,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            let message;

            if (request.decision === domain.RequestStepParticipantDecision.Approve) {
                message = intl.formatMessage(messages.makeForceDecisionApprovedSuccess);
            } else {
                message = intl.formatMessage(messages.makeForceDecisionDeclinedSuccess);
            }

            if (isFolderChangedAfterCancelOrForceDecision(selectedFilter)) {
                toast.success(
                    <>
                        <Text font='body'>{message}</Text>

                        <Link
                            to={getPath(Path.request, request.target.id, request.target.companyId)}
                        >{`Go to ${request.target.displayName}`}</Link>
                    </>
                );
            } else {
                toast.success(message);
            }

            await dispatch(reloadRequest(request.target.id, request.target.companyId));
        },

        failure: (error, request) =>
            createErrorAction(MAKE_FORCE_DECISION_FAILURE, error, {
                requestId: request.target.id,
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT) {
                await dispatch(reloadRequest(request.target.id, request.target.companyId));
            }
        },
    });

export const CLONE_REQUEST = 'REQUESTLIST/CLONE_REQUEST';
export const CLONE_REQUEST_RESPONSE = 'REQUESTLIST/CLONE_REQUEST_RESPONSE';
export const CLONE_REQUEST_FAILURE = 'REQUESTLIST/CLONE_REQUEST_FAILURE';
export const cloneRequest = (requestId: Guid, targetTemplateId: Guid, companyId: Guid) =>
    createAsyncAction({
        request: () =>
            createAction(CLONE_REQUEST, {
                requestId,
            }),

        response: async () => {
            const response = await api.requests.copy({
                requestId,
                targetTemplateId,
                companyId,
            });

            return createAction(CLONE_REQUEST_RESPONSE, {
                requestId,
                newRequestId: response.requestId,
                companyId,
            });
        },

        didDispatchResponse: async (request, response) => {
            routingService.push(getPath(Path.editRequest, response.newRequestId, response.companyId));
        },

        failure: (error) => createErrorAction(CLONE_REQUEST_FAILURE, error, { requestId }),
    });

export const CANCEL_REQUEST = 'REQUESTLIST/CANCEL_REQUEST';
export const CANCEL_REQUEST_RESPONSE = 'REQUESTLIST/CANCEL_REQUEST_RESPONSE';
export const CANCEL_REQUEST_FAILURE = 'REQUESTLIST/CANCEL_REQUEST_FAILURE';
export const cancelRequest = (requestId: Guid, selectedFilter: string | null, commentText: string = '') =>
    createAsyncAction({
        request: (state: State) =>
            createAction(CANCEL_REQUEST, {
                requestId,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
            }),

        response: async (request) => {
            await api.requests.cancel({
                requestId,
                commentText,
                companyId: request.companyId,
            });

            return createAction(CANCEL_REQUEST_RESPONSE, {
                requestId,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            if (isFolderChangedAfterCancelOrForceDecision(selectedFilter)) {
                const currentRequest = selectors.request.getRequestById(state, request.requestId);

                toast.success(
                    <>
                        <Text font='body'>{intl.formatMessage(messages.cancelRequestSuccessText)}</Text>

                        <Link
                            to={getPath(Path.request, request.requestId, request.companyId)}
                        >{`Go to ${currentRequest.displayName}`}</Link>
                    </>
                );
            } else {
                toast.success(intl.formatMessage(messages.cancelRequestSuccessText));
            }

            await dispatch(reloadRequest(requestId, request.companyId));
        },

        failure: (error) => createErrorAction(CANCEL_REQUEST_FAILURE, error, { requestId }),
    });

export const DELETE_REQUEST = 'REQUESTLIST/DELETE_REQUEST';
export const DELETE_REQUEST_RESPONSE = 'REQUESTLIST/DELETE_REQUEST_RESPONSE';
export const DELETE_REQUEST_FAILURE = 'REQUESTLIST/DELETE_REQUEST_FAILURE';
export const deleteRequest = (requestId: Guid) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(DELETE_REQUEST, {
                requestId,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
            }),

        response: async (request, getState) => {
            await api.requests.delete({ requestId, companyId: request.companyId });

            return createAction(DELETE_REQUEST_RESPONSE, {
                requestId,
                requestList: getRequests(getState()).map((r) => r.id),
            });
        },

        failure: (error) =>
            createErrorAction(DELETE_REQUEST_FAILURE, error, {
                requestId,
            }),

        successToast: intl.formatMessage(messages.deleteRequestSuccess),
    });

export const SUBMIT_REQUEST = 'REQUESTLIST/SUBMIT_REQUEST';
export const SUBMIT_REQUEST_RESPONSE = 'REQUESTLIST/SUBMIT_REQUEST_RESPONSE';
export const SUBMIT_REQUEST_FAILURE = 'REQUESTLIST/SUBMIT_REQUEST_FAILURE';
export const submitRequest = (requestId: Guid, companyId: Guid) =>
    createAsyncAction({
        request: () => createAction(SUBMIT_REQUEST, { requestId, companyId }),

        response: async () => {
            await api.requests.publish({ requestId, companyId });

            return createAction(SUBMIT_REQUEST_RESPONSE, {
                requestId,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            const template = getActiveTemplate(state);

            if (template) {
                amplitudeService.sendData('requests: create request', {
                    'request type': template.integrationCode?.toLocaleLowerCase() || 'standalone',
                });
            }

            await dispatch(actions.addInfoToast(intl.formatMessage(messages.submitRequestSuccess)));
            await dispatch(reloadRequest(requestId, request.companyId));
        },

        failure: (error) => createErrorAction(SUBMIT_REQUEST_FAILURE, error, { requestId }),
    });

export const START_OVER_REQUEST = 'REQUESTLIST/START_OVER_REQUEST';
export const START_OVER_REQUEST_RESPONSE = 'REQUESTLIST/START_OVER_REQUEST_RESPONSE';
export const START_OVER_REQUEST_FAILURE = 'REQUESTLIST/START_OVER_REQUEST_FAILURE';
export const startOverRequest = (target: domain.Request, commentText: string = '') =>
    createAsyncAction({
        request: () =>
            createAction(START_OVER_REQUEST, {
                requestId: target.id,
                requestTemplateId: target.template.id,
                commentText,
                companyId: target.companyId,
            }),

        response: async (request) => {
            await api.requests.resetTemplate({
                requestIds: [request.requestId],
                templateId: request.requestTemplateId,
                commentText: request.commentText,
                companyId: request.companyId,
            });

            return createAction(START_OVER_REQUEST_RESPONSE, {
                requestId: request.requestId,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            notificationService.showInfoToast(
                intl.formatMessage(messages.startOverSuccessText, {
                    requestName: target.displayName,
                })
            );
            await dispatch(reloadRequest(request.requestId, request.companyId));
        },

        failure: (error) => createErrorAction(START_OVER_REQUEST_FAILURE, error, { requestId: target.id }),

        didDispatchError: async (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT) {
                dispatch(
                    showOperationResultPopup(request.requestId, null, OperationResult.FailedRequestExternallyUpdated)
                );
                await dispatch(reloadRequest(request.requestId, request.companyId));
            }
        },
    });

export const REVOKE_DECISION = 'REQUESTLIST/REVOKE_DECISION';
export const REVOKE_DECISION_RESPONSE = 'REQUESTLIST/REVOKE_DECISION_RESPONSE';
export const REVOKE_DECISION_FAILURE = 'REQUESTLIST/REVOKE_DECISION_FAILURE';
export const revokeDecision = (target: domain.Request, commentText: string = '') =>
    createAsyncAction({
        request: (state: State) => {
            const me = selectors.profile.getProfileUser(state).id;
            const step = target.steps.find((s) =>
                s.participants.some(
                    (p) => p.userId === me && p.decision !== domain.RequestStepParticipantDecision.NoResponse
                )
            );

            miscHelpers.invariant(step, 'The request must have a step approved by me.');

            return createAction(REVOKE_DECISION, {
                requestId: target.id,
                request: target,
                commentText,
                stepId: step!.id,
                companyid: target.companyId,
            });
        },

        response: async (request) => {
            await api.requests.respond({
                response: backend.RequestResponseTransfer.Revoke,
                commentText,
                requestId: request.requestId,
                stepId: request.stepId,
                requestVersion: request.request.version,
                companyId: target.companyId,
            });

            return createAction(REVOKE_DECISION_RESPONSE, {
                requestId: target.id,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            notificationService.showInfoToast(intl.formatMessage(messages.revokeRequestSuccess));
            await dispatch(reloadRequest(request.requestId, request.companyid));
        },

        failure: (error, request, state) =>
            createErrorAction(REVOKE_DECISION_FAILURE, error, {
                requestId: target.id,
                requestList: getRequests(state).map((r) => r.id),
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            let needsReload = false;

            if (errorCode === ErrorCode.E5018_XERO_RESPONSE_OUTDATED_DUE_TO_CHANGES_IN_XERO) {
                await dispatch(integrationActions.syncIntegration({ companyId: request.request.companyId }));
            } else {
                switch (errorCode) {
                    case ErrorCode.E2011_REQUEST_NOT_FOUND:
                        needsReload = false;
                        break;

                    case ErrorCode.E2013_REQUEST_STEP_PARTICIPANT_NOT_FOUND:
                    case ErrorCode.E2012_REQUEST_STEP_NOT_FOUND:
                    case ErrorCode.E4030_OPERATION_VALID_FOR_OPEN_ONLY:
                    case ErrorCode.E4050_RESPONSE_OUTDATED:
                    case ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT:
                        needsReload = true;
                        break;
                }
            }

            if (needsReload) {
                await dispatch(reloadRequest(request.requestId, request.companyid));
            }
        },
    });

export const EDIT_STEP_PARTICIPANTS = 'REQUESTLIST/EDIT_STEP_PARTICIPANTS';
export const EDIT_STEP_PARTICIPANTS_RESPONSE = 'REQUESTLIST/EDIT_STEP_PARTICIPANTS_RESPONSE';
export const EDIT_STEP_PARTICIPANTS_FAILURE = 'REQUESTLIST/EDIT_STEP_PARTICIPANTS_FAILURE';
export const editStepParticipants = (options: {
    requestId: Guid;
    step: domain.RequestStep | domain.RequestReviewStep;
    addedEmails: string[];
    removedEmails: string[];
    commentText: string;
    companyId: Guid;
}) =>
    createAsyncAction({
        request: () =>
            createAction(EDIT_STEP_PARTICIPANTS, {
                ...options,
            }),

        response: async (request) => {
            const stepId = 'id' in request.step ? request.step.id : request.step.reviewStepId;

            await api.requests.changeParticipants({
                requestId: request.requestId,
                changes: [
                    {
                        stepId,
                        participantsToAdd: request.addedEmails,
                        participantsToDelete: request.removedEmails,
                    },
                ],
                commentText: request.commentText,
                companyId: request.companyId,
            });

            return createAction(EDIT_STEP_PARTICIPANTS_RESPONSE, {
                requestId: request.requestId,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            notificationService.showInfoToast(
                'name' in request.step
                    ? intl.formatMessage(messages.editStepParticipantsSuccessText, {
                          stepName: request.step.name,
                      })
                    : intl.formatMessage(messages.editStepReviewersSuccessText)
            );
            await dispatch(reloadRequest(request.requestId, request.companyId));
        },

        failure: (error, request) =>
            createErrorAction(EDIT_STEP_PARTICIPANTS_FAILURE, error, {
                requestId: request.requestId,
            }),

        didDispatchError: async (request, error, state, dispatch) => {
            const errorCode = errorHelpers.getErrorCode(error);

            if (errorCode === ErrorCode.E4075_OPERATION_FAILED_DUE_TO_EXTERNAL_REJECT) {
                await dispatch(reloadRequest(request.requestId, request.companyId));
            }
        },
    });

export const REASSIGN_REQUEST = 'REQUESTLIST/REASSIGN_REQUEST';
export const REASSIGN_REQUEST_RESPONSE = 'REQUESTLIST/REASSIGN_REQUEST_RESPONSE';
export const REASSIGN_REQUEST_FAILURE = 'REQUESTLIST/REASSIGN_REQUEST_FAILURE';
export const reassignRequest = (options: {
    requestId: Guid;
    step: domain.RequestStep | domain.RequestReviewStep;
    target: selectors.types.ExpandedUser;
    commentText?: string;
    companyId: Guid;
}) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(REASSIGN_REQUEST, {
                ...options,
                me: selectors.profile.getProfileUser(state),
            }),

        response: async (request) => {
            await api.requests.changeParticipants({
                requestId: request.requestId,
                changes: [
                    {
                        stepId: 'id' in request.step ? request.step.id : request.step.reviewStepId,
                        participantsToAdd: [request.target.userEmail],
                        participantsToDelete: [request.me.userEmail],
                    },
                ],
                commentText: request.commentText,
                companyId: request.companyId,
            });

            return createAction(REASSIGN_REQUEST_RESPONSE, {
                requestId: request.requestId,
            });
        },

        didDispatchResponse: async (request, response, state, dispatch) => {
            notificationService.showInfoToast(
                intl.formatMessage(messages.reassignRequestSuccessText, {
                    displayName: request.target.displayName,
                })
            );
            await dispatch(reloadRequest(request.requestId, request.companyId));
        },

        failure: (error, request) =>
            createErrorAction(REASSIGN_REQUEST_FAILURE, error, {
                requestId: request.requestId,
            }),
    });

export const ADD_REQUEST_COMMENT = 'REQUESTLIST/ADD_REQUEST_COMMENT';
export const ADD_REQUEST_COMMENT_RESPONSE = 'REQUESTLIST/ADD_REQUEST_COMMENT_RESPONSE';
export const ADD_REQUEST_COMMENT_FAILURE = 'REQUESTLIST/ADD_REQUEST_COMMENT_FAILURE';
export const addRequestComment = (
    requestId: Guid,
    commentText: string,
    attachmentIds: string[],
    mentionedUserIds?: string[]
) =>
    createAsyncAction({
        request: (state: State) =>
            createAction(ADD_REQUEST_COMMENT, {
                requestId,
                commentText,
                attachmentIds,
                me: selectors.profile.getProfileUser(state).id,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
                mentionedUserIds,
            }),

        response: async (request) => {
            const response = await api.requests.addComment({
                requestId,
                commentText,
                commentAttachments: attachmentIds,
                companyId: request.companyId,
                mentionedUserIds,
            });

            return createAction(ADD_REQUEST_COMMENT_RESPONSE, {
                requestId,
                commentEvent: {
                    id: response.Comment.CommentId,
                    authorId: request.me,
                    type: domain.RequestHistoryEventType.Comment,
                    date: response.Comment.CreatedDate,
                    comment: {
                        id: response.Comment.CommentId,
                        author: request.me,
                        createdDate: response.Comment.CreatedDate,
                        text: response.Comment.Text,
                        attachments: (response.Comment.CommentAttachments || []).map((a) =>
                            schemas.request.mapAttachment(a)
                        ),
                        mentionedUsers: (response.Comment.MentionedUsers || []).map(
                            (mentionedUser: backend.MentionedUserAnswer) =>
                                domain.schemas.mapMentionedUser(mentionedUser)
                        ),
                    },
                } as domain.RequestHistoryEvent,
            });
        },

        failure: (error) =>
            createErrorAction(ADD_REQUEST_COMMENT_FAILURE, error, {
                requestId,
                commentText,
            }),
    });

export const DELETE_COMMENT_ATTACHMENT = 'REQUESTLIST/DELETE_COMMENT_ATTACHMENT';
export const DELETE_COMMENT_ATTACHMENT_RESPONSE = 'REQUESTLIST/DELETE_COMMENT_ATTACHMENT_RESPONSE';
export const DELETE_COMMENT_ATTACHMENT_FAILURE = 'REQUESTLIST/DELETE_COMMENT_ATTACHMENT_FAILURE';
export const deleteCommentAttachment = (attachmentId: string) =>
    createAsyncAction({
        request: (state: State) => {
            const targetHistoryItem = getActiveRequest(state).history.find((hi) =>
                Boolean(hi.comment && hi.comment.attachments.find((a) => a.id === attachmentId))
            )!;
            const requestId = getActiveRequestId(state)!;

            return createAction(DELETE_COMMENT_ATTACHMENT, {
                attachmentId,
                requestId,
                targetHistoryItem,
                companyId: selectors.request.getRequestById(state, requestId).companyId,
            });
        },

        response: async (request) => {
            await api.requests.deleteCommentAttachment({
                commentAttachmentId: attachmentId,
                companyId: request.companyId,
            });

            return createAction(DELETE_COMMENT_ATTACHMENT_RESPONSE, {
                request,
            });
        },

        failure: (error, request) =>
            createErrorAction(DELETE_COMMENT_ATTACHMENT_FAILURE, error, {
                request,
            }),
    });

export const CANCEL_ACTIVE_POPUP = 'REQUESTLIST/CANCEL_ACTIVE_POPUP';
export const cancelActivePopup = () => createAction(CANCEL_ACTIVE_POPUP, {});

export const SHOW_MATCHING_POPUP = 'REQUESTLIST/SHOW_MATCHING_POPUP';
export const showMatchingPopup = () => createAction(SHOW_MATCHING_POPUP, {});

export const SHOW_MATCHING_PO_REQUESTERS_POPUP = 'REQUESTLIST/SHOW_MATCHING_PO_REQUESTERS_POPUP';
export const showMatchingPORequestersPopup = (requesters: MatchingRequester[]) =>
    createAction(SHOW_MATCHING_PO_REQUESTERS_POPUP, { requesters: requesters });

export const SHOW_REJECT_REQUEST_POPUP = 'REQUESTLIST/SHOW_REJECT_REQUEST_POPUP';
export const showRejectRequestPopup = (requestId: Guid, showResultsExplicitly: boolean = false) =>
    createAction(SHOW_REJECT_REQUEST_POPUP, {
        requestId,
        showResultsExplicitly,
    });

export const SHOW_DECLINE_CUSTOMER_DECISION_POPUP = 'REQUESTLIST/SHOW_DECLINE_CUSTOMER_DECISION_POPUP';
export const showDeclineCustomerDecisionPopup = (requestId: Guid) =>
    createAction(SHOW_DECLINE_CUSTOMER_DECISION_POPUP, {
        requestId,
    });

export const SHOW_FORCE_DECISION_POPUP = 'REQUESTLIST/SHOW_FORCE_DECISION_POPUP';
export const showForceDecisionPopup = (
    requestId: Guid,
    decision: domain.RequestStepParticipantDecision.Approve | domain.RequestStepParticipantDecision.Reject
) =>
    createAction(SHOW_FORCE_DECISION_POPUP, {
        requestId,
        decision: decision as
            | domain.RequestStepParticipantDecision.Approve
            | domain.RequestStepParticipantDecision.Reject,
    });

export const SHOW_CANCEL_REQUEST_POPUP = 'REQUESTLIST/SHOW_CANCEL_REQUEST_POPUP';
export const showCancelRequestPopup = (requestId: Guid) =>
    createAction(SHOW_CANCEL_REQUEST_POPUP, {
        requestId,
    });

export const SHOW_START_OVER_POPUP = 'REQUESTLIST/SHOW_START_OVER_POPUP';
export const showStartOverPopup = (requestId: Guid) =>
    createAction(SHOW_START_OVER_POPUP, {
        requestId,
    });

export const SHOW_REVOKE_DECISION_POPUP = 'REQUESTLIST/SHOW_REVOKE_DECISION_POPUP';
export const showRevokeDecisionPopup = (requestId: Guid) =>
    createAction(SHOW_REVOKE_DECISION_POPUP, {
        requestId,
    });

export const SHOW_REQUEST_REASSIGN_POPUP = 'REQUESTLIST/SHOW_REQUEST_REASSIGN_POPUP';
export const showRequestReassignPopup = (requestId: Guid, stepId: Guid) =>
    createAction(SHOW_REQUEST_REASSIGN_POPUP, { requestId, stepId });

export const SHOW_EDIT_PARTICIPANTS_POPUP = 'REQUESTLIST/SHOW_EDIT_PARTICIPANTS_POPUP';
export const showEditParticipantsPopup = (requestId: Guid, stepId: Guid) =>
    createAction(SHOW_EDIT_PARTICIPANTS_POPUP, { requestId, stepId });

export const SHOW_EDIT_REVIEWERS_POPUP = 'REQUESTLIST/SHOW_EDIT_REVIEWERS_POPUP';
export const showEditReviewersPopup = (requestId: Guid, reviewStep: domain.RequestReviewStep) =>
    createAction(SHOW_EDIT_REVIEWERS_POPUP, { requestId, reviewStep });

export const SHOW_EDIT_WATCHERS_POPUP = 'REQUESTLIST/SHOW_EDIT_WATCHERS_POPUP';
export const showEditWatchersPopup = (requestId: Guid) => createAction(SHOW_EDIT_WATCHERS_POPUP, { requestId });

export const SHOW_REQUEST_SEARCH_POPUP = 'REQUESTLIST/SHOW_REQUEST_SEARCH_POPUP';
export const SHOW_REQUEST_SEARCH_POPUP_RESPONSE = 'REQUESTLIST/SHOW_REQUEST_SEARCH_POPUP_RESPONSE';
export const SHOW_REQUEST_SEARCH_POPUP_FAILURE = 'REQUESTLIST/SHOW_REQUEST_SEARCH_POPUP_FAILURE';
export const showRequestSearchPopup = (searchParams: SearchParameters | null = null) =>
    createAsyncAction({
        shouldSendRequest: async (state, dispatch) => {
            await dispatch(search.actions.loadSearchContext());

            const isSearchPage = selectors.navigation.getInnerActivePageId(state) === SEARCH_REQUESTS_LIST_PAGE_ID;

            return isSearchPage;
        },

        request: () =>
            createAction(SHOW_REQUEST_SEARCH_POPUP, {
                searchParams,
            }),

        response: async (request) => {
            return createAction(SHOW_REQUEST_SEARCH_POPUP_RESPONSE, {
                request,
            });
        },

        failure: (error) => createErrorAction(SHOW_REQUEST_SEARCH_POPUP_FAILURE, error, {}),
    });

export const REFRESH_AMAX_PAY_XERO_BATCH_PAYMENT_STATUSES = 'REQUESTLIST/REFRESH_AMAX_PAY_XERO_BATCH_PAYMENT_STATUSES';
export const refreshAmaxPayXeroBatchPaymentStatuses = (
    requestId: Guid,
    batchPaymentStatus: domain.AmaxPayXeroBatchPaymentStatus,
    items: UseAmaxPayXeroRefreshResponse['data']['billPaymentStatuses']
) => createAction(REFRESH_AMAX_PAY_XERO_BATCH_PAYMENT_STATUSES, { requestId, batchPaymentStatus, items });

export type Action = ExtractActions<
    | typeof addRequestComment
    | typeof cancelActivePopup
    | typeof cancelRequest
    | typeof cloneRequest
    | typeof completeReview
    | typeof deleteCommentAttachment
    | typeof deleteRequest
    | typeof editStepParticipants
    | typeof leavePage
    | typeof loadMoreRequests
    | typeof loadMoreSearchResults
    | typeof loadPageData
    | typeof loadSearchResultsDataFailure
    | typeof loadSearchResultsDataResponse
    | typeof makeDecision
    | typeof makeForceDecision
    | typeof reassignRequest
    | typeof refreshAmaxPayXeroBatchPaymentStatuses
    | typeof reloadRequest
    | typeof reloadRequestList
    | typeof reloadRequestSync
    | typeof returnToReview
    | typeof revokeDecision
    | typeof setActiveRequestId
    | typeof showCancelRequestPopup
    | typeof showDeclineCustomerDecisionPopup
    | typeof showEditParticipantsPopup
    | typeof showEditReviewersPopup
    | typeof showEditWatchersPopup
    | typeof showForceDecisionPopup
    | typeof showMatchingPORequestersPopup
    | typeof showMatchingPopup
    | typeof showOperationResultPopup
    | typeof showRejectRequestPopup
    | typeof showRequestReassignPopup
    | typeof showRequestSearchPopup
    | typeof showRevokeDecisionPopup
    | typeof showStartOverPopup
    | typeof startOverRequest
    | typeof submitRequest
>;
