import { CaretUpIcon } from '../../../../icons';
import type { BaseItem } from '../../Table.types';
import { HeaderContent, iconSize, SortIcon } from './ColumnHeader.styles';
import type { ColumnHeaderProps } from './ColumnHeader.types';

export const getHeaderColumnContent = <Item extends BaseItem>(props: ColumnHeaderProps<Item>) => {
    const { columnDefinition } = props;

    return (
        <HeaderContent>
            <div>{columnDefinition.name}</div>

            {columnDefinition.sortable && (
                <SortIcon $sortOrder={columnDefinition.sortOrder}>
                    <CaretUpIcon size={iconSize} />
                </SortIcon>
            )}
        </HeaderContent>
    );
};
