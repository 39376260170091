import { FetchIcon } from '@approvalmax/ui';
import { Button, Dropdown, Flex } from '@approvalmax/ui/src/components';
import { domain } from 'modules/data';
import { NetSuiteCacheManagementPanel, xero } from 'modules/integration';
import { FC, memo } from 'react';
import { HelpStripe } from 'shared/components';

import { MatrixType } from '../../../../types/matrix';
import { messages } from './PopupActions.messages';
import { PopupActionsProps } from './PopupActions.types';

const PopupActions: FC<PopupActionsProps> = memo((props) => {
    const { company, integrationType, readonly, integrationCode, applyAndClose, matrixType } = props;

    const isXeroCacheManagementBtnVisible =
        integrationType === domain.IntegrationType.Xero &&
        (matrixType !== MatrixType.AutoApproval || integrationCode !== domain.IntegrationCode.XeroContact) &&
        !readonly;
    const isNetSuiteCacheManagementBtnVisible =
        integrationType === domain.IntegrationType.NetSuite &&
        !readonly &&
        company.betaFeatures.includes(domain.CompanyBetaFeature.NetSuiteRefDataCache);

    return (
        <Flex inline alignItems='center' wrap={false}>
            <HelpStripe.ToggleButton />

            {isXeroCacheManagementBtnVisible && (
                <Dropdown
                    width='max-content'
                    height='max-content'
                    placement='bottom-end'
                    activator={
                        <Button size='large' title={messages.xeroDataSyncButtonTitle}>
                            <FetchIcon size={28} />
                        </Button>
                    }
                >
                    <xero.CacheManagementPanel companyId={company.id} />
                </Dropdown>
            )}

            {isNetSuiteCacheManagementBtnVisible && (
                <Dropdown
                    width='max-content'
                    height='max-content'
                    placement='bottom-end'
                    activator={
                        <Button size='large' title={messages.netSuiteDataSyncButtonTitle}>
                            <FetchIcon size={28} />
                        </Button>
                    }
                >
                    <NetSuiteCacheManagementPanel companyId={company.id} />
                </Dropdown>
            )}

            <Button color='blue10' disabled={readonly} onClick={applyAndClose} size='large'>
                {messages.done}
            </Button>
        </Flex>
    );
});

export default PopupActions;
