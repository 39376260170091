import { domHelpers, miscHelpers } from '@approvalmax/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCallback, useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHoverDirty } from 'react-use';

import { DragIcon } from '../../../../icons';
import Checkbox from '../../../Checkbox/Checkbox';
import Radio from '../../../Radio/Radio';
import { checkboxColumnId, retrieveRowId, typedMemo } from '../../Table.helpers';
import { checkboxSpacing } from '../../Table.styles';
import { BaseItem, TableCheckType } from '../../Table.types';
import { Cell } from '..';
import { useCheckedItems } from './Row.hooks';
import { StyledCell, StyledCellDragHandler, StyledRow } from './Row.styles';
import type { RowProps } from './Row.types';

const Row = typedMemo(<Item extends BaseItem>(props: RowProps<Item>) => {
    const {
        item,
        columns,
        getRowId,
        checkedItems,
        invalidItems,
        onCheckedItemsChange,
        onClick,
        bordered,
        checkboxColumnCover,
        sticky,
        reorderable,
        checkType,
        divider,
        rounded,
        progress,
        qa,
        ...otherProps
    } = props;

    const ref = useRef<HTMLTableRowElement | null>(null);
    const isHovering = useHoverDirty(ref);

    const handleItemCheck = useCheckedItems(retrieveRowId(item, getRowId), checkedItems, onCheckedItemsChange);
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id: item.id,
        data: { type: 'row' },
        disabled: !reorderable,
    });

    const rowId = useMemo(() => retrieveRowId(item, getRowId), [getRowId, item]);
    const checked = useMemo(() => checkedItems?.includes(rowId), [checkedItems, rowId]);
    const invalid = useMemo(() => invalidItems?.includes(rowId), [invalidItems, rowId]);

    const checkboxCoverValue = checkboxColumnCover?.value ? checkboxColumnCover.value(item) : null;

    const showCheckboxCover = checkboxCoverValue && !isHovering && !checked;

    const checkboxSpacingValue = checkboxColumnCover?.spacing || checkboxSpacing;

    const combinedRef = useCallback(
        (node: HTMLTableRowElement | null) => {
            ref.current = node;
            setNodeRef(node);
        },
        [setNodeRef]
    );

    return (
        <StyledRow
            ref={combinedRef}
            $clickable={!!onClick}
            $checked={checked}
            $invalid={invalid}
            $bordered={bordered}
            $sticky={sticky}
            $divider={divider}
            $rounded={rounded}
            onClick={onClick ? () => onClick(item) : undefined}
            $transform={CSS.Transform.toString(transform)}
            $transition={transition}
            $isDragging={active?.id === item.id}
            data-qa={domHelpers.generateDataQa(qa, 'table-row')}
            data-qa-id={rowId}
            {...otherProps}
            {...attributes}
        >
            <StyledCellDragHandler
                $hide={!reorderable}
                $textAlign='center'
                $isDragging={active?.id === item.id}
                {...listeners}
            >
                <DragIcon size='20' color='blue100' />
            </StyledCellDragHandler>

            {checkType === TableCheckType.checkbox && (
                <>
                    <StyledCell $hide={!(checkedItems && showCheckboxCover)} $textAlign='center'>
                        {checkboxCoverValue}
                    </StyledCell>

                    <StyledCell
                        key={checkboxColumnId}
                        $spacing={miscHelpers.spacingPropToCss(checkboxSpacingValue)}
                        $textAlign='center'
                        $hide={!(checkedItems && !showCheckboxCover)}
                    >
                        <Checkbox size='small' checked={checked} onChange={handleItemCheck} />
                    </StyledCell>
                </>
            )}

            {checkType === TableCheckType.radio && (
                <StyledCell
                    key={checkboxColumnId}
                    $spacing={miscHelpers.spacingPropToCss(checkboxSpacingValue)}
                    $textAlign='center'
                    $hide={!(checkedItems && !showCheckboxCover)}
                >
                    <Radio.Group>
                        <Radio size='small' checked={checked} onChange={handleItemCheck} />
                    </Radio.Group>
                </StyledCell>
            )}

            {columns.map((column) => {
                const value = column.value ? column.value(item) : item[column.id as keyof Item] || null;
                const title = column.title ? column.title(item) : typeof value === 'string' ? value : undefined;

                if (progress) {
                    return (
                        <StyledCell key={column.id} $spacing={miscHelpers.spacingPropToCss(column.spacing || '12 8')}>
                            <Skeleton />
                        </StyledCell>
                    );
                }

                const CellComponent = column.cellComponent || Cell;

                return (
                    <CellComponent
                        key={column.id}
                        title={title}
                        columnDefinition={column}
                        item={item}
                        value={value}
                        bordered={bordered}
                        hovered={isHovering}
                        data-column-id={column.id}
                    />
                );
            })}
        </StyledRow>
    );
});

export default Row;
