export enum ReportType {
    Request = 'Request',
    LineItem = 'LineItem',
}

export enum ReportCode {
    Request = 'Request',
    AirwallexXeroBatchPaymentLine = 'AirwallexXeroBatchPaymentLine',
    XeroAmaxPayBatchPaymentLine = 'AmaxPayXeroBatchPaymentLine',
    DearPurchaseOrderLine = 'DearPurchaseOrderLine',
    NetSuiteBillLine = 'NetSuiteBillLine',
    NetSuitePurchaseOrderLine = 'NetSuitePurchaseOrderLine',
    NetSuiteSalesOrderLine = 'NetSuiteSalesOrderLine',
    QBooksBillInvoiceLine = 'QBooksBillInvoiceLine',
    QBooksExpenseLine = 'QBooksExpenseLine',
    QBooksPurchaseOrderLine = 'QBooksPurchaseOrderLine',
    QBooksSalesInvoiceLine = 'QBooksSalesInvoiceLine',
    XeroAPCreditNoteLine = 'XeroAPCreditNoteLine',
    XeroARCreditNoteLine = 'XeroARCreditNoteLine',
    XeroBillInvoiceLine = 'XeroBillInvoiceLine',
    XeroPurchaseOrderLine = 'XeroPurchaseOrderLine',
    XeroQuoteLine = 'XeroQuoteLine',
    XeroSalesInvoiceLine = 'XeroSalesInvoiceLine',
    XeroManualJournalLine = 'XeroManualJournalLine',
}
