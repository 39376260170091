import { Tag } from '@approvalmax/ui/src/components';
import { selectors } from 'modules/common';
import { domain } from 'modules/data';
import { useSelector } from 'modules/react-redux';
import { memo, useMemo } from 'react';

import { messages } from './TagBadge.messages';
import { NewRequestItemProps } from './TagBadge.types';

export const TagBadge = memo((props: NewRequestItemProps) => {
    const { item, companiesData } = props;

    const allIntegrations = useSelector(selectors.integration.getIntegrations);

    const disabledIntegration = useMemo(
        () =>
            allIntegrations.find(
                (integration) =>
                    integration.companyId === item.companyId &&
                    integration.status !== domain.IntegrationStatus.Connected
            ),
        [allIntegrations, item]
    );

    const companyData = companiesData[item.id];

    const inactiveTemplate =
        (disabledIntegration && item.integrationCode !== null) ||
        companyData.isReadonly ||
        companyData.disconnectedFromAirwallex;

    const showAmCaptureBadge =
        item.integrationCode === domain.IntegrationCode.XeroBill &&
        !disabledIntegration &&
        !inactiveTemplate &&
        (companiesData[item.id].betaFeatures.includes(domain.CompanyBetaFeature.XeroOcr) ||
            companiesData[item.id].licenseFeatures.includes(domain.CompanyLicenseFeature.Capture));

    return (
        <>
            {showAmCaptureBadge && (
                <Tag size='xsmall' color='blue20' outline>
                    {messages.amCaptureBadge}
                </Tag>
            )}

            {(disabledIntegration && inactiveTemplate) ||
                (companyData.disconnectedFromAirwallex && (
                    <Tag size='xsmall' color='midnight40' rounded={false}>
                        {messages.integrationDisconnected}
                    </Tag>
                ))}
        </>
    );
});

TagBadge.displayName = 'TagBadge';
