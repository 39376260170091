enum LineItemColumnKind {
    DocumentTaxType = 'documentTaxType',
    DocumentContact = 'documentContact',
    RequestCurrency = 'requestCurrency',
    DocumentDate = 'documentDate',
    RequestName = 'requestName',
    Resolution = 'requestResolution',
    DocumentDepartment = 'documentDepartment',
    TaxAmount = 'taxAmount',

    IsBilled = 'isDocumentBilled',
    DocumentDeliveryDate = 'documentDeliveryDate',
    DueDate = 'documentDueDate',
    IsMatched = 'isMatched',
    IsPaid = 'isDocumentPaid',
    DocumentPaymentDate = 'documentPaymentDate',
    DocumentNumber = 'documentNumber',

    Item = 'item',
    Account = 'account',
    Amount = 'amount',
    Description = 'description',
    Quantity = 'quantity',
    TaxRate = 'taxRate',
    Tracking1 = 'tracking1',
    Tracking2 = 'tracking2',
    UnitPrice = 'unitPrice',
    Discount = 'discount',
    Customer = 'customer',
    Class = 'class',
    NetRemainingBalance = 'netRemainingBalance',
    GrossRemainingBalance = 'grossRemainingBalance',
    NetAmount = 'netAmount',
    GrossAmount = 'grossAmount',
    ServiceDate = 'serviceDate',

    PayeeCustomer = 'payeeCustomer',
    PayeeEmployee = 'payeeEmployee',
    PaymentAccount = 'paymentAccount',
    PaymentType = 'paymentType',
    PaymentMethod = 'paymentMethod',

    QBooksCustomField = 'qBooksCustomFields',
    QBooksPurchaseOrderStatus = 'qBooksPurchaseOrderStatus',

    PaymentStatus = 'paymentStatus',
    BatchPaymentStatus = 'batchPaymentStatus',

    AirwallexPaymentMethod = 'airwallexPaymentMethod',
    AirwallexBeneficiary = 'beneficiary',
    AirwallexBillCurrency = 'billCurrency',
    AirwallexSourceCurrency = 'sourceCurrency',
    AirwallexPaymentPurpose = 'paymentPurpose',
    AirwallexPaymentDate = 'paymentDate',
    AirwallexFeeAmount = 'feeAmount',
    AirwallexSourceAmount = 'sourceAmount',

    BankAccount = 'bankAccount',
    Bill = 'bill',
    BillAmount = 'billAmount',
    PaymentFee = 'paymentFee',
    PaymentReference = 'paymentReference',

    isAccepted = 'isAccepted',

    SumOfAllocations = 'sumOfAllocations',
    PurchaseOrderBalance = 'purchaseOrderBalance',
    MatchedPurchaseOrders = 'matchedPurchaseOrders',
    MatchedBills = 'matchedBills',
    BillBalance = 'billBalance',

    GrnStatus = 'grnStatus',

    DeliveryDate = 'deliveryDate',
    RetrospectivePurchaseOrder = 'retrospectivePurchaseOrder',

    AmaxPayBatchPaymentStatus = 'amaxPayBatchPaymentStatus',
    AmaxPayPaymentStatus = 'amaxPayPaymentStatus',
    AmaxPayBillCurrency = 'amaxPayBillCurrency',
    AmaxPayPaymentDate = 'amaxPayPaymentDate',
    AmaxPayBankAccountName = 'amaxPayBankAccountName',
    PaymentDetails = 'paymentDetails',

    Narration = 'narration',
    TotalDebitAmount = 'totalDebitAmount',
    TotalCreditAmount = 'totalCreditAmount',
    ShowOnCashBasisReports = 'showOnCashBasisReports',
    CreditTax = 'creditTax',
    DebitTax = 'debitTax',
    TotalCreditTax = 'totalCreditTax',
    TotalDebitTax = 'totalDebitTax',
}

export default LineItemColumnKind;
