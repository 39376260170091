import { componentHelpers, domHelpers } from '@approvalmax/utils';
import { DndContext, pointerWithin } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { forwardRef, ReactElement, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { RecoilRoot } from 'recoil';

import { Cell, ColumnHeader, ColumnsGroup, HeaderRow, Row } from './components';
import { generateMockedDataItemsForSkeleton, reorderModifierSnapToAxis, retrieveRowId } from './Table.helpers';
import { useTableReorder } from './Table.hooks';
import { Root } from './Table.styles';
import { BaseItem, ChildrenComponents, TableCheckType, TableProps } from './Table.types';

/**
 * Renders a table component with the given columns and data.
 */
const Table = <Item extends BaseItem>(props: TableProps<Item>) => {
    const {
        columns,
        data,
        getRowId,
        bordered,
        onSort,
        checkedItems,
        invalidItems,
        checkboxColumnCover,
        onCheckedItemsChange,
        onRowClick,
        reorderable = false,
        onReorderColumns,
        onReorderRows,
        headerColor,
        stickyHeader,
        width,
        fontSize = 'small',
        stickyLastRow,
        virtualized,
        progress,
        progressRowCount = 6,
        hideHeader,
        divider = true,
        rounded,
        checkType = TableCheckType.checkbox,
        qa,
        ...otherProps
    } = props;

    const isReorderableColumns = reorderable && !!onReorderColumns;
    const isReorderableRows = reorderable && !!onReorderRows;

    const { sensors, handleReordered } = useTableReorder({
        reorderable,
        columns,
        data,
        onReorderColumns,
        onReorderRows,
    });

    const headerRow = !hideHeader && (
        <HeaderRow
            qa={qa}
            bordered={bordered}
            columns={columns}
            getRowId={getRowId}
            checkedItems={checkedItems}
            onCheckedItemsChange={onCheckedItemsChange}
            headerColor={headerColor}
            stickyHeader={stickyHeader}
            checkboxColumnCover={checkboxColumnCover}
            checkType={checkType}
            onSort={onSort}
            data={data}
            isReorderableColumns={isReorderableColumns}
            isReorderableRows={isReorderableRows}
        />
    );

    const skeletonData: Item[] = useMemo(
        () => generateMockedDataItemsForSkeleton(progressRowCount),
        [progressRowCount]
    );

    return (
        <DndContext
            sensors={sensors}
            modifiers={[reorderModifierSnapToAxis]}
            collisionDetection={pointerWithin}
            onDragEnd={handleReordered}
        >
            <RecoilRoot
                // We need to have an ability to have access to ancestors RecoilRoots within
                // Table components (mostly custom Cells)
                override={false}
            >
                {virtualized ? (
                    <TableVirtuoso
                        totalCount={data.length}
                        data={data}
                        style={
                            reorderable
                                ? {
                                      width: 'calc(100% + 30px)',
                                      marginLeft: '-30px',
                                  }
                                : {}
                        }
                        components={{
                            Table: ({ children }) => {
                                return (
                                    <Root
                                        $fontSize={fontSize}
                                        $width={width}
                                        data-qa={domHelpers.generateDataQa(qa, 'table')}
                                        {...otherProps}
                                    >
                                        <ColumnsGroup
                                            columns={columns}
                                            bordered={bordered}
                                            checkedItems={checkedItems}
                                            checkboxColumnCover={checkboxColumnCover}
                                        />

                                        {children}
                                    </Root>
                                );
                            },
                            TableHead: forwardRef((props, ref) => (
                                <SortableContext items={columns} strategy={horizontalListSortingStrategy}>
                                    <thead ref={ref} {...props} />
                                </SortableContext>
                            )),
                            TableBody: forwardRef((props, ref) => (
                                <SortableContext items={data} strategy={verticalListSortingStrategy}>
                                    <tbody ref={ref} {...props} />
                                </SortableContext>
                            )),
                            TableRow: (props) => (
                                <Row
                                    key={retrieveRowId(props.item, getRowId)}
                                    columns={columns}
                                    getRowId={getRowId}
                                    checkboxColumnCover={checkboxColumnCover}
                                    checkType={checkType}
                                    bordered={bordered}
                                    divider={divider}
                                    checkedItems={checkedItems}
                                    invalidItems={invalidItems}
                                    onCheckedItemsChange={onCheckedItemsChange}
                                    onClick={onRowClick}
                                    sticky={stickyLastRow && props['data-index'] === data.length - 1}
                                    reorderable={isReorderableRows}
                                    rounded={rounded}
                                    progress={progress}
                                    qa={qa}
                                    {...props}
                                />
                            ),
                        }}
                        fixedHeaderContent={() => headerRow}
                    />
                ) : (
                    <Root
                        $fontSize={fontSize}
                        $width={width}
                        $reorderable={reorderable}
                        data-qa={domHelpers.generateDataQa(qa, 'table')}
                        {...otherProps}
                    >
                        <ColumnsGroup
                            columns={columns}
                            bordered={bordered}
                            checkedItems={checkedItems}
                            checkboxColumnCover={checkboxColumnCover}
                        />

                        <SortableContext items={columns} strategy={horizontalListSortingStrategy}>
                            <thead>{headerRow}</thead>
                        </SortableContext>

                        <SortableContext items={data} strategy={verticalListSortingStrategy}>
                            <tbody>
                                {progress
                                    ? skeletonData.map((item) => (
                                          <Row
                                              key={item.id}
                                              item={item}
                                              progress
                                              bordered={bordered}
                                              columns={columns}
                                              checkedItems={checkedItems}
                                          />
                                      ))
                                    : data.map((item, index) => (
                                          <Row
                                              key={retrieveRowId(item, getRowId)}
                                              item={item}
                                              columns={columns}
                                              getRowId={getRowId}
                                              checkboxColumnCover={checkboxColumnCover}
                                              checkType={checkType}
                                              bordered={bordered}
                                              divider={divider}
                                              rounded={rounded}
                                              checkedItems={checkedItems}
                                              invalidItems={invalidItems}
                                              onCheckedItemsChange={onCheckedItemsChange}
                                              onClick={onRowClick}
                                              sticky={stickyLastRow && index === data.length - 1}
                                              reorderable={isReorderableRows}
                                              qa={qa}
                                          />
                                      ))}
                            </tbody>
                        </SortableContext>
                    </Root>
                )}
            </RecoilRoot>
        </DndContext>
    );
};

Table.displayName = 'Table';

const TableWithChildrenComponents = componentHelpers.genericMemo(Table) as unknown as (<Item extends BaseItem>(
    props: TableProps<Item>
) => ReactElement) &
    ChildrenComponents;

TableWithChildrenComponents.Cell = Cell;
TableWithChildrenComponents.ColumnHeader = ColumnHeader;

export default TableWithChildrenComponents;
