import { domHelpers } from '@approvalmax/utils';
import { forwardRef, ForwardRefExoticComponent, memo, MemoExoticComponent, RefAttributes } from 'react';

import { Box } from '../Box/Box';
import { Item, List } from './components';
import { MenuContext } from './Menu.context';
import { ChildrenComponents, MenuProps } from './Menu.types';

/**
 * The Menu allows you to create lists with items that, when clicked, perform certain actions
 */
const Menu = memo(
    forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
        const { items, size, divider, color, children, qa, ...restProps } = props;

        const dataQa = domHelpers.generateDataQa(qa, 'menu');

        return (
            <MenuContext.Provider value={{ size, divider, color }}>
                {items?.length ? (
                    <List {...restProps} items={items} ref={ref} qa={dataQa} />
                ) : (
                    <Box {...restProps} as='ul' ref={ref} role='menu' data-qa={dataQa}>
                        {children}
                    </Box>
                )}
            </MenuContext.Provider>
        );
    })
) as MemoExoticComponent<ForwardRefExoticComponent<MenuProps & RefAttributes<HTMLUListElement>>> & ChildrenComponents;

Menu.Item = Item;

export default Menu;
