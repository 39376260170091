import { Box, Button, Checkbox, Flex, Popup, Spacing, Table, toast } from '@approvalmax/ui/src/components';
import isArray from 'lodash/isArray';
import { selectors } from 'modules/common';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRecoilState } from 'recoil';
import { HelpStripe } from 'shared/components';

import {
    addStepParticipant,
    copyRuleToAnotherStepsUsers,
    copyRuleToSameStepUsers,
    removeLineFromActiveMatrix,
} from '../../../../actions';
import { MatrixType } from '../../../../types/matrix';
import { calculateSelectedUsers } from '../../../../utils/helpers';
import { IUsersDataTableMeta, TCopyRulePopupCheckedSteps } from '../../MatrixPopupContent.types';
import MatrixAddUserButton from '../MatrixAddUserButton/MatrixAddUserButton';
import { getTableColumns } from './CopyRulePopup.columns';
import {
    useCopyRulePopupHelpStripe,
    useCopyRulesPopupAvailableUsers,
    useCopyRulesPopupCheckedUsers,
} from './CopyRulePopup.hooks';
import { messages } from './CopyRulePopup.messages';
import { matrixCopyRuleState } from './CopyRulePopup.states';
import { CopyRulePopupProps, TCopyRuleErrors } from './CopyRulePopup.types';

const CopyRulePopup: FC<CopyRulePopupProps> = memo((props) => {
    const { matrix, steps, company, cellFields, template, team, readonly } = props;
    const [columnsCopied, setColumnsCopied] = useState(false);
    const [checkedData, setCheckedData] = useState<TCopyRulePopupCheckedSteps>({});
    const [checkedColumns, setCheckedColumns] = useState<string[]>([]);
    const [errors, setErrors] = useState<TCopyRuleErrors>([]);
    const dispatch = useDispatch();
    const [matrixCopyRule, setMatrixCopyRule] = useRecoilState(matrixCopyRuleState);
    const { fromUser, isOpen, matrixType } = matrixCopyRule;

    const isApprovalMatrix = matrix.type === MatrixType.Approval;
    const isEditingMatrix = matrix.type === MatrixType.Editing;

    const checkedUsers = useCopyRulesPopupCheckedUsers(checkedData);
    const availableUsers = useCopyRulesPopupAvailableUsers(company, matrix, fromUser);
    const cellFieldsData = useMemo(
        () => cellFields.map((field) => ({ ...field, displayName: field.name })),
        [cellFields]
    );

    const availableUsersTableMeta = useMemo<IUsersDataTableMeta>(
        () => ({ matrix, company, steps, userFrom: fromUser, checkedData }),
        [matrix, company, steps, fromUser, checkedData]
    );

    const onCheckedStepsChange = useCallback(
        (data: any) => {
            if (data && typeof data === 'object' && Object.values(data).every((value) => isArray(value))) {
                const checkedStepsNext = { ...checkedData };

                Object.keys(data).forEach((userId) => {
                    checkedStepsNext[userId] = data[userId];
                });

                setCheckedData(checkedStepsNext);
            }
        },
        [checkedData]
    );

    const tableColumns = useMemo(() => {
        return getTableColumns({
            meta: availableUsersTableMeta,
            onAction: onCheckedStepsChange,
            errors,
            withWorkflowSteps: isApprovalMatrix,
        });
    }, [availableUsersTableMeta, onCheckedStepsChange, errors, isApprovalMatrix]);

    useEffect(() => {
        setCheckedData({});
        setErrors([]);
        setCheckedColumns([]);
        setColumnsCopied(false);
    }, [isOpen]);

    useEffect(() => {
        setCheckedColumns(cellFields.map(({ id }) => id));
    }, [isOpen, cellFields]);

    const appendUnexistedUsersForCopyRulesSubmit = useCallback(
        (
            usersAvailable: selectors.types.ExpandedCompanyUser[],
            usersSelectedId: selectors.types.ExpandedCompanyUser['id'][],
            mapStepsToUser: TCopyRulePopupCheckedSteps
        ) => {
            usersSelectedId.forEach((userId) => {
                template.steps.forEach((step, stepIndex) => {
                    const isUserCopyingToStep = mapStepsToUser[userId].includes(step.id);
                    const isUserNotExistedInStep = !step.participantMatrix.some(
                        (line) => line.lineId === userId && !line.isBackup
                    );

                    if (isUserCopyingToStep && isUserNotExistedInStep) {
                        const user = usersAvailable.find((el) => el.userEmail === userId);

                        if (user) {
                            dispatch(addStepParticipant(stepIndex, user, false));
                        }
                    }
                });
            });
        },
        [dispatch, template.steps]
    );

    const closeCopyRulePopup = useCallback(
        (checkedUserIds: string[] = []) => {
            matrixCopyRule.addedUsers.forEach((user) => {
                const isUnusedUser = !checkedUserIds.includes(user.id);

                if (isUnusedUser) {
                    dispatch(removeLineFromActiveMatrix(user.id));
                }
            });

            setMatrixCopyRule({
                isOpen: false,
                fromUser: null,
                addedUsers: [],
                matrixType: null,
            });
        },
        [dispatch, matrixCopyRule.addedUsers, setMatrixCopyRule]
    );

    const onCopyRuleSubmit = useCallback(
        (mapStepsToUser: TCopyRulePopupCheckedSteps, checkedColumns: string[]) => {
            const selectedUsersIds = Object.keys(mapStepsToUser);

            if (fromUser && selectedUsersIds.length > 0) {
                const users = calculateSelectedUsers(team, selectedUsersIds);

                if (isApprovalMatrix) {
                    const usersInTheSameStep = users.filter((user) => {
                        // select users to copy to the same step
                        const usersStepIds = mapStepsToUser[user.id];
                        const usersStepIndexes = usersStepIds.map((stepId) =>
                            template.steps.findIndex((step) => step.id === stepId)
                        );

                        return usersStepIndexes.includes(matrix.stepIndex);
                    });

                    const mapAndFilteredStepsToUser = Object.entries(mapStepsToUser).reduce(
                        (accum: TCopyRulePopupCheckedSteps, [userId, stepIds]: [string, string[]]) => {
                            // exclude the current step, it must be saved with saving popup
                            const currentStepId = template.steps[matrix.stepIndex].id;

                            return {
                                ...accum,
                                [userId]: stepIds.filter((stepId) => stepId !== currentStepId),
                            };
                        },
                        {}
                    );

                    if (usersInTheSameStep.length > 0) {
                        dispatch(
                            copyRuleToSameStepUsers({
                                fromUser,
                                toUsers: usersInTheSameStep,
                                checkedColumns,
                                matrixType: matrix.type,
                            })
                        );
                    }

                    appendUnexistedUsersForCopyRulesSubmit(users, selectedUsersIds, mapAndFilteredStepsToUser);
                } else {
                    dispatch(
                        copyRuleToSameStepUsers({ fromUser, toUsers: users, checkedColumns, matrixType: matrix.type })
                    );
                }

                const defaultApprover =
                    isApprovalMatrix && matrix.defaultApprover === fromUser.id ? matrix.defaultApprover : null;

                dispatch(
                    copyRuleToAnotherStepsUsers({
                        matrixActive: matrix,
                        fromUser,
                        mapStepsToUser,
                        defaultApprover,
                        template,
                        checkedColumns,
                    })
                );
            }

            closeCopyRulePopup(Object.keys(mapStepsToUser));
        },
        [
            fromUser,
            closeCopyRulePopup,
            team,
            isApprovalMatrix,
            matrix,
            dispatch,
            template,
            appendUnexistedUsersForCopyRulesSubmit,
        ]
    );

    const validate = useCallback((): boolean => {
        if (!isApprovalMatrix) {
            return true;
        }

        const errorsList: TCopyRuleErrors = [];
        const usersIds = Object.keys(checkedData);

        if (usersIds.length === 0) {
            errorsList.push(null);
        } else {
            usersIds.forEach((userId) => {
                if (checkedData[userId].length === 0) {
                    errorsList.push(userId);
                }
            });
        }

        setErrors(errorsList);

        return errorsList.length === 0;
    }, [isApprovalMatrix, checkedData]);

    const onCheckedUsersChange = useCallback(
        (usersIds: string[]) => {
            const checkedStepsNext = usersIds.reduce<TCopyRulePopupCheckedSteps>((total, userId) => {
                total[userId] = Object.prototype.hasOwnProperty.call(checkedData, userId)
                    ? [...checkedData[userId]]
                    : [];

                return total;
            }, {});

            setCheckedData(checkedStepsNext);
        },
        [checkedData]
    );

    const onCheckedColumnChange = useCallback((columnIds: string[]) => {
        setCheckedColumns(columnIds);
    }, []);

    const onSelectAllColumns = useCallback(
        (selected: boolean) => {
            if (selected) {
                setCheckedColumns(cellFieldsData.map((field) => field.id));
            } else {
                setCheckedColumns([]);
            }
        },
        [cellFieldsData]
    );

    const onSubmit = useCallback(() => {
        const isValid = validate();

        if (isValid) {
            onCopyRuleSubmit(checkedData, checkedColumns);
            setCheckedData({});
            setCheckedColumns([]);
            setColumnsCopied(false);
        } else {
            toast.error(messages.errorMessage);
        }
    }, [validate, onCopyRuleSubmit, checkedData, checkedColumns]);

    const onClose = useCallback(
        (open: boolean) => {
            if (!open) {
                setCheckedData({});
                setCheckedColumns([]);
                setColumnsCopied(false);
                closeCopyRulePopup();
            }
        },
        [closeCopyRulePopup]
    );

    const onNextButton = useCallback(() => {
        checkedColumns.length === 0 ? toast.error(messages.errorMessageColumn) : setColumnsCopied(true);
    }, [checkedColumns.length]);

    const onBackButton = useCallback(() => {
        setColumnsCopied(false);
    }, []);

    const helpItemId = useCopyRulePopupHelpStripe();

    return (
        <Popup open={isOpen && matrixType === matrix.type} onToggle={onClose} preventAutoClose size='large'>
            <Popup.Header
                title={messages.title}
                subTitle={columnsCopied ? messages.usersStep : messages.columnsStep}
                actions={
                    <Flex inline alignItems='center' wrap={false}>
                        <HelpStripe.ToggleButton />

                        {columnsCopied ? (
                            <>
                                <Button color='blue10' disabled={readonly} onClick={onBackButton} size='medium'>
                                    {messages.back}
                                </Button>

                                <Button color='blue80' disabled={readonly} onClick={onSubmit} size='medium'>
                                    {messages.copy}
                                </Button>
                            </>
                        ) : (
                            <Button color='blue80' disabled={readonly} onClick={onNextButton} size='medium'>
                                {messages.next}
                            </Button>
                        )}
                    </Flex>
                }
            />

            <Popup.Body>
                <Flex inline direction='column' grow={1} width='100%' height='100%' wrap={false} spacing='0'>
                    {helpItemId && <HelpStripe id={helpItemId} />}

                    <Spacing height={20} />

                    <Box>
                        {columnsCopied ? (
                            <>
                                {availableUsers.length > 0 && (
                                    <Flex inline direction='column'>
                                        <Table
                                            checkedItems={checkedUsers}
                                            onCheckedItemsChange={onCheckedUsersChange}
                                            data={availableUsers}
                                            columns={tableColumns}
                                            headerColor='white100'
                                        />
                                    </Flex>
                                )}

                                {!isEditingMatrix && (
                                    <MatrixAddUserButton onlyAddNewUser isForCopyRule readonly={readonly} team={team} />
                                )}
                            </>
                        ) : (
                            <Flex inline direction='column' spacing='16'>
                                <Checkbox
                                    indeterminate={checkedColumns.length !== cellFieldsData.length}
                                    checked={checkedColumns.length > 0}
                                    onChange={onSelectAllColumns}
                                >
                                    {messages.all}
                                </Checkbox>

                                <Checkbox.Group
                                    name='copyRule'
                                    onChange={onCheckedColumnChange}
                                    value={checkedColumns}
                                    block
                                    size='medium'
                                >
                                    {cellFieldsData.map((field) => (
                                        <Checkbox key={field.id} value={field.id}>
                                            {field.displayName}
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Flex>
                        )}
                    </Box>
                </Flex>
            </Popup.Body>
        </Popup>
    );
});

export default CopyRulePopup;
