import { defineMessages } from '@approvalmax/utils';

export const messages = defineMessages('components.Drawer.AllCompaniesMenu', {
    myDecisionRequiredLinkName: 'Requires my decision (all)',
    myDecisionRequiredLinkTitle: 'Requests that require my decision',
    myReviewRequiredLinkName: 'My review required',
    myReviewRequiredLinkTitle: 'Requests that require my review',
    myOcrRequestsLinkName: 'Captured by me',
    myOcrRequestsLinkTitle: 'Captured by me',
    myOpenLinkName: 'Created by me',
    myOpenLinkTitle: 'Requests pending approval that I submitted',
    myDraftLinkName: 'Drafts',
    myDraftLinkTitle: 'Draft requests',
    myReadyToPayName: 'Ready to pay',
    myReadyToPayTitle: 'Ready to pay',
});
