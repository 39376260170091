import { Popup } from '@approvalmax/ui';
import { Popup as NewPopup } from '@approvalmax/ui/src/components';
import { actions, selectors } from 'modules/common';
import { TwoFaEnablingWizard, useTwoFaEnablingWizardContext } from 'modules/components';
import { domain } from 'modules/data';
import { useDispatch, useSelector } from 'modules/react-redux';
import { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { commonErrorMessages } from 'services/error/messages';
import { routingService } from 'services/routing';
import { AfterAllFeatureTrialPopup, NewAppVersionPopup, SubscribeAfterTrialPopup } from 'shared/components';
import { useExpandedCompany } from 'shared/hooks/useExpandedCompany';
import { activeCompanyIdState } from 'shared/states';
import { getPath, Path } from 'urlBuilder';

import {
    cancelActivePopup,
    changeProfilePopupModified,
    closeOrSkipPracticeInvitation,
    openTwoFaSoftEnforcementPopup,
    showPracticeInvitationPopup,
} from './actions';
import { MyDelegatesPopup } from './components/MyDelegatesPopup/MyDelegatesPopup';
import { StartTrialPeriodPopup } from './components/StartTrialPeriodPopup/StartTrialPeriodPopup';
import TwoFaSoftEnforcementPopup from './components/TwoFaSoftEnforcementPopup/TwoFaSoftEnforcementPopup';
import ContactSalesPopup from './containers/ContactSalesPopup/ContactSalesPopup';
import ContactSupportPopup from './containers/ContactSupportPopup/ContactSupportPopup';
import PendingPracticeInvitationPopup from './containers/pendingPracticeInvitationPopup/PendingPracticeInvitationPopup';
import ProfilePopup from './containers/ProfilePopup/ProfilePopup';
import { TrialPeriodStartedPopup } from './containers/TrialPeriodStartedPopup/TrialPeriodStartedPopup';
import { getActivePopups, getConsentToStartTrialPeriodPopupData } from './selectors/moduleSelectors';
import { needToShowPracticeInvitationPopup } from './selectors/practiceInvitations';

export const ModuleComponent = memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobileInfoPage = history.location.pathname === getPath(Path.mobile);
    const activePopups = useSelector(getActivePopups);
    const needToShowPracticeInvitation = useSelector(needToShowPracticeInvitationPopup);
    const consentToStartTrialPeriodPopupData = useSelector(getConsentToStartTrialPeriodPopupData);
    const profile = useSelector(selectors.profile.findProfile);
    const companyId = useRecoilValue(activeCompanyIdState);
    const company = useExpandedCompany(companyId);

    useEffect(() => {
        if (needToShowPracticeInvitation) {
            dispatch(showPracticeInvitationPopup());
        }
    }, [dispatch, needToShowPracticeInvitation]);

    useEffect(() => {
        if (profile?.requiredEnforcementTfaType === domain.TwoFaEnforcementType.Soft) {
            dispatch(openTwoFaSoftEnforcementPopup());
        }
    }, [dispatch, profile?.requiredEnforcementTfaType]);

    const closeProfilePopup = (proceed: boolean) => {
        if (proceed) {
            dispatch(cancelActivePopup());
            dispatch(changeProfilePopupModified(false));
        }
    };

    const onCloseProfilePopup = () => {
        const modified = profile?.modified;
        const twoFaEnforcementType = company?.enforcementTfaType;

        if (modified) {
            dispatch(
                actions.showDiscardChangesPopup({
                    unsavedChangesConfirmationCallback: closeProfilePopup,
                })
            );
        } else {
            closeProfilePopup(true);
        }

        if (twoFaEnforcementType === domain.TwoFaEnforcementType.Hard && !profile?.is2faEnabled) {
            toast.error(commonErrorMessages.twoFAMustBeEnabled);

            setTimeout(() => {
                routingService.reloadToUrl(getPath(Path.twoFaHardEnforcement));
            }, 1000);
        }
    };

    const handleRequestClose = useCallback(
        (open?: boolean) => {
            if (!open) {
                dispatch(cancelActivePopup());
            }
        },
        [dispatch]
    );

    const { context: twoFaEnablingWizardContext } = useTwoFaEnablingWizardContext();

    if (isMobileInfoPage) return null;

    return (
        <div>
            <NewPopup size='large' open={activePopups.myDelegates} onToggle={handleRequestClose}>
                <MyDelegatesPopup />
            </NewPopup>

            <Popup isOpen={activePopups.profile} onRequestClose={onCloseProfilePopup} disableAutoClose>
                <ProfilePopup />
            </Popup>

            <TwoFaSoftEnforcementPopup isOpen={activePopups.twoFaSoftEnforcementPopup} onClose={handleRequestClose} />

            <TwoFaEnablingWizard isOpen={twoFaEnablingWizardContext.isOpen} />

            <Popup
                isOpen={activePopups.pendingPracticeInvitation}
                onRequestClose={() => dispatch(closeOrSkipPracticeInvitation())}
            >
                <PendingPracticeInvitationPopup />
            </Popup>

            {consentToStartTrialPeriodPopupData && (
                <StartTrialPeriodPopup
                    isOpen={activePopups.consentToStartTrialPeriodPopup}
                    data={consentToStartTrialPeriodPopupData}
                    onClose={handleRequestClose}
                />
            )}

            <TrialPeriodStartedPopup isOpen={activePopups.trialPeriodStartedPopup} onClose={handleRequestClose} />

            <Popup isOpen={activePopups.contactSalesPopup} onRequestClose={handleRequestClose}>
                <ContactSalesPopup />
            </Popup>

            <Popup isOpen={activePopups.contactSupportPopup} onRequestClose={handleRequestClose}>
                <ContactSupportPopup />
            </Popup>

            <NewAppVersionPopup />

            <AfterAllFeatureTrialPopup />

            {profile && profile.account && (
                <SubscribeAfterTrialPopup
                    profileId={profile?.id}
                    companyId={companyId}
                    accountId={profile.account.accountId}
                />
            )}
        </div>
    );
});

ModuleComponent.displayName = 'ModuleComponent';
